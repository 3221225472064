:root {
  --neutralwhite: #fff;
}

.component {
  width: 749px;
  height: 873px;
}

.component .frame-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
  display: inline-flex;
  position: relative;
  top: 3px;
}

.component .frame-4 {
  background-color: #fff;
  border-radius: 9px;
  width: 749px;
  height: 195px;
  position: relative;
  box-shadow: 0 2px 15px #0000001c;
}

.component .screenshot {
  object-fit: cover;
  width: 287px;
  height: 164px;
  position: absolute;
  top: 19px;
  left: 18px;
}

.component .heading-link {
  color: #253b95;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 394px;
  height: 31px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  position: absolute;
  top: 31px;
  left: 324px;
}

.component .p {
  color: #000;
  letter-spacing: 0;
  width: 410px;
  height: 48px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  position: absolute;
  top: 78px;
  left: 324px;
}

.component .overlap-group-3 {
  width: 66px;
  height: 14px;
  position: absolute;
  top: 147px;
  left: 324px;
}

.component .text-wrapper-7 {
  color: #3c83ff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 63px;
  height: 14px;
  font-family: Kantumruy, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: underline;
  position: absolute;
  top: 0;
  left: 0;
}

.component .arrow {
  width: 5px;
  height: 4px;
  position: absolute;
  top: 7px;
  left: 61px;
}

.component .text-wrapper-8 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 88px;
  height: 19px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 48px;
  position: absolute;
  top: 142px;
  left: 612px;
}

.component .line {
  object-fit: cover;
  width: 9px;
  height: 1px;
  position: absolute;
  top: 153px;
  left: 595px;
}

.component .img {
  object-fit: cover;
  width: 9px;
  height: 1px;
  position: absolute;
  top: 153px;
  left: 703px;
}

.component .screenshot-2 {
  width: 287px;
  height: 163px;
  position: absolute;
  top: 19px;
  left: 18px;
}

.component-wrapper {
  width: 449px;
  height: 871px;
}

.component-wrapper .frame-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: inline-flex;
  position: relative;
}

.component-wrapper .frame-6 {
  width: 448.97px;
  height: 269px;
  position: relative;
}

.component-wrapper .img-wrapper {
  background-color: #d9d9d9;
  border: 3px solid #253b95;
  width: 192px;
  height: 272px;
  position: absolute;
  top: -3px;
  left: -3px;
}

.component-wrapper .image-2 {
  object-fit: cover;
  width: 186px;
  height: 266px;
  position: absolute;
  top: 0;
  left: 0;
}

.component-wrapper .text-wrapper-9 {
  color: #171717;
  letter-spacing: 0;
  white-space: nowrap;
  height: 10px;
  font-family: Montserrat, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  position: absolute;
  top: 253px;
  left: 218px;
}

.component-wrapper .svg {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 254px;
  left: 275px;
}

.component-wrapper .text-wrapper-10 {
  color: #171717;
  letter-spacing: 0;
  white-space: nowrap;
  height: 10px;
  font-family: Montserrat, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  position: absolute;
  top: 253px;
  left: 321px;
}

.component-wrapper .text-wrapper-11 {
  color: #171717;
  letter-spacing: 0;
  width: 231px;
  height: 54px;
  font-family: Montserrat, Helvetica;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  position: absolute;
  top: -1px;
  left: 218px;
}

.frame {
  width: 1272px;
  height: 242px;
}

.frame .div-3 {
  width: 1218px;
  height: 176px;
  position: relative;
  top: 44px;
  left: 25px;
}

.frame .div-4 {
  width: 155px;
  height: 139px;
  position: absolute;
  top: 4px;
  left: 0;
}

.frame .screenshot-3 {
  width: 134px;
  height: 120px;
  display: block;
  position: absolute;
  top: -6px;
  left: 10px;
}

.frame .text-wrapper-12 {
  color: #000;
  letter-spacing: .36px;
  text-align: center;
  width: 155px;
  height: 20px;
  font-family: Kantumruy, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 118px;
  left: 0;
}

.frame .div-5 {
  width: 150px;
  height: 139px;
  position: absolute;
  top: 4px;
  left: 182px;
}

.frame .screenshot-4 {
  width: 141px;
  height: 120px;
  display: block;
  position: absolute;
  top: -6px;
  left: 5px;
}

.frame .text-wrapper-13 {
  color: #000;
  letter-spacing: .36px;
  text-align: center;
  width: 150px;
  height: 20px;
  font-family: Kantumruy, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 118px;
  left: 0;
}

.frame .overlap-group-wrapper-2 {
  width: 168px;
  height: 173px;
  position: absolute;
  top: 0;
  left: 346px;
}

.frame .overlap-group-4 {
  height: 178px;
  position: relative;
  top: -6px;
}

.frame .screenshot-5 {
  width: 132px;
  height: 130px;
  display: block;
  position: absolute;
  top: 0;
  left: 18px;
}

.frame .text-wrapper-14 {
  color: #000;
  letter-spacing: .36px;
  text-align: center;
  width: 168px;
  height: 49px;
  font-family: Kantumruy, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 24.5px;
  position: absolute;
  top: 129px;
  left: 0;
}

.frame .div-6 {
  width: 150px;
  height: 141px;
  position: absolute;
  top: 4px;
  left: 534px;
}

.frame .overlap-2 {
  width: 121px;
  height: 123px;
  position: absolute;
  top: -6px;
  left: 14px;
}

.frame .ellipse {
  background-color: #fff;
  border-radius: 49px;
  width: 98px;
  height: 98px;
  position: absolute;
  top: 6px;
  left: 14px;
}

.frame .screenshot-6 {
  width: 121px;
  height: 123px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.frame .text-wrapper-15 {
  color: #000;
  letter-spacing: .36px;
  text-align: center;
  width: 150px;
  height: 20px;
  font-family: Kantumruy, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 120px;
  left: 0;
}

.frame .div-7 {
  width: 150px;
  height: 144px;
  position: absolute;
  top: 4px;
  left: 704px;
}

.frame .text-wrapper-16 {
  color: #000;
  letter-spacing: .36px;
  text-align: center;
  width: 150px;
  height: 25px;
  font-family: Kantumruy, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 24.5px;
  position: absolute;
  top: 118px;
  left: 0;
}

.frame .screenshot-7 {
  width: 125px;
  height: 117px;
  display: block;
  position: absolute;
  top: -6px;
  left: 10px;
}

.frame .div-8 {
  width: 150px;
  height: 164px;
  position: absolute;
  top: 0;
  left: 884px;
}

.frame .screenshot-8 {
  width: 113px;
  height: 125px;
  display: block;
  position: absolute;
  top: -6px;
  left: 18px;
}

.frame .text-wrapper-17 {
  color: #000;
  letter-spacing: .36px;
  text-align: center;
  width: 150px;
  height: 40px;
  font-family: Kantumruy, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 123px;
  left: 0;
}

.frame .div-9 {
  width: 150px;
  height: 167px;
  position: absolute;
  top: 2px;
  left: 1068px;
}

.frame .screenshot-9 {
  width: 122px;
  height: 120px;
  display: block;
  position: absolute;
  top: -6px;
  left: 14px;
}

.frame .text-wrapper-18 {
  color: #000;
  letter-spacing: .36px;
  text-align: center;
  width: 150px;
  height: 49px;
  font-family: Kantumruy, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 24.5px;
  position: absolute;
  top: 117px;
  left: 0;
}

.group {
  width: 1440px;
  height: 195px;
}

.group .overlap {
  height: 195px;
  position: relative;
}

.group .div-nav-utility {
  background-color: #253b95;
  width: 1440px;
  height: 50px;
  position: absolute;
  top: 145px;
  left: 0;
}

.group .overlap-wrapper {
  width: 1440px;
  height: 145px;
  position: absolute;
  top: 0;
  left: 0;
}

.group .overlap-group {
  height: 145px;
  position: relative;
}

.group .div {
  background-color: #253b95;
  width: 1440px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.group .div-2 {
  width: 1440px;
  height: 138px;
  position: absolute;
  top: 7px;
  left: 0;
}

.group .div-frame {
  border: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 16.2px;
  width: 1203px;
  max-width: 1260px;
  height: 37px;
  padding: 1px 0 3px 21px;
  display: flex;
  position: absolute;
  top: 0;
  left: 142px;
}

.group .link-state {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 3.93px 5px 4.32px;
  display: inline-flex;
  position: relative;
}

.group .symbol {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.group .symbol-wrapper {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
  display: inline-flex;
  position: relative;
}

.group .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.group .header {
  background-color: #e41d2c;
  width: 1440px;
  height: 95px;
  position: absolute;
  top: 43px;
  left: 0;
}

.group .nav-list {
  width: 1440px;
  height: 95px;
  position: relative;
  top: -2px;
}

.group .overlap-group-wrapper {
  width: 388px;
  height: 89px;
  position: absolute;
  top: 3px;
  left: 380px;
}

.group .overlap-group-2 {
  width: 384px;
  height: 89px;
  position: relative;
}

.group .text-wrapper-2 {
  color: #fff;
  letter-spacing: 0;
  width: 321px;
  height: 50px;
  font-family: Moulpali, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.group .text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  width: 384px;
  height: 50px;
  font-family: Moul, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 39px;
  left: 0;
}

.group .frame-2 {
  justify-content: center;
  align-items: center;
  gap: 38px;
  width: 512px;
  display: flex;
  position: absolute;
  top: 32px;
  left: 833px;
}

.group .text-wrapper-4 {
  color: #fff;
  letter-spacing: .5px;
  text-shadow: 0 2px 4px #00000029;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: -6.5px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  position: relative;
}

.group .text-wrapper-5 {
  color: #fff;
  letter-spacing: .5px;
  text-shadow: 0 2px 4px #00000029;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  position: relative;
}

.group .text-wrapper-6 {
  color: #fff;
  letter-spacing: .5px;
  text-align: center;
  text-shadow: 0 2px 4px #00000029;
  width: 85px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  position: relative;
}

.group .div-flex {
  width: 92px;
  height: 29.59px;
  margin-right: -6.5px;
  position: relative;
}

.group .link {
  width: 36px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.group .div-flex-wrapper {
  background-color: #f9e88c;
  width: 37px;
  height: 30px;
}

.group .image-wrapper {
  width: 28px;
  height: 30px;
}

.group .image {
  background-image: url("image-2x.8b46ff98.png");
  background-position: 50%;
  background-size: cover;
  width: 32px;
  height: 26px;
  position: relative;
  top: 2px;
  left: 2px;
}

.group .link-image {
  background-image: url("link-image.f60899c0.png");
  background-position: 50%;
  background-size: cover;
  width: 32px;
  height: 26px;
  position: absolute;
  top: 2px;
  left: 52px;
}

.group .asset-hero {
  width: 149px;
  height: 149px;
  position: absolute;
  top: 23px;
  left: 205px;
}

.group .asset-wat {
  object-fit: cover;
  width: 78px;
  height: 50px;
  position: absolute;
  top: 65px;
  left: 101px;
}

.home {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.home .div-14 {
  background-color: #fff;
  width: 1440px;
  height: 4383px;
  position: relative;
  overflow-x: hidden;
}

.home .overlap-6 {
  width: 1464px;
  height: 3171px;
  position: absolute;
  top: 0;
  left: -10px;
}

.home .group-10 {
  width: 1450px;
  height: 1539px;
  position: absolute;
  top: 746px;
  left: 5px;
}

.home .overlap-group-7 {
  height: 1539px;
  position: relative;
}

.home .background {
  width: 1450px;
  height: 883px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .background-2 {
  width: 1450px;
  height: 883px;
  position: absolute;
  top: 656px;
  left: 0;
}

.home .image-10 {
  width: 1440px;
  height: 760px;
  position: absolute;
  top: 145px;
  left: 10px;
}

.home .section {
  width: 1440px;
  height: 240px;
  position: absolute;
  top: 0;
  left: 10px;
}

.home .overlap-7 {
  width: 1440px;
  height: 711px;
  position: absolute;
  top: 195px;
  left: 0;
}

.home .div-absolute-2 {
  background: linear-gradient(#000000d9 0%, #0000 100%);
  width: 623px;
  height: 250px;
  position: absolute;
  top: 186px;
  left: -186px;
  transform: rotate(-90deg);
}

.home .div-absolute-3 {
  background: linear-gradient(#000000d9 0%, #0000 100%);
  width: 644px;
  height: 250px;
  position: absolute;
  top: 197px;
  left: 993px;
  transform: rotate(90deg);
}

.home .div-absolute-wrapper {
  width: 1440px;
  height: 442px;
  position: absolute;
  top: 269px;
  left: 0;
}

.home .div-absolute-4 {
  background: linear-gradient(#000 10.42%, #0000 100%);
  height: 442px;
  transform: rotate(180deg);
}

.home .group-11 {
  width: 898px;
  height: 181px;
  position: absolute;
  top: 409px;
  left: 271px;
}

.home .overlap-8 {
  width: 900px;
  height: 181px;
  position: relative;
}

.home .group-12 {
  width: 900px;
  height: 181px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .text-wrapper-39 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 898px;
  height: 95px;
  font-family: Kantumruy, Helvetica;
  font-size: 36px;
  font-weight: 700;
  line-height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .group-13 {
  width: 227px;
  height: 59px;
  position: absolute;
  top: 123px;
  left: 0;
}

.home .group-14 {
  width: 89px;
  height: 21px;
  position: absolute;
  top: 18px;
  left: 140px;
}

.home .symbol-9 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 11px;
  height: 20px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .telegram-app-2 {
  width: 17px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 68px;
}

.home .symbol-10 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 17px;
  height: 20px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 1px;
  left: 33px;
}

.home .text-wrapper-40 {
  color: #fff;
  letter-spacing: 0;
  width: 112px;
  height: 59px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .group-16 {
  width: 123px;
  height: 35px;
  position: absolute;
  top: 132px;
  left: 775px;
}

.home .group-17 {
  height: 35px;
}

.home .overlap-9 {
  width: 123px;
  height: 35px;
  position: relative;
}

.home .group-18 {
  width: 107px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .overlap-group-8 {
  width: 105px;
  height: 35px;
  position: relative;
}

.home .text-wrapper-41 {
  color: #fff;
  letter-spacing: 0;
  width: 84px;
  height: 18px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  top: 9px;
  left: 11px;
}

.home .rectangle {
  border: 1.5px solid #fff;
  width: 105px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .arrow-2 {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 14px;
  left: 104px;
}

.home .group-19 {
  width: 177px;
  height: 14px;
  position: absolute;
  top: 586px;
  left: 631px;
}

.home .ellipse-2 {
  background-color: #ffffff80;
  border-radius: 4.67px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 2px;
  left: 42px;
}

.home .ellipse-3 {
  background-color: #fff;
  border-radius: 7px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  left: 82px;
}

.home .ellipse-4 {
  background-color: #ffffff80;
  border-radius: 4.67px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 2px;
  left: 126px;
}

.home .ellipse-5 {
  background-color: #ffffff80;
  border-radius: 4.67px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 2px;
  left: 168px;
}

.home .ellipse-6 {
  background-color: #ffffff80;
  border-radius: 4.67px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 2px;
  left: 0;
}

.home .group-instance {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.home .group-20 {
  width: 356px;
  height: 62px;
  position: absolute;
  top: 2777px;
  left: 307px;
}

.home .text-wrapper-42 {
  color: #fff;
  letter-spacing: 0;
  text-shadow: 0 4px 4px #00000040;
  width: 354px;
  height: 62px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .group-21 {
  width: 1464px;
  height: 934px;
  position: absolute;
  top: 2237px;
  left: 0;
}

.home .overlap-10 {
  width: 1460px;
  height: 934px;
  position: relative;
}

.home .group-22 {
  width: 1460px;
  height: 934px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .overlap-group-9 {
  height: 934px;
  position: relative;
}

.home .image-11 {
  width: 1460px;
  height: 900px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .group-23 {
  background: linear-gradient(#253b9500 0%, #000 78.65%);
  width: 1460px;
  height: 919px;
  position: absolute;
  top: 15px;
  left: 0;
}

.home .text-wrapper-43 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 296px;
  height: 102px;
  font-family: Kantumruy, Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  position: absolute;
  top: 78px;
  left: 215px;
}

.home .text-wrapper-44 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 296px;
  height: 102px;
  font-family: Kantumruy, Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: 48px;
  position: absolute;
  top: 64px;
  left: 983px;
}

.home .asset-map {
  object-fit: cover;
  width: 487px;
  height: 376px;
  position: absolute;
  top: 183px;
  left: 111px;
}

.home .group-24 {
  width: 547px;
  height: 62px;
  position: absolute;
  top: 738px;
  left: 101px;
}

.home .group-25 {
  width: 356px;
  height: 62px;
  position: absolute;
  top: 0;
  left: 191px;
}

.home .text-wrapper-45 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 140px;
  height: 47px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: 48px;
  position: absolute;
  top: 7px;
  left: 0;
}

.home .line-6 {
  width: 20px;
  height: 2px;
  position: absolute;
  top: 30px;
  left: 154px;
}

.home .group-26 {
  width: 547px;
  height: 62px;
  position: absolute;
  top: 738px;
  left: 819px;
}

.home .group-27 {
  width: 547px;
  height: 62px;
  position: absolute;
  top: 623px;
  left: 819px;
}

.home .group-28 {
  width: 547px;
  height: 62px;
  position: absolute;
  top: 623px;
  left: 101px;
}

.home .rectangle-2 {
  object-fit: cover;
  width: 453px;
  height: 388px;
  position: absolute;
  top: 173px;
  left: 906px;
}

.home .component-1 {
  width: 752px !important;
  height: 853px !important;
  position: absolute !important;
  top: 1251px !important;
  left: 99px !important;
}

.home .component-instance {
  gap: 23px !important;
}

.home .frame-26 {
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 40px;
  display: inline-flex;
  position: absolute;
  top: 1189px;
  left: 99px;
}

.home .america {
  width: 40px;
  height: 41px;
  margin-top: -.48px;
  margin-bottom: -.48px;
  position: relative;
}

.home .frame-27 {
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-items: center;
  height: 47px;
  margin-top: -3.48px;
  margin-bottom: -3.48px;
  padding: 0 0 5px;
  display: inline-flex;
  position: relative;
}

.home .text-wrapper-46 {
  color: #253b95;
  letter-spacing: 0;
  width: 100px;
  height: 48px;
  margin-top: -1px;
  margin-bottom: -5px;
  font-family: Kantumruy, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  position: relative;
}

.home .line-7 {
  width: 109px;
  height: 3px;
  margin-top: -6.5px;
  margin-bottom: -1.5px;
  position: relative;
}

.home .frame-28 {
  width: 186px;
  height: 34px;
  position: absolute;
  top: 1189px;
  left: 911px;
}

.home .icon-bullhorn {
  width: 27px;
  height: 32px;
  position: absolute;
  top: 7px;
  left: 0;
}

.home .frame-29 {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 142px;
  height: 38px;
  display: flex;
  position: absolute;
  top: 7px;
  left: 44px;
}

.home .text-wrapper-47 {
  color: #253b95;
  letter-spacing: 0;
  white-space: nowrap;
  width: 129px;
  height: 24px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  position: relative;
}

.home .line-8 {
  width: 132px;
  height: 4px;
  position: relative;
}

.home .right {
  align-items: flex-start;
  gap: 16px;
  width: 178px;
  height: 46px;
  display: flex;
  position: absolute;
  top: 1188px;
  left: 669px;
}

.home .datepicker {
  background-color: var(--neutralwhite);
  border: 1px solid #253b95;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 178px;
  height: 46px;
  padding: 10px 14px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home .calendar-alt {
  width: 41px !important;
  height: 41px !important;
  margin-top: -7.5px !important;
  margin-bottom: -7.5px !important;
  position: relative !important;
}

.home .text-wrapper-48 {
  color: #253b95;
  letter-spacing: .08px;
  width: 99px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  position: relative;
}

.home .component-2 {
  height: 849px !important;
  position: absolute !important;
  top: 1255px !important;
  left: 911px !important;
}

.home .component-2-instance {
  gap: 21px !important;
}

.home .group-29 {
  opacity: 0;
  background-image: url("gold-ornament-frame-8-1.dea4e1f5.png");
  background-position: 50%;
  background-size: cover;
  width: 1040px;
  height: 336px;
  position: absolute;
  top: 809px;
  left: 210px;
  transform: translate(25px);
}

.home .overlap-11 {
  width: 813px;
  height: 158px;
  position: relative;
  top: 90px;
  left: 69px;
}

.home .group-30 {
  width: 658px;
  height: 91px;
  position: absolute;
  top: 32px;
  left: 155px;
}

.home .group-31 {
  width: 662px;
  height: 91px;
}

.home .overlap-12 {
  width: 658px;
  height: 91px;
  position: relative;
}

.home .text-wrapper-49 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 104px;
  height: 20px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 70px;
  left: 554px;
}

.home .overlap-group-10 {
  width: 658px;
  height: 91px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .heading-link-2 {
  color: #253b95;
  letter-spacing: 0;
  width: 658px;
  height: 61px;
  font-family: Kantumruy, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 39px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .group-32 {
  width: 499px;
  height: 37px;
  position: absolute;
  top: 55px;
  left: 0;
}

.home .text-wrapper-50 {
  color: #253b95;
  letter-spacing: 0;
  white-space: nowrap;
  width: 239px;
  height: 37px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 47px;
  position: absolute;
  top: 0;
  left: 258px;
}

.home .line-9 {
  object-fit: cover;
  width: 239px;
  height: 1px;
  position: absolute;
  top: 17px;
  left: 0;
}

.home .asset-new-hero {
  width: 158px;
  height: 158px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .overlap-13 {
  width: 1459px;
  height: 1207px;
  position: absolute;
  top: 3176px;
  left: -11px;
}

.home .background-3 {
  width: 1450px;
  height: 696px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .group-33 {
  width: 306px;
  height: 58px;
  position: absolute;
  top: 62px;
  left: 573px;
}

.home .text-wrapper-51 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 48px;
  font-family: Kantumruy, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .line-10 {
  width: 100px;
  height: 4px;
  position: absolute;
  top: 56px;
  left: 107px;
}

.home .group-34 {
  width: 1455px;
  height: 714px;
  position: absolute;
  top: 493px;
  left: 4px;
}

.home .overlap-14 {
  height: 714px;
  position: relative;
}

.home .cambodianflag-2 {
  width: 1453px;
  height: 548px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.home .group-35 {
  width: 1453px;
  height: 399px;
  position: absolute;
  top: 311px;
  left: 1px;
}

.home .overlap-15 {
  height: 399px;
  position: relative;
}

.home .footer-2 {
  background-color: #0000;
  width: 1453px;
  height: 399px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .overlap-group-11 {
  width: 1257px;
  height: 68px;
  position: relative;
  top: 301px;
  left: 96px;
}

.home .line-11 {
  object-fit: cover;
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 11px;
}

.home .div-15 {
  width: 1246px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .p-2 {
  border: 1px solid #fff;
  border-radius: 2px;
  align-items: flex-start;
  width: 372px;
  height: 34px;
  padding: 6px 11.14px 6px 11px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 4px;
}

.home .element-2 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.home .text-wrapper-52 {
  color: #fff;
  letter-spacing: .8px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.home .text-wrapper-53 {
  font-family: Montserrat, Helvetica;
}

.home .ul-2 {
  width: 366px;
  height: 16px;
  position: absolute;
  top: 32px;
  left: 935px;
}

.home .frame-30 {
  width: 395px;
  height: 16px;
  position: relative;
  left: -36px;
}

.home .frame-31 {
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
  position: relative;
  left: 23px;
}

.home .text-wrapper-54 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 57px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.home .text-wrapper-55 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 95px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.home .text-wrapper-56 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 80px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.home .text-wrapper-57 {
  color: #fff;
  letter-spacing: 0;
  width: 92px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.home .frame-32 {
  width: 1256px;
  height: 240px;
  position: absolute;
  top: 45px;
  left: 98px;
}

.home .frame-33 {
  align-items: flex-start;
  gap: 136px;
  height: 180px;
  display: inline-flex;
  position: relative;
  top: 36px;
}

.home .frame-34 {
  align-items: flex-start;
  gap: 10px;
  width: 423px;
  height: 203px;
  margin-bottom: -23px;
  display: flex;
  position: relative;
}

.home .frame-35 {
  width: 377px;
  height: 201px;
  position: relative;
  overflow: hidden;
}

.home .group-36 {
  width: 238px;
  height: 41px;
  position: absolute;
  top: 12px;
  left: 395px;
}

.home .div-16 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 130px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .text-wrapper-58 {
  font-weight: 700;
  line-height: 12px;
}

.home .frame-36 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.home .frame-37 {
  flex: none;
  align-items: flex-start;
  gap: 44px;
  display: inline-flex;
  position: relative;
}

.home .image-12 {
  width: 91px;
  height: 59px;
  position: relative;
}

.home .frame-38 {
  flex: none;
  align-items: flex-start;
  gap: 45px;
  width: 361px;
  display: flex;
  position: relative;
}

.home .image-13 {
  object-fit: cover;
  width: 87px;
  height: 57px;
  position: relative;
}

.home .image-14 {
  object-fit: cover;
  width: 91px;
  height: 59px;
  position: relative;
}

.home .group-37 {
  width: 319px;
  height: 166px;
  position: relative;
}

.home .group-38 {
  width: 318px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 1px;
}

.home .frame-39 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 318px;
  height: 112px;
  display: flex;
  position: relative;
}

.home .frame-40 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9px;
  margin-right: -30.37px;
  display: inline-flex;
  position: relative;
}

.home .text-wrapper-59 {
  color: #fff;
  letter-spacing: 0;
  width: 106px;
  height: 16px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.home .text-wrapper-60 {
  color: #fff;
  letter-spacing: 0;
  width: 348.37px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.home .frame-41 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.home .div-17 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 136px;
  height: 15px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.home .text-wrapper-61 {
  font-weight: 700;
  line-height: 11px;
}

.home .text-wrapper-62 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 267px;
  height: 11px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.home .div-footer-social-2 {
  width: 230px;
  height: 161px;
  position: relative;
}

.home .text-wrapper-63 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 144px;
  height: 17px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 7px;
  left: 48px;
}

.home .frame-42 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px 15px;
  width: 184px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 48px;
}

.home .group-39 {
  width: 50px;
  height: 50px;
  position: relative;
}

.home .list-item-link-SVG-2 {
  background-image: url("group-12.53dfdb38.png");
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.home .symbol-11 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.home .telegram-app-3 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.home .symbol-12 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 10px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 19px;
  left: 12px;
}

.home .symbol-13 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 24px;
  height: 6px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 86px;
  left: 13px;
}

.home .symbol-14 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 81px;
}

.home .symbol-15 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 145px;
}

.home .subtract-2 {
  width: 1455px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .frame-77 {
  position: absolute !important;
  top: 172px !important;
  left: 108px !important;
}

.vision {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.vision .div-10 {
  background-color: #fff;
  width: 1440px;
  height: 2779px;
  position: relative;
  overflow-x: hidden;
}

.vision .group-2 {
  width: 942px;
  height: 56px;
  position: absolute;
  top: 809px;
  left: 251px;
}

.vision .button {
  all: unset;
  box-sizing: border-box;
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 402px;
  height: 56px;
  font-family: Montserrat, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 59px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision .button-statements {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 340px;
  height: 44px;
  font-family: Montserrat, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 598px;
}

.vision .group-3 {
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 904px;
  left: 97px;
}

.vision .overlap-group-5 {
  height: 5px;
  position: relative;
  top: -4px;
}

.vision .line-2 {
  width: 1246px;
  height: 2px;
  position: absolute;
  top: 3px;
  left: 0;
}

.vision .line-3 {
  width: 619px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 627px;
}

.vision .group-15 {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.vision .group-4 {
  width: 1442px;
  height: 714px;
  position: absolute;
  top: 2069px;
  left: -1px;
}

.vision .overlap-3 {
  height: 714px;
  position: relative;
}

.vision .cambodianflag {
  width: 1440px;
  height: 548px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.vision .group-5 {
  width: 1440px;
  height: 399px;
  position: absolute;
  top: 311px;
  left: 1px;
}

.vision .overlap-4 {
  height: 399px;
  position: relative;
}

.vision .footer {
  background-color: #0000;
  width: 1440px;
  height: 399px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision .overlap-group-6 {
  width: 1251px;
  height: 68px;
  position: relative;
  top: 301px;
  left: 96px;
}

.vision .line-4 {
  object-fit: cover;
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.vision .div-11 {
  width: 1246px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision .element-wrapper {
  border: 1px solid #fff;
  border-radius: 2px;
  align-items: flex-start;
  width: 372px;
  height: 34px;
  padding: 6px 11.14px 6px 11px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 4px;
}

.vision .element {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision .text-wrapper-25 {
  color: #fff;
  letter-spacing: .8px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.vision .text-wrapper-26 {
  font-family: Montserrat, Helvetica;
}

.vision .ul {
  width: 366px;
  height: 16px;
  position: absolute;
  top: 32px;
  left: 935px;
}

.vision .frame-12 {
  width: 395px;
  height: 16px;
  position: relative;
  left: -36px;
}

.vision .frame-13 {
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
  position: relative;
  left: 23px;
}

.vision .text-wrapper-27 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 57px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision .text-wrapper-28 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 95px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision .text-wrapper-29 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 80px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision .text-wrapper-30 {
  color: #fff;
  letter-spacing: 0;
  width: 92px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision .frame-14 {
  width: 1245px;
  height: 240px;
  position: absolute;
  top: 45px;
  left: 97px;
}

.vision .frame-15 {
  align-items: flex-start;
  gap: 136px;
  height: 180px;
  display: inline-flex;
  position: relative;
  top: 36px;
}

.vision .frame-16 {
  align-items: flex-start;
  gap: 10px;
  width: 423px;
  height: 203px;
  margin-bottom: -23px;
  display: flex;
  position: relative;
}

.vision .frame-17 {
  width: 377px;
  height: 201px;
  position: relative;
  overflow: hidden;
}

.vision .group-6 {
  width: 238px;
  height: 41px;
  position: absolute;
  top: 12px;
  left: 395px;
}

.vision .div-12 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 130px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision .text-wrapper-31 {
  font-weight: 700;
  line-height: 12px;
}

.vision .frame-18 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.vision .frame-19 {
  flex: none;
  align-items: flex-start;
  gap: 44px;
  display: inline-flex;
  position: relative;
}

.vision .image-7 {
  width: 91px;
  height: 59px;
  position: relative;
}

.vision .frame-20 {
  flex: none;
  align-items: flex-start;
  gap: 45px;
  width: 361px;
  display: flex;
  position: relative;
}

.vision .image-8 {
  object-fit: cover;
  width: 87px;
  height: 57px;
  position: relative;
}

.vision .image-9 {
  object-fit: cover;
  width: 91px;
  height: 59px;
  position: relative;
}

.vision .group-7 {
  width: 319px;
  height: 166px;
  position: relative;
}

.vision .group-8 {
  width: 318px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 1px;
}

.vision .frame-21 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 318px;
  height: 112px;
  display: flex;
  position: relative;
}

.vision .frame-22 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9px;
  margin-right: -30.37px;
  display: inline-flex;
  position: relative;
}

.vision .text-wrapper-32 {
  color: #fff;
  letter-spacing: 0;
  width: 106px;
  height: 16px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.vision .text-wrapper-33 {
  color: #fff;
  letter-spacing: 0;
  width: 348.37px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.vision .frame-23 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.vision .div-13 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 136px;
  height: 15px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision .text-wrapper-34 {
  font-weight: 700;
  line-height: 11px;
}

.vision .text-wrapper-35 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 267px;
  height: 11px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.vision .div-footer-social {
  width: 230px;
  height: 161px;
  position: relative;
}

.vision .text-wrapper-36 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 144px;
  height: 17px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 7px;
  left: 48px;
}

.vision .frame-24 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px 15px;
  width: 184px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 48px;
}

.vision .group-9 {
  width: 50px;
  height: 50px;
  position: relative;
}

.vision .list-item-link-SVG {
  background-image: url("group-6-2x.9fa295c5.png");
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.vision .symbol-4 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.vision .telegram-app {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.vision .symbol-5 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 10px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 19px;
  left: 12px;
}

.vision .symbol-6 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 24px;
  height: 6px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 86px;
  left: 13px;
}

.vision .symbol-7 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 81px;
}

.vision .symbol-8 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 145px;
}

.vision .subtract {
  width: 1442px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision .button-SVG {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 781px;
  left: 1323px;
}

.vision .frame-25 {
  flex-direction: column;
  align-items: center;
  height: 1060px;
  display: inline-flex;
  position: absolute;
  top: 905px;
  left: 97px;
}

.vision .link-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.vision .link-2 {
  border-color: #e6e7e8;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 1241px;
  height: 259px;
  position: relative;
}

.vision .heading-president-2 {
  color: #253b95;
  letter-spacing: 0;
  white-space: nowrap;
  width: 869px;
  height: 40px;
  font-family: Kantumruy, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  position: absolute;
  top: 108px;
  left: 372px;
}

.vision .text-wrapper-37 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 104px;
  height: 20px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 192px;
  left: 1137px;
}

.vision .text-wrapper-38 {
  color: #253b95;
  letter-spacing: 0;
  white-space: nowrap;
  width: 263px;
  height: 37px;
  font-family: Kantumruy, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 47px;
  position: absolute;
  top: 212px;
  left: 685px;
}

.vision .line-5 {
  width: 280px;
  height: 1px;
  position: absolute;
  top: 230px;
  left: 392px;
}

.vision .div-image-2 {
  background-image: url("div-image-1.ad8f8f2d.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 9px;
  width: 323px;
  height: 204px;
  position: absolute;
  top: 27px;
  left: 0;
  transform: rotate(180deg);
}

.vision .heading-president-3 {
  color: #253b95;
  letter-spacing: 0;
  white-space: nowrap;
  width: 852px;
  height: 40px;
  font-family: Kantumruy, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  position: absolute;
  top: 108px;
  left: 389px;
}

.vision .div-image-3 {
  background-image: url("div-image-2x.3b93c148.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 9px;
  width: 323px;
  height: 204px;
  position: absolute;
  top: 27px;
  left: 0;
}

.vision .div-image-4 {
  background-image: url("div-image-2.35399ca0.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 9px;
  width: 323px;
  height: 204px;
  position: absolute;
  top: 27px;
  left: 0;
  transform: rotate(180deg);
}

.vision .div-image-5 {
  background-image: url("div-image-3.11d3747f.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 9px;
  width: 323px;
  height: 201px;
  position: absolute;
  top: 30px;
  left: 0;
}

.vision .main-section {
  background-image: url("rectangle-29.f6b8640d.png");
  background-position: 50%;
  background-size: cover;
  width: 1440px;
  height: 560px;
  position: absolute;
  top: 195px;
  left: 0;
}

.vision .overlap-5 {
  height: 560px;
  position: relative;
}

.vision .div-absolute {
  background: linear-gradient(#000000c7 10.42%, #0000 100%);
  width: 1440px;
  height: 560px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.vision .heading-president-4 {
  -webkit-text-stroke: 1px #fff;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 667px;
  height: 86px;
  font-family: Metal, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 424px;
  left: 386px;
}

.vision .screenshot-10 {
  object-fit: cover;
  width: 595px;
  height: 334px;
  position: absolute;
  top: 51px;
  left: 423px;
}

.biography {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.biography .div-18 {
  background-color: #fff;
  width: 1440px;
  height: 4247px;
  position: relative;
  overflow-x: hidden;
}

.biography .overlap-16 {
  width: 1465px;
  height: 4056px;
  position: absolute;
  top: 195px;
  left: -2px;
}

.biography .background-4 {
  width: 1450px;
  height: 884px;
  position: absolute;
  top: 502px;
  left: 7px;
}

.biography .background-5 {
  width: 1461px;
  height: 1175px;
  position: absolute;
  top: 1378px;
  left: 1px;
}

.biography .background-6 {
  width: 1461px;
  height: 1175px;
  position: absolute;
  top: 2468px;
  left: 4px;
}

.biography .fluent-card-wrapper {
  width: 1248px;
  height: 651px;
  position: absolute;
  top: 2587px;
  left: 82px;
}

.biography .rectangle-wrapper {
  background-color: #fff;
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 1248px;
  height: 651px;
  display: flex;
  position: relative;
  box-shadow: 0 1.6px 3.6px #00000021, 0 0 2.9px #0000001c;
}

.biography .rectangle-3 {
  border: 9px solid #ffcb47;
  width: 1266px;
  height: 669px;
  margin: -9px;
  position: relative;
  box-shadow: 0 4px 4px #00000040;
}

.biography .un {
  width: 1440px;
  height: 499px;
  position: absolute;
  top: 0;
  left: 2px;
}

.biography .pseudo {
  width: 1442px;
  height: 499px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography .div-19 {
  width: 1180px;
  height: 122px;
  position: absolute;
  top: 438px;
  left: 142px;
}

.biography .cambodia-prime {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000040;
  white-space: nowrap;
  width: 991px;
  height: 30px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  position: absolute;
  top: 483px;
  left: 236px;
}

.biography .group-40 {
  width: 1180px;
  height: 338px;
  position: absolute;
  top: 1684px;
  left: 148px;
}

.biography .award-wrapper {
  background-color: #d9d9d9;
  border: 6px solid #ffcb47;
  width: 555px;
  height: 350px;
  position: absolute;
  top: -6px;
  left: -6px;
}

.biography .award {
  object-fit: cover;
  width: 543px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography .group-41 {
  width: 547px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 638px;
}

.biography .text-wrapper-64 {
  color: #000;
  letter-spacing: 0;
  width: 543px;
  height: 274px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 64px;
  left: 0;
}

.biography .text-wrapper-65 {
  -webkit-text-stroke: 1px #000;
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 377px;
  height: 31px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: underline;
  position: absolute;
  top: -1px;
  left: 78px;
}

.biography .group-42 {
  width: 1180px;
  height: 338px;
  position: absolute;
  top: 2147px;
  left: 148px;
}

.biography .overlap-17 {
  background-color: #d9d9d9;
  border: 6px solid #ffcb47;
  width: 555px;
  height: 350px;
  position: absolute;
  top: -6px;
  left: 632px;
}

.biography .group-43 {
  width: 547px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography .text-wrapper-66 {
  -webkit-text-stroke: 1px #000;
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 377px;
  height: 31px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: underline;
  position: absolute;
  top: -1px;
  left: 79px;
}

.biography .group-44 {
  width: 1174px;
  height: 563px;
  position: absolute;
  top: 2643px;
  left: 141px;
}

.biography .group-45 {
  width: 1182px;
  height: 247px;
  position: absolute;
  top: 1340px;
  left: 132px;
}

.biography .overlap-18 {
  background-image: url("div.1f19ae9b.png");
  background-size: 100% 100%;
  width: 1180px;
  height: 122px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography .text-wrapper-67 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000040;
  white-space: nowrap;
  width: 991px;
  height: 30px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  position: absolute;
  top: 46px;
  left: 94px;
}

.biography .medal {
  object-fit: cover;
  width: 168px;
  height: 125px;
  position: absolute;
  top: 122px;
  left: 506px;
}

.biography .group-46 {
  width: 1464px;
  height: 714px;
  position: absolute;
  top: 3342px;
  left: 1px;
}

.biography .overlap-19 {
  height: 714px;
  position: relative;
}

.biography .cambodianflag-3 {
  width: 1462px;
  height: 548px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.biography .group-47 {
  width: 1462px;
  height: 399px;
  position: absolute;
  top: 311px;
  left: 1px;
}

.biography .overlap-20 {
  height: 399px;
  position: relative;
}

.biography .footer-3 {
  background-color: #0000;
  width: 1462px;
  height: 399px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography .overlap-group-12 {
  width: 1262px;
  height: 68px;
  position: relative;
  top: 301px;
  left: 96px;
}

.biography .line-12 {
  object-fit: cover;
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 16px;
}

.biography .div-20 {
  width: 1246px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography .p-3 {
  border: 1px solid #fff;
  border-radius: 2px;
  align-items: flex-start;
  width: 372px;
  height: 34px;
  padding: 6px 11.14px 6px 11px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 4px;
}

.biography .element-3 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography .text-wrapper-68 {
  color: #fff;
  letter-spacing: .8px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.biography .text-wrapper-69 {
  font-family: Montserrat, Helvetica;
}

.biography .ul-3 {
  width: 366px;
  height: 16px;
  position: absolute;
  top: 32px;
  left: 935px;
}

.biography .frame-43 {
  width: 395px;
  height: 16px;
  position: relative;
  left: -36px;
}

.biography .frame-44 {
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
  position: relative;
  left: 23px;
}

.biography .text-wrapper-70 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 57px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography .text-wrapper-71 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 95px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography .text-wrapper-72 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 80px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography .text-wrapper-73 {
  color: #fff;
  letter-spacing: 0;
  width: 92px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography .frame-45 {
  width: 1264px;
  height: 240px;
  position: absolute;
  top: 45px;
  left: 98px;
}

.biography .frame-46 {
  align-items: flex-start;
  gap: 136px;
  height: 180px;
  display: inline-flex;
  position: relative;
  top: 36px;
}

.biography .frame-47 {
  align-items: flex-start;
  gap: 10px;
  width: 423px;
  height: 203px;
  margin-bottom: -23px;
  display: flex;
  position: relative;
}

.biography .frame-48 {
  width: 377px;
  height: 201px;
  position: relative;
  overflow: hidden;
}

.biography .group-48 {
  width: 238px;
  height: 41px;
  position: absolute;
  top: 12px;
  left: 395px;
}

.biography .div-21 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 130px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography .text-wrapper-74 {
  font-weight: 700;
  line-height: 12px;
}

.biography .frame-49 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.biography .frame-50 {
  flex: none;
  align-items: flex-start;
  gap: 44px;
  display: inline-flex;
  position: relative;
}

.biography .image-15 {
  width: 91px;
  height: 59px;
  position: relative;
}

.biography .frame-51 {
  flex: none;
  align-items: flex-start;
  gap: 45px;
  width: 361px;
  display: flex;
  position: relative;
}

.biography .image-16 {
  object-fit: cover;
  width: 87px;
  height: 57px;
  position: relative;
}

.biography .image-17 {
  object-fit: cover;
  width: 91px;
  height: 59px;
  position: relative;
}

.biography .group-49 {
  width: 319px;
  height: 166px;
  position: relative;
}

.biography .group-50 {
  width: 318px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 1px;
}

.biography .frame-52 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 318px;
  height: 112px;
  display: flex;
  position: relative;
}

.biography .frame-53 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9px;
  margin-right: -30.37px;
  display: inline-flex;
  position: relative;
}

.biography .text-wrapper-75 {
  color: #fff;
  letter-spacing: 0;
  width: 106px;
  height: 16px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.biography .text-wrapper-76 {
  color: #fff;
  letter-spacing: 0;
  width: 348.37px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.biography .frame-54 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.biography .div-22 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 136px;
  height: 15px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography .text-wrapper-77 {
  font-weight: 700;
  line-height: 11px;
}

.biography .text-wrapper-78 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 267px;
  height: 11px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.biography .div-footer-social-3 {
  width: 230px;
  height: 161px;
  position: relative;
}

.biography .text-wrapper-79 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 144px;
  height: 17px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 7px;
  left: 48px;
}

.biography .frame-55 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px 15px;
  width: 184px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 48px;
}

.biography .group-51 {
  width: 50px;
  height: 50px;
  position: relative;
}

.biography .list-item-link-SVG-3 {
  background-image: url("group-18.bfb6e30d.png");
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.biography .symbol-16 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.biography .telegram-app-4 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.biography .symbol-17 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 10px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 19px;
  left: 12px;
}

.biography .symbol-18 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 24px;
  height: 6px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 86px;
  left: 13px;
}

.biography .symbol-19 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 81px;
}

.biography .symbol-20 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 145px;
}

.biography .subtract-3 {
  width: 1464px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography .group-52 {
  width: 1252px;
  height: 637px;
  position: absolute;
  top: 626px;
  left: 95px;
}

.biography .frame-56 {
  width: 572px;
  height: 551px;
  position: absolute;
  top: 26px;
  left: 680px;
}

.biography .overlap-group-13 {
  background-color: #fff;
  border-radius: 4px;
  width: 593px;
  height: 506px;
  position: relative;
  top: 76px;
  left: -22px;
  box-shadow: 0 1.6px 3.6px #00000021, 0 0 2.9px #0000001c;
}

.biography .frame-57 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 551px;
  height: 449px;
  display: flex;
  position: relative;
  top: 28px;
  left: 21px;
}

.biography .text-wrapper-80 {
  color: #333;
  letter-spacing: 0;
  width: 495.41px;
  height: 95px;
  margin-top: -1px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.biography .text-wrapper-81 {
  color: #333;
  letter-spacing: 0;
  width: 552px;
  height: 161px;
  margin-right: -1px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.biography .text-wrapper-82 {
  color: #333;
  letter-spacing: 0;
  width: 551px;
  height: 161px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.biography .group-53 {
  width: 589px;
  height: 637px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography .text-wrapper-83 {
  color: #333;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 490px;
  height: 53px;
  font-family: Montserrat, Helvetica;
  font-size: 37px;
  font-weight: 700;
  line-height: 48px;
  position: absolute;
  top: 0;
  left: 48px;
}

.biography .text-wrapper-84 {
  color: #333;
  letter-spacing: 0;
  text-align: center;
  width: 442px;
  height: 123px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  position: absolute;
  top: 514px;
  left: 72px;
}

.biography .cambodia-pm {
  width: 585px;
  height: 369px;
  position: absolute;
  top: 97px;
  left: 0;
}

.biography .group-15-instance {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.frame-wrapper {
  align-items: center;
  gap: 43px;
  width: 1036px;
  display: flex;
  position: relative;
}

.frame-wrapper .fluent-card {
  background-color: #fff;
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 226px;
  height: 277px;
  display: flex;
  position: relative;
  box-shadow: 0 1.6px 3.6px #00000021, 0 0 2.9px #0000001c;
}

.frame-wrapper .frame-7 {
  width: 189px;
  height: 249px;
  position: relative;
}

.frame-wrapper .image-3 {
  object-fit: cover;
  width: 191px;
  height: 143px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-wrapper .text-wrapper-19 {
  color: #171717;
  letter-spacing: 0;
  width: 188px;
  height: 70px;
  font-family: Montserrat, Helvetica;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 152px;
  left: 0;
}

.frame-wrapper .text-wrapper-20 {
  color: #000;
  letter-spacing: 0;
  width: 81px;
  height: 10px;
  font-family: Montserrat, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  position: absolute;
  top: 235px;
  left: 0;
}

.frame-wrapper .icon-facebook {
  width: 8px;
  height: 16px;
  position: absolute;
  top: 233px;
  left: 174px;
}

.frame-wrapper .vector {
  width: 18px;
  height: 16px;
  position: absolute;
  top: 233px;
  left: 148px;
}

.announcement {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.announcement .div-23 {
  background-color: #fff;
  width: 1440px;
  height: 3309px;
  position: relative;
  overflow-x: hidden;
}

.announcement .overlap-21 {
  width: 1468px;
  height: 2756px;
  position: absolute;
  top: 556px;
  left: -15px;
}

.announcement .background-7 {
  width: 1450px;
  height: 797px;
  position: absolute;
  top: 1452px;
  left: 10px;
}

.announcement .div-24 {
  width: 1180px;
  height: 764px;
  position: absolute;
  top: 1071px;
  left: 146px;
}

.announcement .rectangle-4 {
  background-color: #253b95;
  width: 1032px;
  height: 1340px;
  position: absolute;
  top: 0;
  left: 225px;
}

.announcement .image-18 {
  object-fit: cover;
  width: 883px;
  height: 1199px;
  position: absolute;
  top: 71px;
  left: 300px;
}

.announcement .rectangle-5 {
  background-color: #f5f5f5;
  width: 1032px;
  height: 103px;
  position: absolute;
  top: 1381px;
  left: 225px;
}

.announcement .text-wrapper-85 {
  color: #171717;
  letter-spacing: 0;
  white-space: nowrap;
  width: 503px;
  height: 17px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  position: absolute;
  top: 1417px;
  left: 335px;
}

.announcement .file-svg {
  width: 53px;
  height: 52px;
  position: absolute;
  top: 1400px;
  left: 271px;
}

.announcement .rectangle-6 {
  background-color: #e41d2c;
  width: 147px;
  height: 52px;
  position: absolute;
  top: 1400px;
  left: 1046px;
}

.announcement .text-wrapper-86 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 92px;
  height: 17px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  position: absolute;
  top: 1417px;
  left: 1074px;
}

.announcement .frame-92 {
  position: absolute !important;
  top: 1646px !important;
  left: 224px !important;
}

.announcement .group-54 {
  width: 226px;
  height: 34px;
  position: absolute;
  top: 1564px;
  left: 623px;
}

.announcement .text-wrapper-87 {
  color: #000;
  letter-spacing: 0;
  width: 224px;
  height: 24px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.announcement .line-13 {
  width: 100px;
  height: 4px;
  position: absolute;
  top: 32px;
  left: 62px;
}

.announcement .group-55 {
  width: 1468px;
  height: 714px;
  position: absolute;
  top: 2042px;
  left: 0;
}

.announcement .overlap-22 {
  height: 714px;
  position: relative;
}

.announcement .cambodianflag-4 {
  width: 1466px;
  height: 548px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.announcement .group-56 {
  width: 1466px;
  height: 399px;
  position: absolute;
  top: 311px;
  left: 1px;
}

.announcement .overlap-23 {
  height: 399px;
  position: relative;
}

.announcement .footer-4 {
  background-color: #0000;
  width: 1466px;
  height: 399px;
  position: absolute;
  top: 0;
  left: 0;
}

.announcement .overlap-group-14 {
  width: 1264px;
  height: 68px;
  position: relative;
  top: 301px;
  left: 96px;
}

.announcement .line-14 {
  object-fit: cover;
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 18px;
}

.announcement .div-25 {
  width: 1246px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.announcement .p-4 {
  border: 1px solid #fff;
  border-radius: 2px;
  align-items: flex-start;
  width: 372px;
  height: 34px;
  padding: 6px 11.14px 6px 11px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 4px;
}

.announcement .element-4 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.announcement .text-wrapper-88 {
  color: #fff;
  letter-spacing: .8px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.announcement .text-wrapper-89 {
  font-family: Montserrat, Helvetica;
}

.announcement .ul-4 {
  width: 366px;
  height: 16px;
  position: absolute;
  top: 32px;
  left: 935px;
}

.announcement .frame-58 {
  width: 395px;
  height: 16px;
  position: relative;
  left: -36px;
}

.announcement .frame-59 {
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
  position: relative;
  left: 23px;
}

.announcement .text-wrapper-90 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 57px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.announcement .text-wrapper-91 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 95px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.announcement .text-wrapper-92 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 80px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.announcement .text-wrapper-93 {
  color: #fff;
  letter-spacing: 0;
  width: 92px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.announcement .frame-60 {
  width: 1267px;
  height: 240px;
  position: absolute;
  top: 45px;
  left: 99px;
}

.announcement .frame-61 {
  align-items: flex-start;
  gap: 136px;
  height: 180px;
  display: inline-flex;
  position: relative;
  top: 36px;
}

.announcement .frame-62 {
  align-items: flex-start;
  gap: 10px;
  width: 423px;
  height: 203px;
  margin-bottom: -23px;
  display: flex;
  position: relative;
}

.announcement .frame-63 {
  width: 377px;
  height: 201px;
  position: relative;
  overflow: hidden;
}

.announcement .group-57 {
  width: 238px;
  height: 41px;
  position: absolute;
  top: 12px;
  left: 395px;
}

.announcement .div-26 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 130px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.announcement .text-wrapper-94 {
  font-weight: 700;
  line-height: 12px;
}

.announcement .frame-64 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.announcement .frame-65 {
  flex: none;
  align-items: flex-start;
  gap: 44px;
  display: inline-flex;
  position: relative;
}

.announcement .image-19 {
  width: 91px;
  height: 59px;
  position: relative;
}

.announcement .frame-66 {
  flex: none;
  align-items: flex-start;
  gap: 45px;
  width: 361px;
  display: flex;
  position: relative;
}

.announcement .image-20 {
  object-fit: cover;
  width: 87px;
  height: 57px;
  position: relative;
}

.announcement .image-21 {
  object-fit: cover;
  width: 91px;
  height: 59px;
  position: relative;
}

.announcement .group-58 {
  width: 319px;
  height: 166px;
  position: relative;
}

.announcement .group-59 {
  width: 318px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 1px;
}

.announcement .frame-67 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 318px;
  height: 112px;
  display: flex;
  position: relative;
}

.announcement .frame-68 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9px;
  margin-right: -30.37px;
  display: inline-flex;
  position: relative;
}

.announcement .text-wrapper-95 {
  color: #fff;
  letter-spacing: 0;
  width: 106px;
  height: 16px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.announcement .text-wrapper-96 {
  color: #fff;
  letter-spacing: 0;
  width: 348.37px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.announcement .frame-69 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.announcement .div-27 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 136px;
  height: 15px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.announcement .text-wrapper-97 {
  font-weight: 700;
  line-height: 11px;
}

.announcement .text-wrapper-98 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 267px;
  height: 11px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.announcement .div-footer-social-4 {
  width: 230px;
  height: 161px;
  position: relative;
}

.announcement .text-wrapper-99 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 144px;
  height: 17px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 7px;
  left: 48px;
}

.announcement .frame-70 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px 15px;
  width: 184px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 48px;
}

.announcement .group-60 {
  width: 50px;
  height: 50px;
  position: relative;
}

.announcement .list-item-link-SVG-4 {
  background-image: url("group-24.afb0afcd.png");
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.announcement .symbol-21 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.announcement .telegram-app-5 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.announcement .symbol-22 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 10px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 19px;
  left: 12px;
}

.announcement .symbol-23 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 24px;
  height: 6px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 86px;
  left: 13px;
}

.announcement .symbol-24 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 81px;
}

.announcement .symbol-25 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 145px;
}

.announcement .subtract-4 {
  width: 1468px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.announcement .group-61 {
  width: 914px;
  height: 16px;
  position: absolute;
  top: 221px;
  left: 95px;
}

.announcement .text-wrapper-100 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 839px;
  height: 13px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 73px;
}

.announcement .vector-5 {
  width: 6px;
  height: 10px;
  position: absolute;
  top: 4px;
  left: 38px;
}

.announcement .vector-6 {
  width: 19px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.announcement .text-wrapper-101 {
  color: #171717;
  letter-spacing: 0;
  width: 1239px;
  height: 148px;
  font-family: Montserrat, Helvetica;
  font-size: 36px;
  font-weight: 700;
  line-height: 56px;
  position: absolute;
  top: 307px;
  left: 94px;
}

.announcement .span-wrapper {
  width: 88px;
  height: 27px;
  position: absolute;
  top: 483px;
  left: 108px;
}

.announcement .span-2 {
  opacity: .7;
  width: 84px;
  height: 18px;
  position: relative;
  top: 4px;
  left: -11px;
}

.announcement .text-wrapper-102 {
  color: #171717;
  letter-spacing: 0;
  white-space: nowrap;
  height: 18px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  top: -1px;
  left: 0;
}

.announcement .svg-2 {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 1px;
  left: 90px;
}

.announcement .text-wrapper-103 {
  color: #171717;
  letter-spacing: 0;
  text-align: right;
  width: 166px;
  height: 20px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 256px;
  left: 1180px;
}

.announcement .icon-facebook-2 {
  width: 12px;
  height: 21px;
  position: absolute;
  top: 483px;
  left: 1270px;
}

.announcement .vector-7 {
  width: 27px;
  height: 21px;
  position: absolute;
  top: 483px;
  left: 1319px;
}

.announcement .design-component-instance-node {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.group-wrapper {
  width: 1381px;
  height: 439px;
}

.group-wrapper .frame-8 {
  align-items: center;
  gap: 17px;
  display: inline-flex;
  position: relative;
  top: 13px;
  left: 66px;
}

.group-wrapper .button-go-to {
  background-color: #253795;
  border-radius: 35px;
  width: 43px;
  height: 43px;
  position: relative;
}

.group-wrapper .symbol-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 19px;
  height: 13px;
  font-family: Inter, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  position: absolute;
  top: 12px;
  left: 11px;
}

.group-wrapper .div-relative-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.group-wrapper .div-relative {
  background-color: #ffffff01;
  border-radius: 4px;
  width: 270px;
  height: 413.14px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 4px -2px #0000001a, 0 4px 6px -1px #0000001a;
}

.group-wrapper .img-2 {
  width: 9px;
  height: 9px;
  position: absolute;
  top: 389px;
  left: 12px;
}

.group-wrapper .text-wrapper-21 {
  color: #253b95;
  letter-spacing: 0;
  white-space: nowrap;
  width: 86px;
  height: 15px;
  font-family: Montserrat, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 385px;
  left: 29px;
}

.group-wrapper .image-4 {
  border-radius: 4px 4px 0 0;
  width: 363px;
  height: 381px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.group-wrapper .image-5 {
  width: 269px;
  height: 375px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-wrapper .vector-2 {
  width: 11px;
  height: 20px;
  position: absolute;
  top: 384px;
  left: 248px;
}

.group-wrapper .button-go-to-2 {
  background-color: #253795;
  border-radius: 35px;
  width: 43px;
  height: 43px;
  position: relative;
  transform: rotate(180deg);
}

.group-wrapper .symbol-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 19px;
  height: 13px;
  font-family: Inter, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  position: absolute;
  top: 16px;
  left: 11px;
}

.contact {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.contact .div-28 {
  background-color: #fff;
  width: 1440px;
  height: 2569px;
  position: relative;
  overflow-x: hidden;
}

.contact .overlap-24 {
  width: 1440px;
  height: 411px;
  position: absolute;
  top: 195px;
  left: 0;
}

.contact .section-image {
  background-image: url("section-image.956d21f9.png");
  background-position: 50%;
  background-size: cover;
  width: 1440px;
  height: 410px;
  position: absolute;
  top: 1px;
  left: 0;
}

.contact .rectangle-7 {
  background-color: #00000040;
  width: 1440px;
  height: 411px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .section-2 {
  background-color: #253795;
  width: 1440px;
  height: 56px;
  position: absolute;
  top: 606px;
  left: 0;
}

.contact .group-62 {
  width: 1243px;
  height: 20px;
  position: relative;
  top: 18px;
  left: 101px;
}

.contact .div-relative-2 {
  width: 156px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .img-3 {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: 0;
}

.contact .text-wrapper-104 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 128px;
  height: 18px;
  font-family: Montserrat, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 30px;
}

.contact .div-relative-3 {
  width: 168px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 285px;
}

.contact .text-wrapper-105 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 139px;
  height: 18px;
  font-family: Montserrat, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 30px;
}

.contact .div-relative-4 {
  width: 661px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 582px;
}

.contact .img-4 {
  width: 13px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: 0;
}

.contact .text-wrapper-106 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 638px;
  height: 18px;
  font-family: Kantumruy, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 25px;
}

.contact .section-iframe {
  border-color: #e41d2c;
  border-top-style: solid;
  border-top-width: 8px;
  border-bottom-style: solid;
  border-bottom-width: 8px;
  width: 1440px;
  height: 400px;
  position: absolute;
  top: 662px;
  left: 0;
}

.contact .body {
  background-color: gray;
  height: 384px;
  position: relative;
  top: 8px;
}

.contact .div-29 {
  background-color: #e5e3df;
  width: 1440px;
  height: 384px;
  position: relative;
  overflow: hidden;
}

.contact .overlap-25 {
  background-image: url("vt.b589d6c5.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: 179px;
  left: 834px;
}

.contact .image-22 {
  width: 52px;
  height: 22px;
  position: absolute;
  top: 168px;
  left: 100px;
}

.contact .vt {
  background-image: url("vt-1.34e13f54.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: 179px;
  left: 578px;
}

.contact .vt-2 {
  background-image: url("vt-2.53bfa9b4.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: -77px;
  left: 834px;
}

.contact .vt-3 {
  background-image: url("vt-3.6a785b34.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: -77px;
  left: 322px;
}

.contact .vt-4 {
  background-image: url("vt-4.165f2561.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: 179px;
  left: 322px;
}

.contact .vt-5 {
  background-image: url("vt-5.0cc0f513.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: -77px;
  left: 578px;
}

.contact .vt-6 {
  background-image: url("vt-6.23a6550d.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: -77px;
  left: 1090px;
}

.contact .overlap-group-15 {
  width: 1024px;
  height: 256px;
  position: absolute;
  top: 179px;
  left: 1090px;
}

.contact .vt-7 {
  background-image: url("vt-7.4cfba0d4.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .vt-8 {
  background-image: url("vt-12.78933710.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: 0;
  left: 768px;
}

.contact .vt-9 {
  background-image: url("vt-14.f9b577da.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: 0;
  left: 256px;
}

.contact .vt-10 {
  background-image: url("vt-17.1ac5d564.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: 0;
  left: 512px;
}

.contact .div-30 {
  background-color: #fff;
  border-radius: 2px;
  width: 40px;
  height: 81px;
  position: absolute;
  top: 100px;
  left: 780px;
  overflow: hidden;
  box-shadow: 0 1px 4px -1px #0000004c;
}

.contact .button-zoom-in {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 326px;
  left: 18656px;
}

.contact .div-31 {
  background-color: #e6e6e6;
  width: 30px;
  height: 1px;
  position: absolute;
  top: 40px;
  left: 5px;
}

.contact .button-zoom-out {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 367px;
  left: 18656px;
}

.contact .div-32 {
  opacity: .7;
  background-color: #f5f5f5;
  width: 382px;
  height: 14px;
  position: absolute;
  top: 191px;
  left: 223px;
  transform: rotate(180deg);
}

.contact .button-keyboard {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 87px;
  height: 14px;
  font-family: Roboto, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  position: absolute;
  top: 190px;
  left: 610px;
}

.contact .div-33 {
  opacity: .7;
  background-color: #f5f5f5;
  width: 394px;
  height: 14px;
  position: absolute;
  top: 191px;
  left: 310px;
  transform: rotate(180deg);
}

.contact .text-wrapper-107 {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 75px;
  height: 11px;
  font-family: Roboto, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  position: absolute;
  top: 191px;
  left: 709px;
}

.contact .div-34 {
  opacity: .7;
  background-color: #f5f5f5;
  width: 441px;
  height: 14px;
  position: absolute;
  top: 191px;
  left: 350px;
  transform: rotate(180deg);
}

.contact .link-terms {
  color: #000;
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: 28px;
  height: 11px;
  font-family: Roboto, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  position: absolute;
  top: 191px;
  left: 796px;
}

.contact .vt-11 {
  background-image: url("vt-8.84dc4f0a.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: 179px;
  left: 66px;
}

.contact .button-show-wrapper {
  background-image: url("vt-9.fbc1b2f2.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: 179px;
  left: -190px;
}

.contact .button-show {
  background-color: #fff;
  border: 2px solid;
  border-radius: 3px;
  width: 42px;
  height: 42px;
  position: relative;
  top: 152px;
  left: 200px;
  overflow: hidden;
  box-shadow: 0 2px 6px #0000004c;
}

.contact .region-map-wrapper {
  background-color: #e5e3df;
  width: 38px;
  height: 38px;
  position: relative;
  top: 2px;
  left: 2px;
  overflow: hidden;
}

.contact .region-map {
  background-image: url("region-map.3d4d1636.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: relative;
  top: -143px;
  left: -61px;
}

.contact .overlap-26 {
  background-image: url("vt-10.d58bdd3f.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: -77px;
  left: -190px;
}

.contact .div-35 {
  background-color: #fff;
  border-radius: 2px;
  width: 178px;
  height: 92px;
  position: relative;
  top: 87px;
  left: 22px;
  overflow: hidden;
  transform: rotate(180deg);
  box-shadow: 0 1px 4px -1px #0000004c;
}

.contact .div-place-name {
  width: 76px;
  height: 16px;
  position: absolute;
  top: 66px;
  left: 12px;
  overflow: hidden;
}

.contact .text-wrapper-108 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 92px;
  height: 16px;
  font-family: Roboto, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 0;
}

.contact .text-wrapper-109 {
  color: #5b5b5b;
  letter-spacing: 0;
  white-space: nowrap;
  width: 69px;
  height: 14px;
  font-family: Roboto, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 45px;
  left: 12px;
}

.contact .link-get-directions {
  background-image: url("link-get-directions-to-this-location-on-google-maps.a14a153e.png");
  background-position: 50%;
  background-size: cover;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 60px;
  left: 183px;
}

.contact .link-get-directions-2 {
  width: 70px;
  height: 14px;
  position: absolute;
  top: 41px;
  left: 159px;
  transform: rotate(180deg);
}

.contact .text-wrapper-110 {
  color: #1a73e8;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 54px;
  height: 14px;
  font-family: Roboto, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 8px;
}

.contact .link-view-larger-map {
  color: #1a73e8;
  letter-spacing: 0;
  white-space: nowrap;
  width: 87px;
  height: 14px;
  font-family: Roboto, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 12px;
}

.contact .vt-12 {
  background-image: url("vt-11.b7f7cdd4.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: -77px;
  left: 66px;
}

.contact .vt-13 {
  background-image: url("vt-13.b40971f0.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: -77px;
  left: 1346px;
}

.contact .vt-14 {
  background-image: url("vt-15.ca43367d.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: -77px;
  left: 1858px;
}

.contact .vt-15 {
  background-image: url("vt-16.b1bb1c98.png");
  background-position: 50%;
  background-size: cover;
  width: 256px;
  height: 256px;
  position: absolute;
  top: -77px;
  left: 1602px;
}

.contact .overlap-27 {
  width: 1536px;
  height: 727px;
  position: absolute;
  top: 1085px;
  left: -48px;
}

.contact .div-px {
  width: 1536px;
  height: 727px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .section-3 {
  border-color: #e41d2c;
  border-top-style: solid;
  border-top-width: 1px;
  width: 597px;
  height: 1px;
  position: absolute;
  top: 18px;
  left: 80px;
}

.contact .section-4 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 151px;
  height: 32px;
  font-family: Kantumruy, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  position: absolute;
  top: 2px;
  left: 693px;
}

.contact .section-5 {
  border-color: #e41d2c;
  border-top-style: solid;
  border-top-width: 1px;
  width: 597px;
  height: 1px;
  position: absolute;
  top: 18px;
  left: 859px;
}

.contact .section-6 {
  border: 2px solid #e41d2c;
  width: 40px;
  height: 4px;
  position: absolute;
  top: 51px;
  left: 748px;
}

.contact .section-link-img {
  width: 20px;
  height: 32px;
  position: absolute;
  top: 132px;
  left: 185px;
}

.contact .group-130 {
  position: absolute !important;
  top: 258px !important;
  left: 80px !important;
}

.contact .section-link {
  color: #253795;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 79px;
  height: 24px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 234px;
  left: 154px;
}

.contact .vector-8 {
  width: 32px;
  height: 26px;
  position: absolute;
  top: 136px;
  left: 407px;
}

.contact .section-link-twitter {
  color: #253795;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 56px;
  height: 24px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 172px;
  left: 395px;
}

.contact .vector-9 {
  width: 31px;
  height: 31px;
  position: absolute;
  top: 134px;
  left: 637px;
}

.contact .section-link-2 {
  color: #253795;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 75px;
  height: 24px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 172px;
  left: 615px;
}

.contact .vector-10 {
  width: 28px;
  height: 32px;
  position: absolute;
  top: 133px;
  left: 867px;
}

.contact .section-link-tiktok {
  color: #253795;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 49px;
  height: 24px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 172px;
  left: 857px;
}

.contact .vector-11 {
  width: 34px;
  height: 24px;
  position: absolute;
  top: 137px;
  left: 1094px;
}

.contact .section-link-youtube {
  color: #253795;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 68px;
  height: 24px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 172px;
  left: 1077px;
}

.contact .vector-12 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 135px;
  left: 1326px;
}

.contact .section-link-3 {
  color: #253795;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 83px;
  height: 24px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 172px;
  left: 1299px;
}

.contact .group-63 {
  width: 84px;
  height: 12px;
  position: absolute;
  top: 708px;
  left: 726px;
}

.contact .list-item-button-go {
  background-color: #253795;
  border: 2px solid #a9212d;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .list-item-button-go-2 {
  border: 2px solid #a9212d;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 18px;
}

.contact .list-item-button-go-3 {
  border: 2px solid #a9212d;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 36px;
}

.contact .list-item-button-go-4 {
  border: 2px solid #a71f2d;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 54px;
}

.contact .list-item-button-go-5 {
  border: 2px solid #a9212d;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 72px;
}

.contact .group-64 {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.contact .group-65 {
  width: 1463px;
  height: 714px;
  position: absolute;
  top: 1855px;
  left: 9px;
}

.contact .overlap-28 {
  height: 714px;
  position: relative;
}

.contact .cambodianflag-5 {
  width: 1461px;
  height: 548px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.contact .group-66 {
  width: 1461px;
  height: 399px;
  position: absolute;
  top: 311px;
  left: 1px;
}

.contact .overlap-29 {
  height: 399px;
  position: relative;
}

.contact .footer-5 {
  background-color: #0000;
  width: 1461px;
  height: 399px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .overlap-group-16 {
  width: 1261px;
  height: 68px;
  position: relative;
  top: 301px;
  left: 96px;
}

.contact .line-15 {
  object-fit: cover;
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 15px;
}

.contact .div-36 {
  width: 1246px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .p-5 {
  border: 1px solid #fff;
  border-radius: 2px;
  align-items: flex-start;
  width: 372px;
  height: 34px;
  padding: 6px 11.14px 6px 11px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 4px;
}

.contact .element-5 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.contact .text-wrapper-111 {
  color: #fff;
  letter-spacing: .8px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.contact .text-wrapper-112 {
  font-family: Montserrat, Helvetica;
}

.contact .ul-5 {
  width: 366px;
  height: 16px;
  position: absolute;
  top: 32px;
  left: 935px;
}

.contact .frame-71 {
  width: 395px;
  height: 16px;
  position: relative;
  left: -36px;
}

.contact .frame-72 {
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
  position: relative;
  left: 23px;
}

.contact .text-wrapper-113 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 57px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.contact .text-wrapper-114 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 95px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.contact .text-wrapper-115 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 80px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.contact .text-wrapper-116 {
  color: #fff;
  letter-spacing: 0;
  width: 92px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.contact .frame-73 {
  width: 1263px;
  height: 240px;
  position: absolute;
  top: 45px;
  left: 98px;
}

.contact .frame-74 {
  align-items: flex-start;
  gap: 136px;
  height: 180px;
  display: inline-flex;
  position: relative;
  top: 36px;
}

.contact .frame-75 {
  align-items: flex-start;
  gap: 10px;
  width: 423px;
  height: 203px;
  margin-bottom: -23px;
  display: flex;
  position: relative;
}

.contact .frame-76 {
  width: 377px;
  height: 201px;
  position: relative;
  overflow: hidden;
}

.contact .group-67 {
  width: 238px;
  height: 41px;
  position: absolute;
  top: 12px;
  left: 395px;
}

.contact .div-37 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 130px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .text-wrapper-117 {
  font-weight: 700;
  line-height: 12px;
}

.contact .frame-78 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.contact .frame-79 {
  flex: none;
  align-items: flex-start;
  gap: 44px;
  display: inline-flex;
  position: relative;
}

.contact .image-23 {
  width: 91px;
  height: 59px;
  position: relative;
}

.contact .frame-80 {
  flex: none;
  align-items: flex-start;
  gap: 45px;
  width: 361px;
  display: flex;
  position: relative;
}

.contact .image-24 {
  object-fit: cover;
  width: 87px;
  height: 57px;
  position: relative;
}

.contact .image-25 {
  object-fit: cover;
  width: 91px;
  height: 59px;
  position: relative;
}

.contact .group-68 {
  width: 319px;
  height: 166px;
  position: relative;
}

.contact .group-69 {
  width: 318px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 1px;
}

.contact .frame-81 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 318px;
  height: 112px;
  display: flex;
  position: relative;
}

.contact .frame-82 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9px;
  margin-right: -30.37px;
  display: inline-flex;
  position: relative;
}

.contact .text-wrapper-118 {
  color: #fff;
  letter-spacing: 0;
  width: 106px;
  height: 16px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.contact .text-wrapper-119 {
  color: #fff;
  letter-spacing: 0;
  width: 348.37px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.contact .frame-83 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.contact .div-38 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 136px;
  height: 15px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.contact .text-wrapper-120 {
  font-weight: 700;
  line-height: 11px;
}

.contact .text-wrapper-121 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 267px;
  height: 11px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.contact .div-footer-social-5 {
  width: 230px;
  height: 161px;
  position: relative;
}

.contact .text-wrapper-122 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 144px;
  height: 17px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 7px;
  left: 48px;
}

.contact .frame-84 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px 15px;
  width: 184px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 48px;
}

.contact .group-70 {
  width: 50px;
  height: 50px;
  position: relative;
}

.contact .list-item-link-SVG-5 {
  background-image: url("group.c0f72102.png");
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.contact .symbol-26 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.contact .telegram-app-6 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.contact .symbol-27 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 10px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 19px;
  left: 12px;
}

.contact .symbol-28 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 24px;
  height: 6px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 86px;
  left: 13px;
}

.contact .symbol-29 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 81px;
}

.contact .symbol-30 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 145px;
}

.contact .subtract-5 {
  width: 1463px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.component-variant {
  width: 1272px;
  height: 1253px;
}

.component-variant .frame-9 {
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  display: inline-flex;
  position: relative;
}

.component-variant .frame-10 {
  background-color: #fff;
  border-radius: 9px;
  width: 1272px;
  height: 226px;
  position: relative;
  box-shadow: 0 2px 15px #0000001c;
}

.component-variant .div-image {
  object-fit: cover;
  width: 348px;
  height: 200px;
  position: absolute;
  top: 13px;
  left: 13px;
}

.component-variant .heading-president {
  color: #000;
  letter-spacing: 0;
  width: 874px;
  height: 67px;
  font-family: Montserrat, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  position: absolute;
  top: 19px;
  left: 384px;
}

.component-variant .frame-11 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  display: inline-flex;
  position: absolute;
  top: 105px;
  left: 384px;
}

.component-variant .first-to-maintain {
  color: #000;
  letter-spacing: 0;
  width: 874px;
  height: 40px;
  margin-top: -1px;
  font-family: Montserrat, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.component-variant .span {
  font-weight: 700;
}

.component-variant .text-wrapper-22 {
  font-weight: 500;
}

.component-variant .second-to-build-and {
  color: #000;
  letter-spacing: 0;
  width: 874px;
  height: 53px;
  font-family: Montserrat, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.vision-screen {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.vision-screen .vision-2 {
  background-color: #fff;
  width: 1440px;
  height: 3739px;
  position: relative;
  overflow-x: hidden;
}

.vision-screen .overlap-30 {
  width: 1458px;
  height: 1987px;
  position: absolute;
  top: 1752px;
  left: -1px;
}

.vision-screen .group-71 {
  width: 1456px;
  height: 1784px;
  position: absolute;
  top: 61px;
  left: 2px;
}

.vision-screen .group-72 {
  background-image: url("background1-6-1.5525224f.png");
  background-size: 100% 100%;
  width: 1450px;
  height: 883px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision-screen .background-8 {
  width: 1450px;
  height: 883px;
  position: absolute;
  top: 901px;
  left: 6px;
}

.vision-screen .group-73 {
  width: 1442px;
  height: 714px;
  position: absolute;
  top: 1273px;
  left: 0;
}

.vision-screen .overlap-31 {
  height: 714px;
  position: relative;
}

.vision-screen .cambodianflag-6 {
  width: 1440px;
  height: 548px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.vision-screen .group-74 {
  width: 1440px;
  height: 399px;
  position: absolute;
  top: 311px;
  left: 1px;
}

.vision-screen .overlap-32 {
  height: 399px;
  position: relative;
}

.vision-screen .footer-6 {
  background-color: #0000;
  width: 1440px;
  height: 399px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision-screen .overlap-group-17 {
  width: 1251px;
  height: 68px;
  position: relative;
  top: 301px;
  left: 96px;
}

.vision-screen .line-16 {
  object-fit: cover;
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.vision-screen .div-39 {
  width: 1246px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision-screen .p-6 {
  border: 1px solid #fff;
  border-radius: 2px;
  align-items: flex-start;
  width: 372px;
  height: 34px;
  padding: 6px 11.14px 6px 11px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 4px;
}

.vision-screen .element-6 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision-screen .text-wrapper-123 {
  color: #fff;
  letter-spacing: .8px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.vision-screen .text-wrapper-124 {
  font-family: Montserrat, Helvetica;
}

.vision-screen .ul-6 {
  width: 366px;
  height: 16px;
  position: absolute;
  top: 32px;
  left: 935px;
}

.vision-screen .frame-85 {
  width: 395px;
  height: 16px;
  position: relative;
  left: -36px;
}

.vision-screen .frame-86 {
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
  position: relative;
  left: 23px;
}

.vision-screen .text-wrapper-125 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 57px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision-screen .text-wrapper-126 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 95px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision-screen .text-wrapper-127 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 80px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision-screen .text-wrapper-128 {
  color: #fff;
  letter-spacing: 0;
  width: 92px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision-screen .frame-87 {
  width: 1245px;
  height: 240px;
  position: absolute;
  top: 45px;
  left: 97px;
}

.vision-screen .frame-88 {
  align-items: flex-start;
  gap: 136px;
  height: 180px;
  display: inline-flex;
  position: relative;
  top: 36px;
}

.vision-screen .frame-89 {
  align-items: flex-start;
  gap: 10px;
  width: 423px;
  height: 203px;
  margin-bottom: -23px;
  display: flex;
  position: relative;
}

.vision-screen .frame-90 {
  width: 377px;
  height: 201px;
  position: relative;
  overflow: hidden;
}

.vision-screen .group-75 {
  width: 238px;
  height: 41px;
  position: absolute;
  top: 12px;
  left: 395px;
}

.vision-screen .div-40 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 130px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision-screen .text-wrapper-129 {
  font-weight: 700;
  line-height: 12px;
}

.vision-screen .frame-91 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.vision-screen .frame-93 {
  flex: none;
  align-items: flex-start;
  gap: 44px;
  display: inline-flex;
  position: relative;
}

.vision-screen .image-26 {
  width: 91px;
  height: 59px;
  position: relative;
}

.vision-screen .frame-94 {
  flex: none;
  align-items: flex-start;
  gap: 45px;
  width: 361px;
  display: flex;
  position: relative;
}

.vision-screen .image-27 {
  object-fit: cover;
  width: 87px;
  height: 57px;
  position: relative;
}

.vision-screen .image-28 {
  object-fit: cover;
  width: 91px;
  height: 59px;
  position: relative;
}

.vision-screen .group-76 {
  width: 319px;
  height: 166px;
  position: relative;
}

.vision-screen .group-77 {
  width: 318px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 1px;
}

.vision-screen .frame-95 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 318px;
  height: 112px;
  display: flex;
  position: relative;
}

.vision-screen .frame-96 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9px;
  margin-right: -30.37px;
  display: inline-flex;
  position: relative;
}

.vision-screen .text-wrapper-130 {
  color: #fff;
  letter-spacing: 0;
  width: 106px;
  height: 16px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.vision-screen .text-wrapper-131 {
  color: #fff;
  letter-spacing: 0;
  width: 348.37px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.vision-screen .frame-97 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.vision-screen .div-41 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 136px;
  height: 15px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.vision-screen .text-wrapper-132 {
  font-weight: 700;
  line-height: 11px;
}

.vision-screen .text-wrapper-133 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 267px;
  height: 11px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.vision-screen .div-footer-social-6 {
  width: 230px;
  height: 161px;
  position: relative;
}

.vision-screen .text-wrapper-134 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 144px;
  height: 17px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 7px;
  left: 48px;
}

.vision-screen .frame-98 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px 15px;
  width: 184px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 48px;
}

.vision-screen .group-78 {
  width: 50px;
  height: 50px;
  position: relative;
}

.vision-screen .list-item-link-SVG-6 {
  background-image: url("group-36.0e3a98ad.png");
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.vision-screen .symbol-31 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.vision-screen .telegram-app-7 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.vision-screen .symbol-32 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 10px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 19px;
  left: 12px;
}

.vision-screen .symbol-33 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 24px;
  height: 6px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 86px;
  left: 13px;
}

.vision-screen .symbol-34 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 81px;
}

.vision-screen .symbol-35 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 145px;
}

.vision-screen .subtract-6 {
  width: 1442px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision-screen .component-1-variant2 {
  width: 1277px !important;
  height: 1009px !important;
  position: absolute !important;
  top: 184px !important;
  left: 91px !important;
}

.vision-screen .component-variant-instance {
  align-items: center !important;
  gap: unset !important;
  justify-content: space-between !important;
  height: 979px !important;
}

.vision-screen .div-42 {
  width: 1180px;
  height: 122px;
  position: absolute;
  top: 0;
  left: 140px;
}

.vision-screen .pentagon-strategy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000040;
  white-space: nowrap;
  width: 991px;
  height: 30px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  position: absolute;
  top: 45px;
  left: 234px;
}

.vision-screen .main-section-2 {
  background-position: 50%;
  background-size: cover;
  width: 1440px;
  height: 560px;
  position: absolute;
  top: 195px;
  left: 0;
}

.vision-screen .overlap-group-18 {
  height: 560px;
  position: relative;
}

.vision-screen .div-absolute-5 {
  background: linear-gradient(#000000c7 10.42%, #0000 100%);
  width: 1440px;
  height: 560px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.vision-screen .heading-president-5 {
  -webkit-text-stroke: 1px #fff;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 667px;
  height: 86px;
  font-family: Metal, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 422px;
  left: 386px;
}

.vision-screen .screenshot-11 {
  object-fit: cover;
  width: 595px;
  height: 334px;
  position: absolute;
  top: 49px;
  left: 423px;
}

.vision-screen .group-79 {
  width: 942px;
  height: 56px;
  position: absolute;
  top: 809px;
  left: 251px;
}

.vision-screen .button-agenda {
  all: unset;
  box-sizing: border-box;
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 402px;
  height: 56px;
  font-family: Montserrat, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 59px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision-screen .button-statements-2 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 340px;
  height: 44px;
  font-family: Montserrat, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 598px;
}

.vision-screen .group-80 {
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 904px;
  left: 97px;
}

.vision-screen .overlap-33 {
  height: 5px;
  position: relative;
  top: -4px;
}

.vision-screen .line-17 {
  width: 1246px;
  height: 2px;
  position: absolute;
  top: 3px;
  left: 0;
}

.vision-screen .line-18 {
  width: 619px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.vision-screen .group-81 {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.vision-screen .pantagon {
  width: 945px;
  height: 699px;
  position: absolute;
  top: 959px;
  left: 244px;
}

.vision-screen .button-SVG-2 {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 789px;
  left: 1323px;
}

.msft-content-card {
  background-color: #fff;
  border-radius: 9px;
  width: 323px;
  height: 100px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.6px 3.6px #00000040, 0 0 2.9px #00000040;
}

.msft-content-card .text-wrapper-23 {
  color: #2b2b2b;
  letter-spacing: 0;
  width: 166px;
  height: 40px;
  font-family: Montserrat, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 7px;
  left: 120px;
}

.msft-content-card .text-wrapper-24 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  height: 10px;
  font-family: Montserrat, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  position: absolute;
  top: 74px;
  left: 120px;
}

.msft-content-card .image-6 {
  width: 104px;
  height: 84px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.msft-content-card .vector-3 {
  width: 8px;
  height: 15px;
  position: absolute;
  top: 77px;
  left: 249px;
}

.msft-content-card .vector-4 {
  width: 17px;
  height: 14px;
  position: absolute;
  top: 78px;
  left: 274px;
}

.detail-page {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.detail-page .div-43 {
  background-color: #fff;
  width: 1440px;
  height: 2788px;
  position: relative;
  overflow-x: hidden;
}

.detail-page .overlap-34 {
  width: 1478px;
  height: 2593px;
  position: absolute;
  top: 195px;
  left: -15px;
}

.detail-page .div-44 {
  width: 1180px;
  height: 764px;
  position: absolute;
  top: 1558px;
  left: 146px;
}

.detail-page .group-82 {
  width: 1257px;
  height: 1757px;
  position: absolute;
  top: 0;
  left: 106px;
}

.detail-page .overlap-35 {
  width: 1273px;
  height: 1736px;
  position: relative;
  top: 21px;
}

.detail-page .rectangle-8 {
  background-color: #6f6f6f;
  width: 2px;
  height: 20px;
  position: absolute;
  top: 931px;
  left: 76px;
}

.detail-page .group-83 {
  width: 1273px;
  height: 1736px;
  position: absolute;
  top: 0;
  left: 0;
}

.detail-page .text-wrapper-135 {
  color: #171717;
  letter-spacing: 0;
  white-space: nowrap;
  width: 845px;
  height: 13px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 56px;
  position: absolute;
  top: 3px;
  left: 76px;
}

.detail-page .vector-13 {
  width: 6px;
  height: 11px;
  position: absolute;
  top: 4px;
  left: 38px;
}

.detail-page .vector-14 {
  width: 19px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.detail-page .cambodia-prime-2 {
  color: #171717;
  letter-spacing: 0;
  width: 1248px;
  height: 148px;
  font-family: Montserrat, Helvetica;
  font-size: 36px;
  font-weight: 700;
  line-height: 56px;
  position: absolute;
  top: 73px;
  left: 8px;
}

.detail-page .text-wrapper-136 {
  color: #171717;
  letter-spacing: 0;
  text-align: right;
  width: 167px;
  height: 20px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 32px;
  left: 1089px;
}

.detail-page .group-84 {
  width: 1246px;
  height: 27px;
  position: absolute;
  top: 250px;
  left: 10px;
}

.detail-page .div-45 {
  width: 89px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 0;
}

.detail-page .span-3 {
  opacity: .7;
  width: 84px;
  height: 18px;
  position: relative;
  top: 4px;
}

.detail-page .text-wrapper-137 {
  color: #171717;
  letter-spacing: 0;
  white-space: nowrap;
  height: 18px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  top: -1px;
  left: 0;
}

.detail-page .svg-3 {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 1px;
  left: 91px;
}

.detail-page .group-85 {
  width: 76px;
  height: 21px;
  position: absolute;
  top: 4px;
  left: 1171px;
}

.detail-page .overlap-36 {
  width: 872px;
  height: 708px;
  position: absolute;
  top: 1028px;
  left: 8px;
}

.detail-page .group-86 {
  width: 872px;
  height: 474px;
  position: absolute;
  top: 0;
  left: 0;
}

.detail-page .having-risen-through {
  color: #333;
  letter-spacing: 0;
  width: 861px;
  height: 91px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 0;
  left: 1px;
}

.detail-page .text-wrapper-138 {
  color: #333;
  letter-spacing: 0;
  width: 861px;
  height: 94px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 100px;
  left: 1px;
}

.detail-page .text-wrapper-139 {
  color: #333;
  letter-spacing: 0;
  width: 861px;
  height: 43px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 205px;
  left: 1px;
}

.detail-page .overlap-group-19 {
  width: 862px;
  height: 216px;
  position: absolute;
  top: 258px;
  left: 0;
}

.detail-page .text-wrapper-140 {
  color: #333;
  letter-spacing: 0;
  width: 861px;
  height: 89px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 0;
  left: 1px;
}

.detail-page .text-wrapper-141 {
  color: #333;
  letter-spacing: 0;
  width: 861px;
  height: 132px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 84px;
  left: 0;
}

.detail-page .text-wrapper-142 {
  color: #333;
  letter-spacing: 0;
  width: 861px;
  height: 132px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 459px;
  left: 0;
}

.detail-page .text-wrapper-143 {
  color: #333;
  letter-spacing: 0;
  width: 861px;
  height: 132px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 576px;
  left: 0;
}

.detail-page .text-wrapper-144 {
  color: #6e6e6e;
  letter-spacing: 0;
  width: 682px;
  height: 24px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 929px;
  left: 16px;
}

.detail-page .text-wrapper-145 {
  color: #000;
  letter-spacing: 0;
  width: 495px;
  height: 24px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  position: absolute;
  top: 980px;
  left: 9px;
}

.detail-page .text-wrapper-146 {
  color: #000;
  letter-spacing: 0;
  width: 148px;
  height: 24px;
  font-family: Montserrat, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  position: absolute;
  top: 974px;
  left: 933px;
}

.detail-page .un-2 {
  object-fit: cover;
  width: 1248px;
  height: 593px;
  position: absolute;
  top: 323px;
  left: 8px;
}

.detail-page .group-87 {
  width: 1478px;
  height: 714px;
  position: absolute;
  top: 1879px;
  left: 0;
}

.detail-page .overlap-37 {
  height: 714px;
  position: relative;
}

.detail-page .cambodianflag-7 {
  width: 1476px;
  height: 548px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.detail-page .group-88 {
  width: 1476px;
  height: 399px;
  position: absolute;
  top: 311px;
  left: 1px;
}

.detail-page .overlap-38 {
  height: 399px;
  position: relative;
}

.detail-page .footer-7 {
  background-color: #0000;
  width: 1476px;
  height: 399px;
  position: absolute;
  top: 0;
  left: 0;
}

.detail-page .overlap-group-20 {
  width: 1269px;
  height: 68px;
  position: relative;
  top: 301px;
  left: 96px;
}

.detail-page .line-19 {
  object-fit: cover;
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 23px;
}

.detail-page .div-46 {
  width: 1246px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.detail-page .p-7 {
  border: 1px solid #fff;
  border-radius: 2px;
  align-items: flex-start;
  width: 372px;
  height: 34px;
  padding: 6px 11.14px 6px 11px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 4px;
}

.detail-page .element-7 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.detail-page .text-wrapper-147 {
  color: #fff;
  letter-spacing: .8px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.detail-page .text-wrapper-148 {
  font-family: Montserrat, Helvetica;
}

.detail-page .ul-7 {
  width: 366px;
  height: 16px;
  position: absolute;
  top: 32px;
  left: 935px;
}

.detail-page .frame-99 {
  width: 395px;
  height: 16px;
  position: relative;
  left: -36px;
}

.detail-page .frame-100 {
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
  position: relative;
  left: 23px;
}

.detail-page .text-wrapper-149 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 57px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.detail-page .text-wrapper-150 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 95px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.detail-page .text-wrapper-151 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 80px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.detail-page .text-wrapper-152 {
  color: #fff;
  letter-spacing: 0;
  width: 92px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.detail-page .frame-101 {
  width: 1276px;
  height: 240px;
  position: absolute;
  top: 45px;
  left: 99px;
}

.detail-page .frame-102 {
  align-items: flex-start;
  gap: 136px;
  height: 180px;
  display: inline-flex;
  position: relative;
  top: 36px;
}

.detail-page .frame-103 {
  align-items: flex-start;
  gap: 10px;
  width: 423px;
  height: 203px;
  margin-bottom: -23px;
  display: flex;
  position: relative;
}

.detail-page .frame-104 {
  width: 377px;
  height: 201px;
  position: relative;
  overflow: hidden;
}

.detail-page .group-89 {
  width: 238px;
  height: 41px;
  position: absolute;
  top: 12px;
  left: 395px;
}

.detail-page .div-47 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 130px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.detail-page .text-wrapper-153 {
  font-weight: 700;
  line-height: 12px;
}

.detail-page .frame-105 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.detail-page .frame-106 {
  flex: none;
  align-items: flex-start;
  gap: 44px;
  display: inline-flex;
  position: relative;
}

.detail-page .image-29 {
  width: 91px;
  height: 59px;
  position: relative;
}

.detail-page .frame-107 {
  flex: none;
  align-items: flex-start;
  gap: 45px;
  width: 361px;
  display: flex;
  position: relative;
}

.detail-page .image-30 {
  object-fit: cover;
  width: 87px;
  height: 57px;
  position: relative;
}

.detail-page .image-31 {
  object-fit: cover;
  width: 91px;
  height: 59px;
  position: relative;
}

.detail-page .group-90 {
  width: 319px;
  height: 166px;
  position: relative;
}

.detail-page .group-91 {
  width: 318px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 1px;
}

.detail-page .frame-108 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 318px;
  height: 112px;
  display: flex;
  position: relative;
}

.detail-page .frame-109 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9px;
  margin-right: -30.37px;
  display: inline-flex;
  position: relative;
}

.detail-page .text-wrapper-154 {
  color: #fff;
  letter-spacing: 0;
  width: 106px;
  height: 16px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.detail-page .text-wrapper-155 {
  color: #fff;
  letter-spacing: 0;
  width: 348.37px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.detail-page .frame-110 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.detail-page .div-48 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 136px;
  height: 15px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.detail-page .text-wrapper-156 {
  font-weight: 700;
  line-height: 11px;
}

.detail-page .text-wrapper-157 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 267px;
  height: 11px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.detail-page .div-footer-social-7 {
  width: 230px;
  height: 161px;
  position: relative;
}

.detail-page .text-wrapper-158 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 144px;
  height: 17px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 7px;
  left: 48px;
}

.detail-page .frame-111 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px 15px;
  width: 184px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 48px;
}

.detail-page .group-92 {
  width: 50px;
  height: 50px;
  position: relative;
}

.detail-page .list-item-link-SVG-7 {
  background-image: url("group-42-2x.7586e08d.png");
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.detail-page .symbol-36 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.detail-page .telegram-app-8 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.detail-page .symbol-37 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 10px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 19px;
  left: 12px;
}

.detail-page .symbol-38 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 24px;
  height: 6px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 86px;
  left: 13px;
}

.detail-page .symbol-39 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 81px;
}

.detail-page .symbol-40 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 145px;
}

.detail-page .subtract-7 {
  width: 1478px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.detail-page .msft-content-card-instance {
  position: absolute !important;
  top: 1049px !important;
  left: 1001px !important;
}

.detail-page .msft-content-card-2 {
  position: absolute !important;
  top: 1168px !important;
  left: 1001px !important;
}

.detail-page .msft-content-card-3 {
  position: absolute !important;
  top: 1288px !important;
  left: 1001px !important;
}

.detail-page .msft-content-card-4 {
  position: absolute !important;
  top: 1407px !important;
  left: 1001px !important;
}

.detail-page .msft-content-card-5 {
  position: absolute !important;
  top: 1526px !important;
  left: 1001px !important;
}

.detail-page .group-93 {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.div-wrapper {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.div-wrapper .vision-3 {
  background-color: #fff;
  width: 1440px;
  height: 2779px;
  position: relative;
  overflow-x: hidden;
}

.div-wrapper .group-94 {
  width: 942px;
  height: 56px;
  position: absolute;
  top: 809px;
  left: 251px;
}

.div-wrapper .button-2 {
  all: unset;
  box-sizing: border-box;
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 402px;
  height: 56px;
  font-family: Montserrat, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 59px;
  position: absolute;
  top: 0;
  left: 0;
}

.div-wrapper .button-statements-3 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 340px;
  height: 44px;
  font-family: Montserrat, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 598px;
}

.div-wrapper .group-95 {
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 904px;
  left: 97px;
}

.div-wrapper .overlap-group-21 {
  height: 5px;
  position: relative;
  top: -4px;
}

.div-wrapper .line-20 {
  width: 1246px;
  height: 2px;
  position: absolute;
  top: 3px;
  left: 0;
}

.div-wrapper .line-21 {
  width: 619px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 627px;
}

.div-wrapper .group-96 {
  background-image: url("image.73133226.png") !important;
}

.div-wrapper .group-97 {
  background-image: url("link-image-2x.1221df16.png") !important;
}

.div-wrapper .group-98 {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.div-wrapper .group-99 {
  width: 1442px;
  height: 714px;
  position: absolute;
  top: 2069px;
  left: -1px;
}

.div-wrapper .overlap-39 {
  height: 714px;
  position: relative;
}

.div-wrapper .cambodianflag-8 {
  width: 1440px;
  height: 548px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.div-wrapper .group-100 {
  width: 1440px;
  height: 399px;
  position: absolute;
  top: 311px;
  left: 1px;
}

.div-wrapper .overlap-40 {
  height: 399px;
  position: relative;
}

.div-wrapper .footer-8 {
  background-color: #0000;
  width: 1440px;
  height: 399px;
  position: absolute;
  top: 0;
  left: 0;
}

.div-wrapper .overlap-group-22 {
  width: 1251px;
  height: 68px;
  position: relative;
  top: 301px;
  left: 96px;
}

.div-wrapper .line-22 {
  object-fit: cover;
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.div-wrapper .div-49 {
  width: 1246px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.div-wrapper .p-8 {
  border: 1px solid #fff;
  border-radius: 2px;
  align-items: flex-start;
  width: 372px;
  height: 34px;
  padding: 6px 11.14px 6px 11px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 4px;
}

.div-wrapper .element-8 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.div-wrapper .text-wrapper-159 {
  color: #fff;
  letter-spacing: .8px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.div-wrapper .text-wrapper-160 {
  font-family: Montserrat, Helvetica;
}

.div-wrapper .ul-8 {
  width: 366px;
  height: 16px;
  position: absolute;
  top: 32px;
  left: 935px;
}

.div-wrapper .frame-112 {
  width: 395px;
  height: 16px;
  position: relative;
  left: -36px;
}

.div-wrapper .frame-113 {
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
  position: relative;
  left: 23px;
}

.div-wrapper .text-wrapper-161 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 57px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.div-wrapper .text-wrapper-162 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 95px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.div-wrapper .text-wrapper-163 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 80px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.div-wrapper .text-wrapper-164 {
  color: #fff;
  letter-spacing: 0;
  width: 92px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.div-wrapper .frame-114 {
  width: 1245px;
  height: 240px;
  position: absolute;
  top: 45px;
  left: 97px;
}

.div-wrapper .frame-115 {
  align-items: flex-start;
  gap: 136px;
  height: 180px;
  display: inline-flex;
  position: relative;
  top: 36px;
}

.div-wrapper .frame-116 {
  align-items: flex-start;
  gap: 10px;
  width: 423px;
  height: 203px;
  margin-bottom: -23px;
  display: flex;
  position: relative;
}

.div-wrapper .frame-117 {
  width: 377px;
  height: 201px;
  position: relative;
  overflow: hidden;
}

.div-wrapper .group-101 {
  width: 238px;
  height: 41px;
  position: absolute;
  top: 12px;
  left: 395px;
}

.div-wrapper .div-50 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 130px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.div-wrapper .text-wrapper-165 {
  font-weight: 700;
  line-height: 12px;
}

.div-wrapper .frame-118 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.div-wrapper .frame-119 {
  flex: none;
  align-items: flex-start;
  gap: 44px;
  display: inline-flex;
  position: relative;
}

.div-wrapper .image-32 {
  width: 91px;
  height: 59px;
  position: relative;
}

.div-wrapper .frame-120 {
  flex: none;
  align-items: flex-start;
  gap: 45px;
  width: 361px;
  display: flex;
  position: relative;
}

.div-wrapper .image-33 {
  object-fit: cover;
  width: 87px;
  height: 57px;
  position: relative;
}

.div-wrapper .image-34 {
  object-fit: cover;
  width: 91px;
  height: 59px;
  position: relative;
}

.div-wrapper .group-102 {
  width: 319px;
  height: 166px;
  position: relative;
}

.div-wrapper .group-103 {
  width: 318px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 1px;
}

.div-wrapper .frame-121 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 318px;
  height: 112px;
  display: flex;
  position: relative;
}

.div-wrapper .frame-122 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9px;
  margin-right: -30.37px;
  display: inline-flex;
  position: relative;
}

.div-wrapper .text-wrapper-166 {
  color: #fff;
  letter-spacing: 0;
  width: 106px;
  height: 16px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.div-wrapper .text-wrapper-167 {
  color: #fff;
  letter-spacing: 0;
  width: 348.37px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.div-wrapper .frame-123 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.div-wrapper .div-51 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 136px;
  height: 15px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.div-wrapper .text-wrapper-168 {
  font-weight: 700;
  line-height: 11px;
}

.div-wrapper .text-wrapper-169 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 267px;
  height: 11px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.div-wrapper .div-footer-social-8 {
  width: 230px;
  height: 161px;
  position: relative;
}

.div-wrapper .text-wrapper-170 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 144px;
  height: 17px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 7px;
  left: 48px;
}

.div-wrapper .frame-124 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px 15px;
  width: 184px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 48px;
}

.div-wrapper .group-104 {
  width: 50px;
  height: 50px;
  position: relative;
}

.div-wrapper .list-item-link-SVG-8 {
  background-image: url("group-6.7887a5db.png");
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.div-wrapper .symbol-41 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.div-wrapper .telegram-app-9 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.div-wrapper .symbol-42 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 10px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 19px;
  left: 12px;
}

.div-wrapper .symbol-43 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 24px;
  height: 6px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 86px;
  left: 13px;
}

.div-wrapper .symbol-44 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 81px;
}

.div-wrapper .symbol-45 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 145px;
}

.div-wrapper .subtract-8 {
  width: 1442px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.div-wrapper .button-SVG-3 {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 781px;
  left: 1323px;
}

.div-wrapper .frame-125 {
  flex-direction: column;
  align-items: center;
  height: 1060px;
  display: inline-flex;
  position: absolute;
  top: 905px;
  left: 97px;
}

.div-wrapper .frame-126 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.div-wrapper .link-3 {
  border-color: #e6e7e8;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 1241px;
  height: 259px;
  position: relative;
}

.div-wrapper .heading-president-6 {
  color: #253b95;
  letter-spacing: 0;
  white-space: nowrap;
  width: 869px;
  height: 40px;
  font-family: Kantumruy, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  position: absolute;
  top: 108px;
  left: 372px;
}

.div-wrapper .text-wrapper-171 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 104px;
  height: 20px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 192px;
  left: 1137px;
}

.div-wrapper .text-wrapper-172 {
  color: #253b95;
  letter-spacing: 0;
  white-space: nowrap;
  width: 263px;
  height: 37px;
  font-family: Kantumruy, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 47px;
  position: absolute;
  top: 212px;
  left: 685px;
}

.div-wrapper .line-23 {
  width: 280px;
  height: 1px;
  position: absolute;
  top: 230px;
  left: 392px;
}

.div-wrapper .div-image-6 {
  background-image: url("div-image-1-2x.167887a5.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 9px;
  width: 323px;
  height: 204px;
  position: absolute;
  top: 27px;
  left: 0;
  transform: rotate(180deg);
}

.div-wrapper .heading-president-7 {
  color: #253b95;
  letter-spacing: 0;
  white-space: nowrap;
  width: 852px;
  height: 40px;
  font-family: Kantumruy, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  position: absolute;
  top: 108px;
  left: 389px;
}

.div-wrapper .div-image-7 {
  background-image: url("div-image.259a8421.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 9px;
  width: 323px;
  height: 204px;
  position: absolute;
  top: 27px;
  left: 0;
}

.div-wrapper .div-image-8 {
  background-image: url("div-image-2-2x.3182c284.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 9px;
  width: 323px;
  height: 204px;
  position: absolute;
  top: 27px;
  left: 0;
  transform: rotate(180deg);
}

.div-wrapper .div-image-9 {
  background-image: url("div-image-3-2x.223bf7f6.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 9px;
  width: 323px;
  height: 201px;
  position: absolute;
  top: 30px;
  left: 0;
}

.div-wrapper .main-section-3 {
  background-image: url("rectangle-29.f6b8640d.png");
  background-position: 50%;
  background-size: cover;
  width: 1440px;
  height: 560px;
  position: absolute;
  top: 195px;
  left: 0;
}

.div-wrapper .overlap-41 {
  height: 560px;
  position: relative;
}

.div-wrapper .div-absolute-6 {
  background: linear-gradient(#000000c7 10.42%, #0000 100%);
  width: 1440px;
  height: 560px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.div-wrapper .heading-president-8 {
  -webkit-text-stroke: 1px #fff;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 667px;
  height: 86px;
  font-family: Metal, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 424px;
  left: 386px;
}

.div-wrapper .screenshot-12 {
  object-fit: cover;
  width: 595px;
  height: 334px;
  position: absolute;
  top: 51px;
  left: 423px;
}

.screen-8 {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.screen-8 .vision-4 {
  background-color: #fff;
  width: 1440px;
  height: 3739px;
  position: relative;
  overflow-x: hidden;
}

.screen-8 .overlap-42 {
  width: 1458px;
  height: 1987px;
  position: absolute;
  top: 1752px;
  left: -1px;
}

.screen-8 .group-105 {
  width: 1456px;
  height: 1784px;
  position: absolute;
  top: 61px;
  left: 2px;
}

.screen-8 .group-106 {
  background-image: url("background1-6-2-2.ceda5b83.png");
  background-size: 100% 100%;
  width: 1450px;
  height: 883px;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-8 .background-9 {
  width: 1450px;
  height: 883px;
  position: absolute;
  top: 901px;
  left: 6px;
}

.screen-8 .group-107 {
  width: 1442px;
  height: 714px;
  position: absolute;
  top: 1273px;
  left: 0;
}

.screen-8 .overlap-43 {
  height: 714px;
  position: relative;
}

.screen-8 .cambodianflag-9 {
  width: 1440px;
  height: 548px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.screen-8 .group-108 {
  width: 1440px;
  height: 399px;
  position: absolute;
  top: 311px;
  left: 1px;
}

.screen-8 .overlap-44 {
  height: 399px;
  position: relative;
}

.screen-8 .footer-9 {
  background-color: #0000;
  width: 1440px;
  height: 399px;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-8 .overlap-group-23 {
  width: 1251px;
  height: 68px;
  position: relative;
  top: 301px;
  left: 96px;
}

.screen-8 .line-24 {
  object-fit: cover;
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.screen-8 .div-52 {
  width: 1246px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-8 .p-9 {
  border: 1px solid #fff;
  border-radius: 2px;
  align-items: flex-start;
  width: 372px;
  height: 34px;
  padding: 6px 11.14px 6px 11px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 4px;
}

.screen-8 .element-9 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.screen-8 .text-wrapper-173 {
  color: #fff;
  letter-spacing: .8px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.screen-8 .text-wrapper-174 {
  font-family: Montserrat, Helvetica;
}

.screen-8 .ul-9 {
  width: 366px;
  height: 16px;
  position: absolute;
  top: 32px;
  left: 935px;
}

.screen-8 .frame-127 {
  width: 395px;
  height: 16px;
  position: relative;
  left: -36px;
}

.screen-8 .frame-128 {
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
  position: relative;
  left: 23px;
}

.screen-8 .text-wrapper-175 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 57px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.screen-8 .text-wrapper-176 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 95px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.screen-8 .text-wrapper-177 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 80px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.screen-8 .text-wrapper-178 {
  color: #fff;
  letter-spacing: 0;
  width: 92px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.screen-8 .frame-129 {
  width: 1245px;
  height: 240px;
  position: absolute;
  top: 45px;
  left: 97px;
}

.screen-8 .frame-130 {
  align-items: flex-start;
  gap: 136px;
  height: 180px;
  display: inline-flex;
  position: relative;
  top: 36px;
}

.screen-8 .frame-131 {
  align-items: flex-start;
  gap: 10px;
  width: 423px;
  height: 203px;
  margin-bottom: -23px;
  display: flex;
  position: relative;
}

.screen-8 .frame-132 {
  width: 377px;
  height: 201px;
  position: relative;
  overflow: hidden;
}

.screen-8 .group-109 {
  width: 238px;
  height: 41px;
  position: absolute;
  top: 12px;
  left: 395px;
}

.screen-8 .div-53 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 130px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-8 .text-wrapper-179 {
  font-weight: 700;
  line-height: 12px;
}

.screen-8 .frame-133 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.screen-8 .frame-134 {
  flex: none;
  align-items: flex-start;
  gap: 44px;
  display: inline-flex;
  position: relative;
}

.screen-8 .image-35 {
  width: 91px;
  height: 59px;
  position: relative;
}

.screen-8 .frame-135 {
  flex: none;
  align-items: flex-start;
  gap: 45px;
  width: 361px;
  display: flex;
  position: relative;
}

.screen-8 .image-36 {
  object-fit: cover;
  width: 87px;
  height: 57px;
  position: relative;
}

.screen-8 .image-37 {
  object-fit: cover;
  width: 91px;
  height: 59px;
  position: relative;
}

.screen-8 .group-110 {
  width: 319px;
  height: 166px;
  position: relative;
}

.screen-8 .group-111 {
  width: 318px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 1px;
}

.screen-8 .frame-136 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 318px;
  height: 112px;
  display: flex;
  position: relative;
}

.screen-8 .frame-137 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9px;
  margin-right: -30.37px;
  display: inline-flex;
  position: relative;
}

.screen-8 .text-wrapper-180 {
  color: #fff;
  letter-spacing: 0;
  width: 106px;
  height: 16px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.screen-8 .text-wrapper-181 {
  color: #fff;
  letter-spacing: 0;
  width: 348.37px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.screen-8 .frame-138 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.screen-8 .div-54 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 136px;
  height: 15px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.screen-8 .text-wrapper-182 {
  font-weight: 700;
  line-height: 11px;
}

.screen-8 .text-wrapper-183 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 267px;
  height: 11px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.screen-8 .div-footer-social-9 {
  width: 230px;
  height: 161px;
  position: relative;
}

.screen-8 .text-wrapper-184 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 144px;
  height: 17px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 7px;
  left: 48px;
}

.screen-8 .frame-139 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px 15px;
  width: 184px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 48px;
}

.screen-8 .group-112 {
  width: 50px;
  height: 50px;
  position: relative;
}

.screen-8 .list-item-link-SVG-9 {
  background-image: url("group-42.a84dad7d.png");
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.screen-8 .symbol-46 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.screen-8 .telegram-app-10 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.screen-8 .symbol-47 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 10px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 19px;
  left: 12px;
}

.screen-8 .symbol-48 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 24px;
  height: 6px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 86px;
  left: 13px;
}

.screen-8 .symbol-49 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 81px;
}

.screen-8 .symbol-50 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 145px;
}

.screen-8 .subtract-9 {
  width: 1442px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-8 .component-1-variant2-instance {
  width: 1277px !important;
  height: 1009px !important;
  position: absolute !important;
  top: 184px !important;
  left: 91px !important;
}

.screen-8 .component-3 {
  align-items: center !important;
  gap: unset !important;
  justify-content: space-between !important;
  height: 979px !important;
}

.screen-8 .div-55 {
  width: 1180px;
  height: 122px;
  position: absolute;
  top: 0;
  left: 140px;
}

.screen-8 .pentagon-strategy-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 4px 4px #00000040;
  white-space: nowrap;
  width: 991px;
  height: 30px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  position: absolute;
  top: 45px;
  left: 234px;
}

.screen-8 .main-section-4 {
  background-image: url("rectangle-29.f6b8640d.png");
  background-position: 50%;
  background-size: cover;
  width: 1440px;
  height: 560px;
  position: absolute;
  top: 195px;
  left: 0;
}

.screen-8 .overlap-group-24 {
  height: 560px;
  position: relative;
}

.screen-8 .div-absolute-7 {
  background: linear-gradient(#000000c7 10.42%, #0000 100%);
  width: 1440px;
  height: 560px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.screen-8 .heading-president-9 {
  -webkit-text-stroke: 1px #fff;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 667px;
  height: 86px;
  font-family: Metal, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 422px;
  left: 386px;
}

.screen-8 .screenshot-13 {
  object-fit: cover;
  width: 595px;
  height: 334px;
  position: absolute;
  top: 49px;
  left: 423px;
}

.screen-8 .group-113 {
  width: 942px;
  height: 56px;
  position: absolute;
  top: 809px;
  left: 251px;
}

.screen-8 .button-3 {
  all: unset;
  box-sizing: border-box;
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 402px;
  height: 56px;
  font-family: Montserrat, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: 59px;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-8 .button-statements-4 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 340px;
  height: 44px;
  font-family: Montserrat, Helvetica;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 598px;
}

.screen-8 .group-114 {
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 904px;
  left: 97px;
}

.screen-8 .overlap-45 {
  height: 5px;
  position: relative;
  top: -4px;
}

.screen-8 .line-25 {
  width: 1246px;
  height: 2px;
  position: absolute;
  top: 3px;
  left: 0;
}

.screen-8 .line-26 {
  width: 619px;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-8 .group-115 {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.screen-8 .group-116 {
  background-image: url("image.73133226.png") !important;
}

.screen-8 .group-117 {
  background-image: url("link-image-2x.1221df16.png") !important;
}

.screen-8 .pantagon-2 {
  width: 945px;
  height: 699px;
  position: absolute;
  top: 959px;
  left: 244px;
}

.screen-8 .button-SVG-4 {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 789px;
  left: 1323px;
}

.biography-screen {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.biography-screen .biography-2 {
  background-color: #fff;
  width: 1440px;
  height: 4250px;
  position: relative;
  overflow-x: hidden;
}

.biography-screen .overlap-46 {
  width: 1251px;
  height: 1569px;
  position: absolute;
  top: 1879px;
  left: 96px;
}

.biography-screen .group-118 {
  background-color: #fff;
  border-radius: 4px;
  width: 1248px;
  height: 651px;
  position: absolute;
  top: 918px;
  left: 0;
  box-shadow: 0 1.6px 3.6px #00000021, 0 0 2.9px #0000001c;
}

.biography-screen .background-10 {
  width: 1251px;
  height: 651px;
  position: absolute;
  top: 918px;
  left: 0;
}

.biography-screen .group-119 {
  width: 1188px;
  height: 1525px;
  position: absolute;
  top: 0;
  left: 43px;
}

.biography-screen .group-120 {
  width: 1180px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 8px;
}

.biography-screen .award-2 {
  object-fit: cover;
  width: 543px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography-screen .group-121 {
  width: 547px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 637px;
}

.biography-screen .text-wrapper-185 {
  color: #000;
  letter-spacing: 0;
  width: 543px;
  height: 274px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  position: absolute;
  top: 64px;
  left: 0;
}

.biography-screen .text-wrapper-186 {
  -webkit-text-stroke: 1px #000;
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 377px;
  height: 31px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: underline;
  position: absolute;
  top: -1px;
  left: 78px;
}

.biography-screen .group-122 {
  width: 1180px;
  height: 338px;
  position: absolute;
  top: 463px;
  left: 8px;
}

.biography-screen .award-3 {
  object-fit: cover;
  width: 543px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 637px;
}

.biography-screen .group-123 {
  width: 547px;
  height: 338px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography-screen .text-wrapper-187 {
  -webkit-text-stroke: 1px #000;
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 377px;
  height: 31px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: underline;
  position: absolute;
  top: -1px;
  left: 79px;
}

.biography-screen .group-124 {
  width: 1167px;
  height: 563px;
  position: absolute;
  top: 962px;
  left: 0;
}

.biography-screen .div-56 {
  width: 547px;
  height: 76px;
  position: absolute;
  top: 874px;
  left: 350px;
}

.biography-screen .overlap-47 {
  width: 1442px;
  height: 560px;
  position: absolute;
  top: 195px;
  left: -1px;
}

.biography-screen .un-3 {
  width: 1441px;
  height: 499px;
  position: absolute;
  top: 0;
  left: 1px;
}

.biography-screen .pseudo-2 {
  width: 1442px;
  height: 499px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography-screen .div-57 {
  width: 1180px;
  height: 122px;
  position: absolute;
  top: 438px;
  left: 141px;
}

.biography-screen .group-125 {
  width: 1252px;
  height: 637px;
  position: absolute;
  top: 814px;
  left: 104px;
}

.biography-screen .frame-140 {
  width: 572px;
  height: 551px;
  position: absolute;
  top: 26px;
  left: 680px;
}

.biography-screen .overlap-group-25 {
  background-color: #fff;
  border-radius: 4px;
  width: 593px;
  height: 506px;
  position: relative;
  top: 76px;
  left: -22px;
  box-shadow: 0 1.6px 3.6px #00000021, 0 0 2.9px #0000001c;
}

.biography-screen .frame-141 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 551px;
  height: 449px;
  display: flex;
  position: relative;
  top: 28px;
  left: 21px;
}

.biography-screen .text-wrapper-188 {
  color: #333;
  letter-spacing: 0;
  width: 495.41px;
  height: 95px;
  margin-top: -1px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.biography-screen .text-wrapper-189 {
  color: #333;
  letter-spacing: 0;
  width: 552px;
  height: 161px;
  margin-right: -1px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.biography-screen .text-wrapper-190 {
  color: #333;
  letter-spacing: 0;
  width: 551px;
  height: 161px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.biography-screen .group-126 {
  width: 589px;
  height: 637px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography-screen .text-wrapper-191 {
  color: #333;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 490px;
  height: 53px;
  font-family: Montserrat, Helvetica;
  font-size: 37px;
  font-weight: 700;
  line-height: 48px;
  position: absolute;
  top: 0;
  left: 48px;
}

.biography-screen .text-wrapper-192 {
  color: #333;
  letter-spacing: 0;
  text-align: center;
  width: 442px;
  height: 123px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  position: absolute;
  top: 514px;
  left: 72px;
}

.biography-screen .hrfihu-cambodia-pm {
  width: 585px;
  height: 369px;
  position: absolute;
  top: 97px;
  left: 0;
}

.biography-screen .group-127 {
  width: 1180px;
  height: 247px;
  position: absolute;
  top: 1535px;
  left: 130px;
}

.biography-screen .div-58 {
  width: 1180px;
  height: 122px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography-screen .medal-2 {
  object-fit: cover;
  width: 168px;
  height: 125px;
  position: absolute;
  top: 122px;
  left: 506px;
}

.biography-screen .group-128 {
  width: 1463px;
  height: 714px;
  position: absolute;
  top: 3537px;
  left: 0;
}

.biography-screen .overlap-48 {
  height: 714px;
  position: relative;
}

.biography-screen .cambodianflag-10 {
  width: 1461px;
  height: 548px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.biography-screen .group-129 {
  width: 1461px;
  height: 399px;
  position: absolute;
  top: 311px;
  left: 1px;
}

.biography-screen .overlap-49 {
  height: 399px;
  position: relative;
}

.biography-screen .footer-10 {
  background-color: #0000;
  width: 1461px;
  height: 399px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography-screen .overlap-group-26 {
  width: 1261px;
  height: 68px;
  position: relative;
  top: 301px;
  left: 96px;
}

.biography-screen .line-27 {
  object-fit: cover;
  width: 1246px;
  height: 1px;
  position: absolute;
  top: 4px;
  left: 15px;
}

.biography-screen .div-59 {
  width: 1246px;
  height: 68px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography-screen .p-10 {
  border: 1px solid #fff;
  border-radius: 2px;
  align-items: flex-start;
  width: 372px;
  height: 34px;
  padding: 6px 11.14px 6px 11px;
  display: flex;
  position: absolute;
  top: 34px;
  left: 4px;
}

.biography-screen .element-10 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  margin-right: -.14px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography-screen .text-wrapper-193 {
  color: #fff;
  letter-spacing: .8px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.biography-screen .text-wrapper-194 {
  font-family: Montserrat, Helvetica;
}

.biography-screen .ul-10 {
  width: 366px;
  height: 16px;
  position: absolute;
  top: 32px;
  left: 935px;
}

.biography-screen .frame-142 {
  width: 395px;
  height: 16px;
  position: relative;
  left: -36px;
}

.biography-screen .frame-143 {
  align-items: flex-start;
  gap: 11px;
  display: inline-flex;
  position: relative;
  left: 23px;
}

.biography-screen .text-wrapper-195 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 57px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography-screen .text-wrapper-196 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 95px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography-screen .text-wrapper-197 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 80px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography-screen .text-wrapper-198 {
  color: #fff;
  letter-spacing: 0;
  width: 92px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography-screen .frame-144 {
  width: 1263px;
  height: 240px;
  position: absolute;
  top: 45px;
  left: 98px;
}

.biography-screen .frame-145 {
  align-items: flex-start;
  gap: 136px;
  height: 180px;
  display: inline-flex;
  position: relative;
  top: 36px;
}

.biography-screen .frame-146 {
  align-items: flex-start;
  gap: 10px;
  width: 423px;
  height: 203px;
  margin-bottom: -23px;
  display: flex;
  position: relative;
}

.biography-screen .frame-147 {
  width: 377px;
  height: 201px;
  position: relative;
  overflow: hidden;
}

.biography-screen .group-131 {
  width: 238px;
  height: 41px;
  position: absolute;
  top: 12px;
  left: 395px;
}

.biography-screen .div-60 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 130px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography-screen .text-wrapper-199 {
  font-weight: 700;
  line-height: 12px;
}

.biography-screen .frame-148 {
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  display: inline-flex;
  position: absolute;
  top: 37px;
  left: 0;
}

.biography-screen .frame-149 {
  flex: none;
  align-items: flex-start;
  gap: 44px;
  display: inline-flex;
  position: relative;
}

.biography-screen .image-38 {
  width: 91px;
  height: 59px;
  position: relative;
}

.biography-screen .frame-150 {
  flex: none;
  align-items: flex-start;
  gap: 45px;
  width: 361px;
  display: flex;
  position: relative;
}

.biography-screen .image-39 {
  object-fit: cover;
  width: 87px;
  height: 57px;
  position: relative;
}

.biography-screen .image-40 {
  object-fit: cover;
  width: 91px;
  height: 59px;
  position: relative;
}

.biography-screen .group-132 {
  width: 319px;
  height: 166px;
  position: relative;
}

.biography-screen .group-133 {
  width: 318px;
  height: 112px;
  position: absolute;
  top: 0;
  left: 1px;
}

.biography-screen .frame-151 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 318px;
  height: 112px;
  display: flex;
  position: relative;
}

.biography-screen .frame-152 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 9px;
  margin-right: -30.37px;
  display: inline-flex;
  position: relative;
}

.biography-screen .text-wrapper-200 {
  color: #fff;
  letter-spacing: 0;
  width: 106px;
  height: 16px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 12px;
  position: relative;
}

.biography-screen .text-wrapper-201 {
  color: #fff;
  letter-spacing: 0;
  width: 348.37px;
  font-family: Kantumruy Pro, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  position: relative;
}

.biography-screen .frame-153 {
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  display: inline-flex;
  position: absolute;
  top: 118px;
  left: 0;
}

.biography-screen .div-61 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 136px;
  height: 15px;
  margin-top: -1px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.biography-screen .text-wrapper-202 {
  font-weight: 700;
  line-height: 11px;
}

.biography-screen .text-wrapper-203 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 267px;
  height: 11px;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 11px;
  position: relative;
}

.biography-screen .div-footer-social-10 {
  width: 230px;
  height: 161px;
  position: relative;
}

.biography-screen .text-wrapper-204 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 144px;
  height: 17px;
  font-family: Kantumruy, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 7px;
  left: 48px;
}

.biography-screen .frame-154 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px 15px;
  width: 184px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 48px;
}

.biography-screen .group-134 {
  width: 50px;
  height: 50px;
  position: relative;
}

.biography-screen .list-item-link-SVG-10 {
  background-image: url("group-2x.4af28778.png");
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  position: relative;
}

.biography-screen .symbol-51 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 17px;
}

.biography-screen .telegram-app-11 {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 11px;
  left: 11px;
}

.biography-screen .symbol-52 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 26px;
  height: 10px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 19px;
  left: 12px;
}

.biography-screen .symbol-53 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 24px;
  height: 6px;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 86px;
  left: 13px;
}

.biography-screen .symbol-54 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 81px;
}

.biography-screen .symbol-55 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 16px;
  position: absolute;
  top: 16px;
  left: 145px;
}

.biography-screen .subtract-10 {
  width: 1463px;
  height: 311px;
  position: absolute;
  top: 0;
  left: 0;
}

.biography-screen .group-135 {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.biography-screen .group-136 {
  background-image: url("image.73133226.png") !important;
}

.biography-screen .group-137 {
  background-image: url("link-image-2x.1221df16.png") !important;
}

/*# sourceMappingURL=index.2483f0e3.css.map */
