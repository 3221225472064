.detail-page {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.detail-page .div-43 {
  background-color: #ffffff;
  height: 2788px;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}

.detail-page .overlap-34 {
  height: 2593px;
  left: -15px;
  position: absolute;
  top: 195px;
  width: 1478px;
}

.detail-page .div-44 {
  height: 764px;
  left: 146px;
  position: absolute;
  top: 1558px;
  width: 1180px;
}

.detail-page .group-82 {
  height: 1757px;
  left: 106px;
  position: absolute;
  top: 0;
  width: 1257px;
}

.detail-page .overlap-35 {
  height: 1736px;
  position: relative;
  top: 21px;
  width: 1273px;
}

.detail-page .rectangle-8 {
  background-color: #6f6f6f;
  height: 20px;
  left: 76px;
  position: absolute;
  top: 931px;
  width: 2px;
}

.detail-page .group-83 {
  height: 1736px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1273px;
}

.detail-page .text-wrapper-135 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 13px;
  left: 76px;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 845px;
}

.detail-page .vector-13 {
  height: 11px;
  left: 38px;
  position: absolute;
  top: 4px;
  width: 6px;
}

.detail-page .vector-14 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 19px;
}

.detail-page .cambodia-prime-2 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-weight: 700;
  height: 148px;
  left: 8px;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 73px;
  width: 1248px;
}

.detail-page .text-wrapper-136 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  left: 1089px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 32px;
  width: 167px;
}

.detail-page .group-84 {
  height: 27px;
  left: 10px;
  position: absolute;
  top: 250px;
  width: 1246px;
}

.detail-page .div-45 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 0;
  width: 89px;
}

.detail-page .span-3 {
  height: 18px;
  opacity: 0.7;
  position: relative;
  top: 4px;
  width: 84px;
}

.detail-page .text-wrapper-137 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 18px;
  left: 0;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.detail-page .svg-3 {
  height: 15px;
  left: 91px;
  position: absolute;
  top: 1px;
  width: 15px;
}

.detail-page .group-85 {
  height: 21px;
  left: 1171px;
  position: absolute;
  top: 4px;
  width: 76px;
}

.detail-page .overlap-36 {
  height: 708px;
  left: 8px;
  position: absolute;
  top: 1028px;
  width: 872px;
}

.detail-page .group-86 {
  height: 474px;
  left: 0;
  position: absolute;
  top: 0;
  width: 872px;
}

.detail-page .having-risen-through {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 91px;
  left: 1px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  width: 861px;
}

.detail-page .text-wrapper-138 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 94px;
  left: 1px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 100px;
  width: 861px;
}

.detail-page .text-wrapper-139 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 43px;
  left: 1px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 205px;
  width: 861px;
}

.detail-page .overlap-group-19 {
  height: 216px;
  left: 0;
  position: absolute;
  top: 258px;
  width: 862px;
}

.detail-page .text-wrapper-140 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 89px;
  left: 1px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  width: 861px;
}

.detail-page .text-wrapper-141 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 132px;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 84px;
  width: 861px;
}

.detail-page .text-wrapper-142 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 132px;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 459px;
  width: 861px;
}

.detail-page .text-wrapper-143 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 132px;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 576px;
  width: 861px;
}

.detail-page .text-wrapper-144 {
  color: #6e6e6e;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 16px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 929px;
  width: 682px;
}

.detail-page .text-wrapper-145 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 24px;
  left: 9px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 980px;
  width: 495px;
}

.detail-page .text-wrapper-146 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 24px;
  left: 933px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 974px;
  width: 148px;
}

.detail-page .un-2 {
  height: 593px;
  left: 8px;
  object-fit: cover;
  position: absolute;
  top: 323px;
  width: 1248px;
}

.detail-page .group-87 {
  height: 714px;
  left: 0;
  position: absolute;
  top: 1879px;
  width: 1478px;
}

.detail-page .overlap-37 {
  height: 714px;
  position: relative;
}

.detail-page .cambodianflag-7 {
  height: 548px;
  left: 1px;
  position: absolute;
  top: 166px;
  width: 1476px;
}

.detail-page .group-88 {
  height: 399px;
  left: 1px;
  position: absolute;
  top: 311px;
  width: 1476px;
}

.detail-page .overlap-38 {
  height: 399px;
  position: relative;
}

.detail-page .footer-7 {
  background-color: transparent;
  height: 399px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1476px;
}

.detail-page .overlap-group-20 {
  height: 68px;
  left: 96px;
  position: relative;
  top: 301px;
  width: 1269px;
}

.detail-page .line-19 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 1246px;
}

.detail-page .div-46 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1246px;
}

.detail-page .p-7 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  left: 4px;
  padding: 6px 11.14px 6px 11px;
  position: absolute;
  top: 34px;
  width: 372px;
}

.detail-page .element-7 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.detail-page .text-wrapper-147 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.detail-page .text-wrapper-148 {
  font-family: "Montserrat", Helvetica;
}

.detail-page .ul-7 {
  height: 16px;
  left: 935px;
  position: absolute;
  top: 32px;
  width: 366px;
}

.detail-page .frame-99 {
  height: 16px;
  left: -36px;
  position: relative;
  width: 395px;
}

.detail-page .frame-100 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11px;
  left: 23px;
  position: relative;
}

.detail-page .text-wrapper-149 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 57px;
}

.detail-page .text-wrapper-150 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 95px;
}

.detail-page .text-wrapper-151 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 80px;
}

.detail-page .text-wrapper-152 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: 92px;
}

.detail-page .frame-101 {
  height: 240px;
  left: 99px;
  position: absolute;
  top: 45px;
  width: 1276px;
}

.detail-page .frame-102 {
  align-items: flex-start;
  display: inline-flex;
  gap: 136px;
  height: 180px;
  position: relative;
  top: 36px;
}

.detail-page .frame-103 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 203px;
  margin-bottom: -23px;
  position: relative;
  width: 423px;
}

.detail-page .frame-104 {
  height: 201px;
  overflow: hidden;
  position: relative;
  width: 377px;
}

.detail-page .group-89 {
  height: 41px;
  left: 395px;
  position: absolute;
  top: 12px;
  width: 238px;
}

.detail-page .div-47 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 130px;
}

.detail-page .text-wrapper-153 {
  font-weight: 700;
  line-height: 12px;
}

.detail-page .frame-105 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  left: 0;
  position: absolute;
  top: 37px;
}

.detail-page .frame-106 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 44px;
  position: relative;
}

.detail-page .image-29 {
  height: 59px;
  position: relative;
  width: 91px;
}

.detail-page .frame-107 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 45px;
  position: relative;
  width: 361px;
}

.detail-page .image-30 {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 87px;
}

.detail-page .image-31 {
  height: 59px;
  object-fit: cover;
  position: relative;
  width: 91px;
}

.detail-page .group-90 {
  height: 166px;
  position: relative;
  width: 319px;
}

.detail-page .group-91 {
  height: 112px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 318px;
}

.detail-page .frame-108 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 112px;
  position: relative;
  width: 318px;
}

.detail-page .frame-109 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  margin-right: -30.37px;
  position: relative;
}

.detail-page .text-wrapper-154 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  width: 106px;
}

.detail-page .text-wrapper-155 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  width: 348.37px;
}

.detail-page .frame-110 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 22px;
  left: 0;
  position: absolute;
  top: 118px;
}

.detail-page .div-48 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 15px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 136px;
}

.detail-page .text-wrapper-156 {
  font-weight: 700;
  line-height: 11px;
}

.detail-page .text-wrapper-157 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 11px;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: 267px;
}

.detail-page .div-footer-social-7 {
  height: 161px;
  position: relative;
  width: 230px;
}

.detail-page .text-wrapper-158 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 48px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
  width: 144px;
}

.detail-page .frame-111 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 17px 15px;
  left: 48px;
  position: absolute;
  top: 40px;
  width: 184px;
}

.detail-page .group-92 {
  height: 50px;
  position: relative;
  width: 50px;
}

.detail-page .list-item-link-SVG-7 {
  background-image: url(../../../static/img/group-42-2x.png);
  background-size: 100% 100%;
  height: 50px;
  position: relative;
  width: 50px;
}

.detail-page .symbol-36 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 17px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.detail-page .telegram-app-8 {
  height: 28px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 28px;
}

.detail-page .symbol-37 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 10px;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 26px;
}

.detail-page .symbol-38 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 6px;
  left: 13px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 86px;
  white-space: nowrap;
  width: 24px;
}

.detail-page .symbol-39 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 81px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.detail-page .symbol-40 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 145px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.detail-page .subtract-7 {
  height: 311px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1478px;
}

.detail-page .msft-content-card-instance {
  left: 1001px !important;
  position: absolute !important;
  top: 1049px !important;
}

.detail-page .msft-content-card-2 {
  left: 1001px !important;
  position: absolute !important;
  top: 1168px !important;
}

.detail-page .msft-content-card-3 {
  left: 1001px !important;
  position: absolute !important;
  top: 1288px !important;
}

.detail-page .msft-content-card-4 {
  left: 1001px !important;
  position: absolute !important;
  top: 1407px !important;
}

.detail-page .msft-content-card-5 {
  left: 1001px !important;
  position: absolute !important;
  top: 1526px !important;
}

.detail-page .group-93 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}
