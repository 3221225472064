.biography {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.biography .div-18 {
  background-color: #ffffff;
  height: 4247px;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}

.biography .overlap-16 {
  height: 4056px;
  left: -2px;
  position: absolute;
  top: 195px;
  width: 1465px;
}

.biography .background-4 {
  height: 884px;
  left: 7px;
  position: absolute;
  top: 502px;
  width: 1450px;
}

.biography .background-5 {
  height: 1175px;
  left: 1px;
  position: absolute;
  top: 1378px;
  width: 1461px;
}

.biography .background-6 {
  height: 1175px;
  left: 4px;
  position: absolute;
  top: 2468px;
  width: 1461px;
}

.biography .fluent-card-wrapper {
  height: 651px;
  left: 82px;
  position: absolute;
  top: 2587px;
  width: 1248px;
}

.biography .rectangle-wrapper {
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1.6px 3.6px #00000021, 0px 0px 2.9px #0000001c;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 651px;
  justify-content: center;
  position: relative;
  width: 1248px;
}

.biography .rectangle-3 {
  border: 9px solid;
  border-color: #ffcb47;
  box-shadow: 0px 4px 4px #00000040;
  height: 669px;
  margin-bottom: -9px;
  margin-left: -9px;
  margin-right: -9px;
  margin-top: -9px;
  position: relative;
  width: 1266px;
}

.biography .un {
  height: 499px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 1440px;
}

.biography .pseudo {
  height: 499px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1442px;
}

.biography .div-19 {
  height: 122px;
  left: 142px;
  position: absolute;
  top: 438px;
  width: 1180px;
}

.biography .cambodia-prime {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 30px;
  left: 236px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 4px 4px #00000040;
  top: 483px;
  white-space: nowrap;
  width: 991px;
}

.biography .group-40 {
  height: 338px;
  left: 148px;
  position: absolute;
  top: 1684px;
  width: 1180px;
}

.biography .award-wrapper {
  background-color: #d9d9d9;
  border: 6px solid;
  border-color: #ffcb47;
  height: 350px;
  left: -6px;
  position: absolute;
  top: -6px;
  width: 555px;
}

.biography .award {
  height: 338px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 543px;
}

.biography .group-41 {
  height: 338px;
  left: 638px;
  position: absolute;
  top: 0;
  width: 547px;
}

.biography .text-wrapper-64 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 274px;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 64px;
  width: 543px;
}

.biography .text-wrapper-65 {
  -webkit-text-stroke: 1px #000000;
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 31px;
  left: 78px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-decoration: underline;
  top: -1px;
  white-space: nowrap;
  width: 377px;
}

.biography .group-42 {
  height: 338px;
  left: 148px;
  position: absolute;
  top: 2147px;
  width: 1180px;
}

.biography .overlap-17 {
  background-color: #d9d9d9;
  border: 6px solid;
  border-color: #ffcb47;
  height: 350px;
  left: 632px;
  position: absolute;
  top: -6px;
  width: 555px;
}

.biography .group-43 {
  height: 338px;
  left: 0;
  position: absolute;
  top: 0;
  width: 547px;
}

.biography .text-wrapper-66 {
  -webkit-text-stroke: 1px #000000;
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 31px;
  left: 79px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-decoration: underline;
  top: -1px;
  white-space: nowrap;
  width: 377px;
}

.biography .group-44 {
  height: 563px;
  left: 141px;
  position: absolute;
  top: 2643px;
  width: 1174px;
}

.biography .group-45 {
  height: 247px;
  left: 132px;
  position: absolute;
  top: 1340px;
  width: 1182px;
}

.biography .overlap-18 {
  background-image: url(../../../static/img/div.png);
  background-size: 100% 100%;
  height: 122px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1180px;
}

.biography .text-wrapper-67 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 30px;
  left: 94px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 4px 4px #00000040;
  top: 46px;
  white-space: nowrap;
  width: 991px;
}

.biography .medal {
  height: 125px;
  left: 506px;
  object-fit: cover;
  position: absolute;
  top: 122px;
  width: 168px;
}

.biography .group-46 {
  height: 714px;
  left: 1px;
  position: absolute;
  top: 3342px;
  width: 1464px;
}

.biography .overlap-19 {
  height: 714px;
  position: relative;
}

.biography .cambodianflag-3 {
  height: 548px;
  left: 1px;
  position: absolute;
  top: 166px;
  width: 1462px;
}

.biography .group-47 {
  height: 399px;
  left: 1px;
  position: absolute;
  top: 311px;
  width: 1462px;
}

.biography .overlap-20 {
  height: 399px;
  position: relative;
}

.biography .footer-3 {
  background-color: transparent;
  height: 399px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1462px;
}

.biography .overlap-group-12 {
  height: 68px;
  left: 96px;
  position: relative;
  top: 301px;
  width: 1262px;
}

.biography .line-12 {
  height: 1px;
  left: 16px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 1246px;
}

.biography .div-20 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1246px;
}

.biography .p-3 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  left: 4px;
  padding: 6px 11.14px 6px 11px;
  position: absolute;
  top: 34px;
  width: 372px;
}

.biography .element-3 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.biography .text-wrapper-68 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.biography .text-wrapper-69 {
  font-family: "Montserrat", Helvetica;
}

.biography .ul-3 {
  height: 16px;
  left: 935px;
  position: absolute;
  top: 32px;
  width: 366px;
}

.biography .frame-43 {
  height: 16px;
  left: -36px;
  position: relative;
  width: 395px;
}

.biography .frame-44 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11px;
  left: 23px;
  position: relative;
}

.biography .text-wrapper-70 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 57px;
}

.biography .text-wrapper-71 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 95px;
}

.biography .text-wrapper-72 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 80px;
}

.biography .text-wrapper-73 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: 92px;
}

.biography .frame-45 {
  height: 240px;
  left: 98px;
  position: absolute;
  top: 45px;
  width: 1264px;
}

.biography .frame-46 {
  align-items: flex-start;
  display: inline-flex;
  gap: 136px;
  height: 180px;
  position: relative;
  top: 36px;
}

.biography .frame-47 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 203px;
  margin-bottom: -23px;
  position: relative;
  width: 423px;
}

.biography .frame-48 {
  height: 201px;
  overflow: hidden;
  position: relative;
  width: 377px;
}

.biography .group-48 {
  height: 41px;
  left: 395px;
  position: absolute;
  top: 12px;
  width: 238px;
}

.biography .div-21 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 130px;
}

.biography .text-wrapper-74 {
  font-weight: 700;
  line-height: 12px;
}

.biography .frame-49 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  left: 0;
  position: absolute;
  top: 37px;
}

.biography .frame-50 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 44px;
  position: relative;
}

.biography .image-15 {
  height: 59px;
  position: relative;
  width: 91px;
}

.biography .frame-51 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 45px;
  position: relative;
  width: 361px;
}

.biography .image-16 {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 87px;
}

.biography .image-17 {
  height: 59px;
  object-fit: cover;
  position: relative;
  width: 91px;
}

.biography .group-49 {
  height: 166px;
  position: relative;
  width: 319px;
}

.biography .group-50 {
  height: 112px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 318px;
}

.biography .frame-52 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 112px;
  position: relative;
  width: 318px;
}

.biography .frame-53 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  margin-right: -30.37px;
  position: relative;
}

.biography .text-wrapper-75 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  width: 106px;
}

.biography .text-wrapper-76 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  width: 348.37px;
}

.biography .frame-54 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 22px;
  left: 0;
  position: absolute;
  top: 118px;
}

.biography .div-22 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 15px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 136px;
}

.biography .text-wrapper-77 {
  font-weight: 700;
  line-height: 11px;
}

.biography .text-wrapper-78 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 11px;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: 267px;
}

.biography .div-footer-social-3 {
  height: 161px;
  position: relative;
  width: 230px;
}

.biography .text-wrapper-79 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 48px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
  width: 144px;
}

.biography .frame-55 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 17px 15px;
  left: 48px;
  position: absolute;
  top: 40px;
  width: 184px;
}

.biography .group-51 {
  height: 50px;
  position: relative;
  width: 50px;
}

.biography .list-item-link-SVG-3 {
  background-image: url(../../../static/img/group-18.png);
  background-size: 100% 100%;
  height: 50px;
  position: relative;
  width: 50px;
}

.biography .symbol-16 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 17px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.biography .telegram-app-4 {
  height: 28px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 28px;
}

.biography .symbol-17 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 10px;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 26px;
}

.biography .symbol-18 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 6px;
  left: 13px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 86px;
  white-space: nowrap;
  width: 24px;
}

.biography .symbol-19 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 81px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.biography .symbol-20 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 145px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.biography .subtract-3 {
  height: 311px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1464px;
}

.biography .group-52 {
  height: 637px;
  left: 95px;
  position: absolute;
  top: 626px;
  width: 1252px;
}

.biography .frame-56 {
  height: 551px;
  left: 680px;
  position: absolute;
  top: 26px;
  width: 572px;
}

.biography .overlap-group-13 {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1.6px 3.6px #00000021, 0px 0px 2.9px #0000001c;
  height: 506px;
  left: -22px;
  position: relative;
  top: 76px;
  width: 593px;
}

.biography .frame-57 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 449px;
  left: 21px;
  position: relative;
  top: 28px;
  width: 551px;
}

.biography .text-wrapper-80 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 95px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: 495.41px;
}

.biography .text-wrapper-81 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 161px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: -1px;
  position: relative;
  width: 552px;
}

.biography .text-wrapper-82 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 161px;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: 551px;
}

.biography .group-53 {
  height: 637px;
  left: 0;
  position: absolute;
  top: 0;
  width: 589px;
}

.biography .text-wrapper-83 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 37px;
  font-weight: 700;
  height: 53px;
  left: 48px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 490px;
}

.biography .text-wrapper-84 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 123px;
  left: 72px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 514px;
  width: 442px;
}

.biography .cambodia-pm {
  height: 369px;
  left: 0;
  position: absolute;
  top: 97px;
  width: 585px;
}

.biography .group-15-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}
