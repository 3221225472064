.component-wrapper {
  height: 871px;
  width: 449px;
}

.component-wrapper .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.component-wrapper .frame-6 {
  height: 269px;
  position: relative;
  width: 448.97px;
}

.component-wrapper .img-wrapper {
  background-color: #d9d9d9;
  border: 3px solid;
  border-color: #253b95;
  height: 272px;
  left: -3px;
  position: absolute;
  top: -3px;
  width: 192px;
}

.component-wrapper .image-2 {
  height: 266px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 186px;
}

.component-wrapper .text-wrapper-9 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-weight: 500;
  height: 10px;
  left: 218px;
  letter-spacing: 0;
  line-height: 10px;
  position: absolute;
  top: 253px;
  white-space: nowrap;
}

.component-wrapper .svg {
  height: 15px;
  left: 275px;
  position: absolute;
  top: 254px;
  width: 15px;
}

.component-wrapper .text-wrapper-10 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-weight: 500;
  height: 10px;
  left: 321px;
  letter-spacing: 0;
  line-height: 10px;
  position: absolute;
  top: 253px;
  white-space: nowrap;
}

.component-wrapper .text-wrapper-11 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-weight: 600;
  height: 54px;
  left: 218px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: -1px;
  width: 231px;
}
