.group {
  height: 195px;
  width: 1440px;
}

.group .overlap {
  height: 195px;
  position: relative;
}

.group .div-nav-utility {
  background-color: #253b95;
  height: 50px;
  left: 0;
  position: absolute;
  top: 145px;
  width: 1440px;
}

.group .overlap-wrapper {
  height: 145px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.group .overlap-group {
  height: 145px;
  position: relative;
}

.group .div {
  background-color: #253b95;
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.group .div-2 {
  height: 138px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 1440px;
}

.group .div-frame {
  align-items: center;
  border: 0px none;
  display: flex;
  gap: 16.2px;
  height: 37px;
  justify-content: flex-end;
  left: 142px;
  max-width: 1260px;
  padding: 1px 0px 3px 21px;
  position: absolute;
  top: 0;
  width: 1203px;
}

.group .link-state {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 5px 3.93px 5px 4.32px;
  position: relative;
}

.group .symbol {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.group .symbol-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 5px;
  position: relative;
}

.group .text-wrapper {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.group .header {
  background-color: #e41d2c;
  height: 95px;
  left: 0;
  position: absolute;
  top: 43px;
  width: 1440px;
}

.group .nav-list {
  height: 95px;
  position: relative;
  top: -2px;
  width: 1440px;
}

.group .overlap-group-wrapper {
  height: 89px;
  left: 380px;
  position: absolute;
  top: 3px;
  width: 388px;
}

.group .overlap-group-2 {
  height: 89px;
  position: relative;
  width: 384px;
}

.group .text-wrapper-2 {
  color: #ffffff;
  font-family: "Moulpali", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 50px;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 0;
  width: 321px;
}

.group .text-wrapper-3 {
  color: #ffffff;
  font-family: "Moul", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 50px;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 39px;
  width: 384px;
}

.group .frame-2 {
  align-items: center;
  display: flex;
  gap: 38px;
  justify-content: center;
  left: 833px;
  position: absolute;
  top: 32px;
  width: 512px;
}

.group .text-wrapper-4 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin-left: -6.5px;
  position: relative;
  text-shadow: 0px 2px 4px #00000029;
  white-space: nowrap;
  width: fit-content;
}

.group .text-wrapper-5 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 22px;
  position: relative;
  text-shadow: 0px 2px 4px #00000029;
  white-space: nowrap;
  width: fit-content;
}

.group .text-wrapper-6 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 22px;
  position: relative;
  text-align: center;
  text-shadow: 0px 2px 4px #00000029;
  width: 85px;
}

.group .div-flex {
  height: 29.59px;
  margin-right: -6.5px;
  position: relative;
  width: 92px;
}

.group .link {
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 36px;
}

.group .div-flex-wrapper {
  background-color: #f9e88c;
  height: 30px;
  width: 37px;
}

.group .image-wrapper {
  height: 30px;
  width: 28px;
}

.group .image {
  background-image: url(../../../static/img/image-2x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 26px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 32px;
}

.group .link-image {
  background-image: url(../../../static/img/link-image.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 26px;
  left: 52px;
  position: absolute;
  top: 2px;
  width: 32px;
}

.group .asset-hero {
  height: 149px;
  left: 205px;
  position: absolute;
  top: 23px;
  width: 149px;
}

.group .asset-wat {
  height: 50px;
  left: 101px;
  object-fit: cover;
  position: absolute;
  top: 65px;
  width: 78px;
}
