.frame-wrapper {
  align-items: center;
  display: flex;
  gap: 43px;
  position: relative;
  width: 1036px;
}

.frame-wrapper .fluent-card {
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1.6px 3.6px #00000021, 0px 0px 2.9px #0000001c;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 0px;
  height: 277px;
  justify-content: center;
  position: relative;
  width: 226px;
}

.frame-wrapper .frame-7 {
  height: 249px;
  position: relative;
  width: 189px;
}

.frame-wrapper .image-3 {
  height: 143px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 191px;
}

.frame-wrapper .text-wrapper-19 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-weight: 600;
  height: 70px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 152px;
  width: 188px;
}

.frame-wrapper .text-wrapper-20 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-weight: 500;
  height: 10px;
  left: 0;
  letter-spacing: 0;
  line-height: 10px;
  position: absolute;
  top: 235px;
  width: 81px;
}

.frame-wrapper .icon-facebook {
  height: 16px;
  left: 174px;
  position: absolute;
  top: 233px;
  width: 8px;
}

.frame-wrapper .vector {
  height: 16px;
  left: 148px;
  position: absolute;
  top: 233px;
  width: 18px;
}
