.frame {
  height: 242px;
  width: 1272px;
}

.frame .div-3 {
  height: 176px;
  left: 25px;
  position: relative;
  top: 44px;
  width: 1218px;
}

.frame .div-4 {
  height: 139px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 155px;
}

.frame .screenshot-3 {
  display: block;
  height: 120px;
  left: 10px;
  position: absolute;
  top: -6px;
  width: 134px;
}

.frame .text-wrapper-12 {
  color: #000000;
  font-family: "Kantumruy", Helvetica;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  left: 0;
  letter-spacing: 0.36px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 118px;
  width: 155px;
}

.frame .div-5 {
  height: 139px;
  left: 182px;
  position: absolute;
  top: 4px;
  width: 150px;
}

.frame .screenshot-4 {
  display: block;
  height: 120px;
  left: 5px;
  position: absolute;
  top: -6px;
  width: 141px;
}

.frame .text-wrapper-13 {
  color: #000000;
  font-family: "Kantumruy", Helvetica;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  left: 0;
  letter-spacing: 0.36px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 118px;
  width: 150px;
}

.frame .overlap-group-wrapper-2 {
  height: 173px;
  left: 346px;
  position: absolute;
  top: 0;
  width: 168px;
}

.frame .overlap-group-4 {
  height: 178px;
  position: relative;
  top: -6px;
}

.frame .screenshot-5 {
  display: block;
  height: 130px;
  left: 18px;
  position: absolute;
  top: 0;
  width: 132px;
}

.frame .text-wrapper-14 {
  color: #000000;
  font-family: "Kantumruy", Helvetica;
  font-size: 12px;
  font-weight: 700;
  height: 49px;
  left: 0;
  letter-spacing: 0.36px;
  line-height: 24.5px;
  position: absolute;
  text-align: center;
  top: 129px;
  width: 168px;
}

.frame .div-6 {
  height: 141px;
  left: 534px;
  position: absolute;
  top: 4px;
  width: 150px;
}

.frame .overlap-2 {
  height: 123px;
  left: 14px;
  position: absolute;
  top: -6px;
  width: 121px;
}

.frame .ellipse {
  background-color: #ffffff;
  border-radius: 49px;
  height: 98px;
  left: 14px;
  position: absolute;
  top: 6px;
  width: 98px;
}

.frame .screenshot-6 {
  display: block;
  height: 123px;
  left: 0;
  position: absolute;
  top: 0;
  width: 121px;
}

.frame .text-wrapper-15 {
  color: #000000;
  font-family: "Kantumruy", Helvetica;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  left: 0;
  letter-spacing: 0.36px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 120px;
  width: 150px;
}

.frame .div-7 {
  height: 144px;
  left: 704px;
  position: absolute;
  top: 4px;
  width: 150px;
}

.frame .text-wrapper-16 {
  color: #000000;
  font-family: "Kantumruy", Helvetica;
  font-size: 12px;
  font-weight: 700;
  height: 25px;
  left: 0;
  letter-spacing: 0.36px;
  line-height: 24.5px;
  position: absolute;
  text-align: center;
  top: 118px;
  width: 150px;
}

.frame .screenshot-7 {
  display: block;
  height: 117px;
  left: 10px;
  position: absolute;
  top: -6px;
  width: 125px;
}

.frame .div-8 {
  height: 164px;
  left: 884px;
  position: absolute;
  top: 0;
  width: 150px;
}

.frame .screenshot-8 {
  display: block;
  height: 125px;
  left: 18px;
  position: absolute;
  top: -6px;
  width: 113px;
}

.frame .text-wrapper-17 {
  color: #000000;
  font-family: "Kantumruy", Helvetica;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  left: 0;
  letter-spacing: 0.36px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 123px;
  width: 150px;
}

.frame .div-9 {
  height: 167px;
  left: 1068px;
  position: absolute;
  top: 2px;
  width: 150px;
}

.frame .screenshot-9 {
  display: block;
  height: 120px;
  left: 14px;
  position: absolute;
  top: -6px;
  width: 122px;
}

.frame .text-wrapper-18 {
  color: #000000;
  font-family: "Kantumruy", Helvetica;
  font-size: 12px;
  font-weight: 700;
  height: 49px;
  left: 0;
  letter-spacing: 0.36px;
  line-height: 24.5px;
  position: absolute;
  text-align: center;
  top: 117px;
  width: 150px;
}
