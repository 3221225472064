.msft-content-card {
  background-color: #ffffff;
  border-radius: 9px;
  box-shadow: 0px 1.6px 3.6px #00000040, 0px 0px 2.9px #00000040;
  height: 100px;
  overflow: hidden;
  position: relative;
  width: 323px;
}

.msft-content-card .text-wrapper-23 {
  color: #2b2b2b;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  left: 120px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 7px;
  width: 166px;
}

.msft-content-card .text-wrapper-24 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-weight: 500;
  height: 10px;
  left: 120px;
  letter-spacing: 0;
  line-height: 10px;
  position: absolute;
  top: 74px;
  white-space: nowrap;
}

.msft-content-card .image-6 {
  height: 84px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 104px;
}

.msft-content-card .vector-3 {
  height: 15px;
  left: 249px;
  position: absolute;
  top: 77px;
  width: 8px;
}

.msft-content-card .vector-4 {
  height: 14px;
  left: 274px;
  position: absolute;
  top: 78px;
  width: 17px;
}
