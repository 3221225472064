.div-wrapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.div-wrapper .vision-3 {
  background-color: #ffffff;
  height: 2779px;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}

.div-wrapper .group-94 {
  height: 56px;
  left: 251px;
  position: absolute;
  top: 809px;
  width: 942px;
}

.div-wrapper .button-2 {
  all: unset;
  box-sizing: border-box;
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 56px;
  left: 0;
  letter-spacing: 0;
  line-height: 59px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 402px;
}

.div-wrapper .button-statements-3 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 44px;
  left: 598px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6px;
  width: 340px;
}

.div-wrapper .group-95 {
  height: 1px;
  left: 97px;
  position: absolute;
  top: 904px;
  width: 1246px;
}

.div-wrapper .overlap-group-21 {
  height: 5px;
  position: relative;
  top: -4px;
}

.div-wrapper .line-20 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 1246px;
}

.div-wrapper .line-21 {
  height: 4px;
  left: 627px;
  position: absolute;
  top: 0;
  width: 619px;
}

.div-wrapper .group-96 {
  background-image: url(../../../static/img/image.png) !important;
}

.div-wrapper .group-97 {
  background-image: url(../../../static/img/link-image-2x.png) !important;
}

.div-wrapper .group-98 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.div-wrapper .group-99 {
  height: 714px;
  left: -1px;
  position: absolute;
  top: 2069px;
  width: 1442px;
}

.div-wrapper .overlap-39 {
  height: 714px;
  position: relative;
}

.div-wrapper .cambodianflag-8 {
  height: 548px;
  left: 1px;
  position: absolute;
  top: 166px;
  width: 1440px;
}

.div-wrapper .group-100 {
  height: 399px;
  left: 1px;
  position: absolute;
  top: 311px;
  width: 1440px;
}

.div-wrapper .overlap-40 {
  height: 399px;
  position: relative;
}

.div-wrapper .footer-8 {
  background-color: transparent;
  height: 399px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.div-wrapper .overlap-group-22 {
  height: 68px;
  left: 96px;
  position: relative;
  top: 301px;
  width: 1251px;
}

.div-wrapper .line-22 {
  height: 1px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 1246px;
}

.div-wrapper .div-49 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1246px;
}

.div-wrapper .p-8 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  left: 4px;
  padding: 6px 11.14px 6px 11px;
  position: absolute;
  top: 34px;
  width: 372px;
}

.div-wrapper .element-8 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.div-wrapper .text-wrapper-159 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.div-wrapper .text-wrapper-160 {
  font-family: "Montserrat", Helvetica;
}

.div-wrapper .ul-8 {
  height: 16px;
  left: 935px;
  position: absolute;
  top: 32px;
  width: 366px;
}

.div-wrapper .frame-112 {
  height: 16px;
  left: -36px;
  position: relative;
  width: 395px;
}

.div-wrapper .frame-113 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11px;
  left: 23px;
  position: relative;
}

.div-wrapper .text-wrapper-161 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 57px;
}

.div-wrapper .text-wrapper-162 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 95px;
}

.div-wrapper .text-wrapper-163 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 80px;
}

.div-wrapper .text-wrapper-164 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: 92px;
}

.div-wrapper .frame-114 {
  height: 240px;
  left: 97px;
  position: absolute;
  top: 45px;
  width: 1245px;
}

.div-wrapper .frame-115 {
  align-items: flex-start;
  display: inline-flex;
  gap: 136px;
  height: 180px;
  position: relative;
  top: 36px;
}

.div-wrapper .frame-116 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 203px;
  margin-bottom: -23px;
  position: relative;
  width: 423px;
}

.div-wrapper .frame-117 {
  height: 201px;
  overflow: hidden;
  position: relative;
  width: 377px;
}

.div-wrapper .group-101 {
  height: 41px;
  left: 395px;
  position: absolute;
  top: 12px;
  width: 238px;
}

.div-wrapper .div-50 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 130px;
}

.div-wrapper .text-wrapper-165 {
  font-weight: 700;
  line-height: 12px;
}

.div-wrapper .frame-118 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  left: 0;
  position: absolute;
  top: 37px;
}

.div-wrapper .frame-119 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 44px;
  position: relative;
}

.div-wrapper .image-32 {
  height: 59px;
  position: relative;
  width: 91px;
}

.div-wrapper .frame-120 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 45px;
  position: relative;
  width: 361px;
}

.div-wrapper .image-33 {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 87px;
}

.div-wrapper .image-34 {
  height: 59px;
  object-fit: cover;
  position: relative;
  width: 91px;
}

.div-wrapper .group-102 {
  height: 166px;
  position: relative;
  width: 319px;
}

.div-wrapper .group-103 {
  height: 112px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 318px;
}

.div-wrapper .frame-121 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 112px;
  position: relative;
  width: 318px;
}

.div-wrapper .frame-122 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  margin-right: -30.37px;
  position: relative;
}

.div-wrapper .text-wrapper-166 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  width: 106px;
}

.div-wrapper .text-wrapper-167 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  width: 348.37px;
}

.div-wrapper .frame-123 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 22px;
  left: 0;
  position: absolute;
  top: 118px;
}

.div-wrapper .div-51 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 15px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 136px;
}

.div-wrapper .text-wrapper-168 {
  font-weight: 700;
  line-height: 11px;
}

.div-wrapper .text-wrapper-169 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 11px;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: 267px;
}

.div-wrapper .div-footer-social-8 {
  height: 161px;
  position: relative;
  width: 230px;
}

.div-wrapper .text-wrapper-170 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 48px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
  width: 144px;
}

.div-wrapper .frame-124 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 17px 15px;
  left: 48px;
  position: absolute;
  top: 40px;
  width: 184px;
}

.div-wrapper .group-104 {
  height: 50px;
  position: relative;
  width: 50px;
}

.div-wrapper .list-item-link-SVG-8 {
  background-image: url(../../../static/img/group-6.png);
  background-size: 100% 100%;
  height: 50px;
  position: relative;
  width: 50px;
}

.div-wrapper .symbol-41 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 17px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.div-wrapper .telegram-app-9 {
  height: 28px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 28px;
}

.div-wrapper .symbol-42 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 10px;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 26px;
}

.div-wrapper .symbol-43 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 6px;
  left: 13px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 86px;
  white-space: nowrap;
  width: 24px;
}

.div-wrapper .symbol-44 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 81px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.div-wrapper .symbol-45 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 145px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.div-wrapper .subtract-8 {
  height: 311px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1442px;
}

.div-wrapper .button-SVG-3 {
  height: 20px;
  left: 1323px;
  position: absolute;
  top: 781px;
  width: 20px;
}

.div-wrapper .frame-125 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  height: 1060px;
  left: 97px;
  position: absolute;
  top: 905px;
}

.div-wrapper .frame-126 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.div-wrapper .link-3 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e6e7e8;
  height: 259px;
  position: relative;
  width: 1241px;
}

.div-wrapper .heading-president-6 {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 40px;
  left: 372px;
  letter-spacing: 0;
  line-height: 39px;
  position: absolute;
  top: 108px;
  white-space: nowrap;
  width: 869px;
}

.div-wrapper .text-wrapper-171 {
  color: #000000;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 20px;
  left: 1137px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 192px;
  white-space: nowrap;
  width: 104px;
}

.div-wrapper .text-wrapper-172 {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 37px;
  left: 685px;
  letter-spacing: 0;
  line-height: 47px;
  position: absolute;
  top: 212px;
  white-space: nowrap;
  width: 263px;
}

.div-wrapper .line-23 {
  height: 1px;
  left: 392px;
  position: absolute;
  top: 230px;
  width: 280px;
}

.div-wrapper .div-image-6 {
  background-image: url(../../../static/img/div-image-1-2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 9px;
  height: 204px;
  left: 0;
  position: absolute;
  top: 27px;
  transform: rotate(180deg);
  width: 323px;
}

.div-wrapper .heading-president-7 {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 40px;
  left: 389px;
  letter-spacing: 0;
  line-height: 39px;
  position: absolute;
  top: 108px;
  white-space: nowrap;
  width: 852px;
}

.div-wrapper .div-image-7 {
  background-image: url(../../../static/img/div-image.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 9px;
  height: 204px;
  left: 0;
  position: absolute;
  top: 27px;
  width: 323px;
}

.div-wrapper .div-image-8 {
  background-image: url(../../../static/img/div-image-2-2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 9px;
  height: 204px;
  left: 0;
  position: absolute;
  top: 27px;
  transform: rotate(180deg);
  width: 323px;
}

.div-wrapper .div-image-9 {
  background-image: url(../../../static/img/div-image-3-2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 9px;
  height: 201px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 323px;
}

.div-wrapper .main-section-3 {
  background-image: url(../../../static/img/rectangle-29.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 560px;
  left: 0;
  position: absolute;
  top: 195px;
  width: 1440px;
}

.div-wrapper .overlap-41 {
  height: 560px;
  position: relative;
}

.div-wrapper .div-absolute-6 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.78) 10.42%, rgba(0, 0, 0, 0) 100%);
  height: 560px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 1440px;
}

.div-wrapper .heading-president-8 {
  -webkit-text-stroke: 1px #ffffff;
  color: #ffffff;
  font-family: "Metal", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 86px;
  left: 386px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 424px;
  width: 667px;
}

.div-wrapper .screenshot-12 {
  height: 334px;
  left: 423px;
  object-fit: cover;
  position: absolute;
  top: 51px;
  width: 595px;
}
