.home {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.home .div-14 {
  background-color: #ffffff;
  height: 4383px;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}

.home .overlap-6 {
  height: 3171px;
  left: -10px;
  position: absolute;
  top: 0;
  width: 1464px;
}

.home .group-10 {
  height: 1539px;
  left: 5px;
  position: absolute;
  top: 746px;
  width: 1450px;
}

.home .overlap-group-7 {
  height: 1539px;
  position: relative;
}

.home .background {
  height: 883px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1450px;
}

.home .background-2 {
  height: 883px;
  left: 0;
  position: absolute;
  top: 656px;
  width: 1450px;
}

.home .image-10 {
  height: 760px;
  left: 10px;
  position: absolute;
  top: 145px;
  width: 1440px;
}

.home .section {
  height: 240px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 1440px;
}

.home .overlap-7 {
  height: 711px;
  left: 0;
  position: absolute;
  top: 195px;
  width: 1440px;
}

.home .div-absolute-2 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
  height: 250px;
  left: -186px;
  position: absolute;
  top: 186px;
  transform: rotate(-90deg);
  width: 623px;
}

.home .div-absolute-3 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
  height: 250px;
  left: 993px;
  position: absolute;
  top: 197px;
  transform: rotate(90deg);
  width: 644px;
}

.home .div-absolute-wrapper {
  height: 442px;
  left: 0;
  position: absolute;
  top: 269px;
  width: 1440px;
}

.home .div-absolute-4 {
  background: linear-gradient(180deg, rgb(0, 0, 0) 10.42%, rgba(0, 0, 0, 0) 100%);
  height: 442px;
  transform: rotate(180deg);
}

.home .group-11 {
  height: 181px;
  left: 271px;
  position: absolute;
  top: 409px;
  width: 898px;
}

.home .overlap-8 {
  height: 181px;
  position: relative;
  width: 900px;
}

.home .group-12 {
  height: 181px;
  left: 0;
  position: absolute;
  top: 0;
  width: 900px;
}

.home .text-wrapper-39 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 36px;
  font-weight: 700;
  height: 95px;
  left: 0;
  letter-spacing: 0;
  line-height: 60px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 898px;
}

.home .group-13 {
  height: 59px;
  left: 0;
  position: absolute;
  top: 123px;
  width: 227px;
}

.home .group-14 {
  height: 21px;
  left: 140px;
  position: absolute;
  top: 18px;
  width: 89px;
}

.home .symbol-9 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 11px;
}

.home .telegram-app-2 {
  height: 20px;
  left: 68px;
  position: absolute;
  top: 0;
  width: 17px;
}

.home .symbol-10 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  left: 33px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 1px;
  width: 17px;
}

.home .text-wrapper-40 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 59px;
  left: 0;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  top: 0;
  width: 112px;
}

.home .group-16 {
  height: 35px;
  left: 775px;
  position: absolute;
  top: 132px;
  width: 123px;
}

.home .group-17 {
  height: 35px;
}

.home .overlap-9 {
  height: 35px;
  position: relative;
  width: 123px;
}

.home .group-18 {
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 107px;
}

.home .overlap-group-8 {
  height: 35px;
  position: relative;
  width: 105px;
}

.home .text-wrapper-41 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 18px;
  left: 11px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 9px;
  width: 84px;
}

.home .rectangle {
  border: 1.5px solid;
  border-color: #ffffff;
  height: 35px;
  left: 0;
  position: absolute;
  top: 0;
  width: 105px;
}

.home .arrow-2 {
  height: 9px;
  left: 104px;
  position: absolute;
  top: 14px;
  width: 19px;
}

.home .group-19 {
  height: 14px;
  left: 631px;
  position: absolute;
  top: 586px;
  width: 177px;
}

.home .ellipse-2 {
  background-color: #ffffff80;
  border-radius: 4.67px;
  height: 9px;
  left: 42px;
  position: absolute;
  top: 2px;
  width: 9px;
}

.home .ellipse-3 {
  background-color: #ffffff;
  border-radius: 7px;
  height: 14px;
  left: 82px;
  position: absolute;
  top: 0;
  width: 14px;
}

.home .ellipse-4 {
  background-color: #ffffff80;
  border-radius: 4.67px;
  height: 9px;
  left: 126px;
  position: absolute;
  top: 2px;
  width: 9px;
}

.home .ellipse-5 {
  background-color: #ffffff80;
  border-radius: 4.67px;
  height: 9px;
  left: 168px;
  position: absolute;
  top: 2px;
  width: 9px;
}

.home .ellipse-6 {
  background-color: #ffffff80;
  border-radius: 4.67px;
  height: 9px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 9px;
}

.home .group-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.home .group-20 {
  height: 62px;
  left: 307px;
  position: absolute;
  top: 2777px;
  width: 356px;
}

.home .text-wrapper-42 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 20px;
  font-weight: 500;
  height: 62px;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-shadow: 0px 4px 4px #00000040;
  top: 0;
  width: 354px;
}

.home .group-21 {
  height: 934px;
  left: 0;
  position: absolute;
  top: 2237px;
  width: 1464px;
}

.home .overlap-10 {
  height: 934px;
  position: relative;
  width: 1460px;
}

.home .group-22 {
  height: 934px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1460px;
}

.home .overlap-group-9 {
  height: 934px;
  position: relative;
}

.home .image-11 {
  height: 900px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1460px;
}

.home .group-23 {
  background: linear-gradient(180deg, rgba(37, 59, 149, 0) 0%, rgb(0, 0, 0) 78.65%);
  height: 919px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 1460px;
}

.home .text-wrapper-43 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 28px;
  font-weight: 700;
  height: 102px;
  left: 215px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  text-align: center;
  top: 78px;
  width: 296px;
}

.home .text-wrapper-44 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 28px;
  font-weight: 700;
  height: 102px;
  left: 983px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  text-align: center;
  top: 64px;
  width: 296px;
}

.home .asset-map {
  height: 376px;
  left: 111px;
  object-fit: cover;
  position: absolute;
  top: 183px;
  width: 487px;
}

.home .group-24 {
  height: 62px;
  left: 101px;
  position: absolute;
  top: 738px;
  width: 547px;
}

.home .group-25 {
  height: 62px;
  left: 191px;
  position: absolute;
  top: 0;
  width: 356px;
}

.home .text-wrapper-45 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 47px;
  left: 0;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  text-align: center;
  top: 7px;
  white-space: nowrap;
  width: 140px;
}

.home .line-6 {
  height: 2px;
  left: 154px;
  position: absolute;
  top: 30px;
  width: 20px;
}

.home .group-26 {
  height: 62px;
  left: 819px;
  position: absolute;
  top: 738px;
  width: 547px;
}

.home .group-27 {
  height: 62px;
  left: 819px;
  position: absolute;
  top: 623px;
  width: 547px;
}

.home .group-28 {
  height: 62px;
  left: 101px;
  position: absolute;
  top: 623px;
  width: 547px;
}

.home .rectangle-2 {
  height: 388px;
  left: 906px;
  object-fit: cover;
  position: absolute;
  top: 173px;
  width: 453px;
}

.home .component-1 {
  height: 853px !important;
  left: 99px !important;
  position: absolute !important;
  top: 1251px !important;
  width: 752px !important;
}

.home .component-instance {
  gap: 23px !important;
}

.home .frame-26 {
  align-items: center;
  display: inline-flex;
  gap: 4px;
  height: 40px;
  justify-content: center;
  left: 99px;
  position: absolute;
  top: 1189px;
}

.home .america {
  height: 41px;
  margin-bottom: -0.48px;
  margin-top: -0.48px;
  position: relative;
  width: 40px;
}

.home .frame-27 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 47px;
  justify-content: space-between;
  margin-bottom: -3.48px;
  margin-top: -3.48px;
  padding: 0px 0px 5px;
  position: relative;
}

.home .text-wrapper-46 {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 48px;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: -5px;
  margin-top: -1px;
  position: relative;
  width: 100px;
}

.home .line-7 {
  height: 3px;
  margin-bottom: -1.5px;
  margin-top: -6.5px;
  position: relative;
  width: 109px;
}

.home .frame-28 {
  height: 34px;
  left: 911px;
  position: absolute;
  top: 1189px;
  width: 186px;
}

.home .icon-bullhorn {
  height: 32px;
  left: 0;
  position: absolute;
  top: 7px;
  width: 27px;
}

.home .frame-29 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 38px;
  left: 44px;
  position: absolute;
  top: 7px;
  width: 142px;
}

.home .text-wrapper-47 {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 24px;
  letter-spacing: 0;
  line-height: 40px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 129px;
}

.home .line-8 {
  height: 4px;
  position: relative;
  width: 132px;
}

.home .right {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  height: 46px;
  left: 669px;
  position: absolute;
  top: 1188px;
  width: 178px;
}

.home .datepicker {
  align-items: center;
  background-color: var(--neutralwhite);
  border: 1px solid;
  border-color: #253b95;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  height: 46px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 14px;
  position: relative;
  width: 178px;
}

.home .calendar-alt {
  height: 41px !important;
  margin-bottom: -7.5px !important;
  margin-top: -7.5px !important;
  position: relative !important;
  width: 41px !important;
}

.home .text-wrapper-48 {
  color: #253b95;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.08px;
  line-height: 22.4px;
  position: relative;
  width: 99px;
}

.home .component-2 {
  height: 849px !important;
  left: 911px !important;
  position: absolute !important;
  top: 1255px !important;
}

.home .component-2-instance {
  gap: 21px !important;
}

.home .group-29 {
  background-image: url(../../../static/img/gold-ornament-frame-8-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 336px;
  left: 210px;
  opacity: 0;
  position: absolute;
  top: 809px;
  transform: translate(25px, 0);
  width: 1040px;
}

.home .overlap-11 {
  height: 158px;
  left: 69px;
  position: relative;
  top: 90px;
  width: 813px;
}

.home .group-30 {
  height: 91px;
  left: 155px;
  position: absolute;
  top: 32px;
  width: 658px;
}

.home .group-31 {
  height: 91px;
  width: 662px;
}

.home .overlap-12 {
  height: 91px;
  position: relative;
  width: 658px;
}

.home .text-wrapper-49 {
  color: #000000;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 20px;
  left: 554px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 70px;
  white-space: nowrap;
  width: 104px;
}

.home .overlap-group-10 {
  height: 91px;
  left: 0;
  position: absolute;
  top: 0;
  width: 658px;
}

.home .heading-link-2 {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 61px;
  left: 0;
  letter-spacing: 0;
  line-height: 39px;
  position: absolute;
  top: 0;
  width: 658px;
}

.home .group-32 {
  height: 37px;
  left: 0;
  position: absolute;
  top: 55px;
  width: 499px;
}

.home .text-wrapper-50 {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 37px;
  left: 258px;
  letter-spacing: 0;
  line-height: 47px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 239px;
}

.home .line-9 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 17px;
  width: 239px;
}

.home .asset-new-hero {
  height: 158px;
  left: 0;
  position: absolute;
  top: 0;
  width: 158px;
}

.home .overlap-13 {
  height: 1207px;
  left: -11px;
  position: absolute;
  top: 3176px;
  width: 1459px;
}

.home .background-3 {
  height: 696px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1450px;
}

.home .group-33 {
  height: 58px;
  left: 573px;
  position: absolute;
  top: 62px;
  width: 306px;
}

.home .text-wrapper-51 {
  color: #000000;
  font-family: "Kantumruy", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 48px;
  left: 0;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.home .line-10 {
  height: 4px;
  left: 107px;
  position: absolute;
  top: 56px;
  width: 100px;
}

.home .group-34 {
  height: 714px;
  left: 4px;
  position: absolute;
  top: 493px;
  width: 1455px;
}

.home .overlap-14 {
  height: 714px;
  position: relative;
}

.home .cambodianflag-2 {
  height: 548px;
  left: 1px;
  position: absolute;
  top: 166px;
  width: 1453px;
}

.home .group-35 {
  height: 399px;
  left: 1px;
  position: absolute;
  top: 311px;
  width: 1453px;
}

.home .overlap-15 {
  height: 399px;
  position: relative;
}

.home .footer-2 {
  background-color: transparent;
  height: 399px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1453px;
}

.home .overlap-group-11 {
  height: 68px;
  left: 96px;
  position: relative;
  top: 301px;
  width: 1257px;
}

.home .line-11 {
  height: 1px;
  left: 11px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 1246px;
}

.home .div-15 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1246px;
}

.home .p-2 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  left: 4px;
  padding: 6px 11.14px 6px 11px;
  position: absolute;
  top: 34px;
  width: 372px;
}

.home .element-2 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-52 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.home .text-wrapper-53 {
  font-family: "Montserrat", Helvetica;
}

.home .ul-2 {
  height: 16px;
  left: 935px;
  position: absolute;
  top: 32px;
  width: 366px;
}

.home .frame-30 {
  height: 16px;
  left: -36px;
  position: relative;
  width: 395px;
}

.home .frame-31 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11px;
  left: 23px;
  position: relative;
}

.home .text-wrapper-54 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 57px;
}

.home .text-wrapper-55 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 95px;
}

.home .text-wrapper-56 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 80px;
}

.home .text-wrapper-57 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: 92px;
}

.home .frame-32 {
  height: 240px;
  left: 98px;
  position: absolute;
  top: 45px;
  width: 1256px;
}

.home .frame-33 {
  align-items: flex-start;
  display: inline-flex;
  gap: 136px;
  height: 180px;
  position: relative;
  top: 36px;
}

.home .frame-34 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 203px;
  margin-bottom: -23px;
  position: relative;
  width: 423px;
}

.home .frame-35 {
  height: 201px;
  overflow: hidden;
  position: relative;
  width: 377px;
}

.home .group-36 {
  height: 41px;
  left: 395px;
  position: absolute;
  top: 12px;
  width: 238px;
}

.home .div-16 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 130px;
}

.home .text-wrapper-58 {
  font-weight: 700;
  line-height: 12px;
}

.home .frame-36 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  left: 0;
  position: absolute;
  top: 37px;
}

.home .frame-37 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 44px;
  position: relative;
}

.home .image-12 {
  height: 59px;
  position: relative;
  width: 91px;
}

.home .frame-38 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 45px;
  position: relative;
  width: 361px;
}

.home .image-13 {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 87px;
}

.home .image-14 {
  height: 59px;
  object-fit: cover;
  position: relative;
  width: 91px;
}

.home .group-37 {
  height: 166px;
  position: relative;
  width: 319px;
}

.home .group-38 {
  height: 112px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 318px;
}

.home .frame-39 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 112px;
  position: relative;
  width: 318px;
}

.home .frame-40 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  margin-right: -30.37px;
  position: relative;
}

.home .text-wrapper-59 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  width: 106px;
}

.home .text-wrapper-60 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  width: 348.37px;
}

.home .frame-41 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 22px;
  left: 0;
  position: absolute;
  top: 118px;
}

.home .div-17 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 15px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 136px;
}

.home .text-wrapper-61 {
  font-weight: 700;
  line-height: 11px;
}

.home .text-wrapper-62 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 11px;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: 267px;
}

.home .div-footer-social-2 {
  height: 161px;
  position: relative;
  width: 230px;
}

.home .text-wrapper-63 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 48px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
  width: 144px;
}

.home .frame-42 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 17px 15px;
  left: 48px;
  position: absolute;
  top: 40px;
  width: 184px;
}

.home .group-39 {
  height: 50px;
  position: relative;
  width: 50px;
}

.home .list-item-link-SVG-2 {
  background-image: url(../../../static/img/group-12.png);
  background-size: 100% 100%;
  height: 50px;
  position: relative;
  width: 50px;
}

.home .symbol-11 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 17px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.home .telegram-app-3 {
  height: 28px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 28px;
}

.home .symbol-12 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 10px;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 26px;
}

.home .symbol-13 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 6px;
  left: 13px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 86px;
  white-space: nowrap;
  width: 24px;
}

.home .symbol-14 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 81px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.home .symbol-15 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 145px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.home .subtract-2 {
  height: 311px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1455px;
}

.home .frame-77 {
  left: 108px !important;
  position: absolute !important;
  top: 172px !important;
}
