.screen-8 {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.screen-8 .vision-4 {
  background-color: #ffffff;
  height: 3739px;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}

.screen-8 .overlap-42 {
  height: 1987px;
  left: -1px;
  position: absolute;
  top: 1752px;
  width: 1458px;
}

.screen-8 .group-105 {
  height: 1784px;
  left: 2px;
  position: absolute;
  top: 61px;
  width: 1456px;
}

.screen-8 .group-106 {
  background-image: url(../../../static/img/background1-6-2-2.png);
  background-size: 100% 100%;
  height: 883px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1450px;
}

.screen-8 .background-9 {
  height: 883px;
  left: 6px;
  position: absolute;
  top: 901px;
  width: 1450px;
}

.screen-8 .group-107 {
  height: 714px;
  left: 0;
  position: absolute;
  top: 1273px;
  width: 1442px;
}

.screen-8 .overlap-43 {
  height: 714px;
  position: relative;
}

.screen-8 .cambodianflag-9 {
  height: 548px;
  left: 1px;
  position: absolute;
  top: 166px;
  width: 1440px;
}

.screen-8 .group-108 {
  height: 399px;
  left: 1px;
  position: absolute;
  top: 311px;
  width: 1440px;
}

.screen-8 .overlap-44 {
  height: 399px;
  position: relative;
}

.screen-8 .footer-9 {
  background-color: transparent;
  height: 399px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.screen-8 .overlap-group-23 {
  height: 68px;
  left: 96px;
  position: relative;
  top: 301px;
  width: 1251px;
}

.screen-8 .line-24 {
  height: 1px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 1246px;
}

.screen-8 .div-52 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1246px;
}

.screen-8 .p-9 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  left: 4px;
  padding: 6px 11.14px 6px 11px;
  position: absolute;
  top: 34px;
  width: 372px;
}

.screen-8 .element-9 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.screen-8 .text-wrapper-173 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.screen-8 .text-wrapper-174 {
  font-family: "Montserrat", Helvetica;
}

.screen-8 .ul-9 {
  height: 16px;
  left: 935px;
  position: absolute;
  top: 32px;
  width: 366px;
}

.screen-8 .frame-127 {
  height: 16px;
  left: -36px;
  position: relative;
  width: 395px;
}

.screen-8 .frame-128 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11px;
  left: 23px;
  position: relative;
}

.screen-8 .text-wrapper-175 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 57px;
}

.screen-8 .text-wrapper-176 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 95px;
}

.screen-8 .text-wrapper-177 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 80px;
}

.screen-8 .text-wrapper-178 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: 92px;
}

.screen-8 .frame-129 {
  height: 240px;
  left: 97px;
  position: absolute;
  top: 45px;
  width: 1245px;
}

.screen-8 .frame-130 {
  align-items: flex-start;
  display: inline-flex;
  gap: 136px;
  height: 180px;
  position: relative;
  top: 36px;
}

.screen-8 .frame-131 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 203px;
  margin-bottom: -23px;
  position: relative;
  width: 423px;
}

.screen-8 .frame-132 {
  height: 201px;
  overflow: hidden;
  position: relative;
  width: 377px;
}

.screen-8 .group-109 {
  height: 41px;
  left: 395px;
  position: absolute;
  top: 12px;
  width: 238px;
}

.screen-8 .div-53 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 130px;
}

.screen-8 .text-wrapper-179 {
  font-weight: 700;
  line-height: 12px;
}

.screen-8 .frame-133 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  left: 0;
  position: absolute;
  top: 37px;
}

.screen-8 .frame-134 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 44px;
  position: relative;
}

.screen-8 .image-35 {
  height: 59px;
  position: relative;
  width: 91px;
}

.screen-8 .frame-135 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 45px;
  position: relative;
  width: 361px;
}

.screen-8 .image-36 {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 87px;
}

.screen-8 .image-37 {
  height: 59px;
  object-fit: cover;
  position: relative;
  width: 91px;
}

.screen-8 .group-110 {
  height: 166px;
  position: relative;
  width: 319px;
}

.screen-8 .group-111 {
  height: 112px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 318px;
}

.screen-8 .frame-136 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 112px;
  position: relative;
  width: 318px;
}

.screen-8 .frame-137 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  margin-right: -30.37px;
  position: relative;
}

.screen-8 .text-wrapper-180 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  width: 106px;
}

.screen-8 .text-wrapper-181 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  width: 348.37px;
}

.screen-8 .frame-138 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 22px;
  left: 0;
  position: absolute;
  top: 118px;
}

.screen-8 .div-54 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 15px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 136px;
}

.screen-8 .text-wrapper-182 {
  font-weight: 700;
  line-height: 11px;
}

.screen-8 .text-wrapper-183 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 11px;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: 267px;
}

.screen-8 .div-footer-social-9 {
  height: 161px;
  position: relative;
  width: 230px;
}

.screen-8 .text-wrapper-184 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 48px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
  width: 144px;
}

.screen-8 .frame-139 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 17px 15px;
  left: 48px;
  position: absolute;
  top: 40px;
  width: 184px;
}

.screen-8 .group-112 {
  height: 50px;
  position: relative;
  width: 50px;
}

.screen-8 .list-item-link-SVG-9 {
  background-image: url(../../../static/img/group-42.png);
  background-size: 100% 100%;
  height: 50px;
  position: relative;
  width: 50px;
}

.screen-8 .symbol-46 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 17px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.screen-8 .telegram-app-10 {
  height: 28px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 28px;
}

.screen-8 .symbol-47 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 10px;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 26px;
}

.screen-8 .symbol-48 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 6px;
  left: 13px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 86px;
  white-space: nowrap;
  width: 24px;
}

.screen-8 .symbol-49 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 81px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.screen-8 .symbol-50 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 145px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.screen-8 .subtract-9 {
  height: 311px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1442px;
}

.screen-8 .component-1-variant2-instance {
  height: 1009px !important;
  left: 91px !important;
  position: absolute !important;
  top: 184px !important;
  width: 1277px !important;
}

.screen-8 .component-3 {
  align-items: center !important;
  gap: unset !important;
  height: 979px !important;
  justify-content: space-between !important;
}

.screen-8 .div-55 {
  height: 122px;
  left: 140px;
  position: absolute;
  top: 0;
  width: 1180px;
}

.screen-8 .pentagon-strategy-2 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 30px;
  left: 234px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 4px 4px #00000040;
  top: 45px;
  white-space: nowrap;
  width: 991px;
}

.screen-8 .main-section-4 {
  background-image: url(../../../static/img/rectangle-29.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 560px;
  left: 0;
  position: absolute;
  top: 195px;
  width: 1440px;
}

.screen-8 .overlap-group-24 {
  height: 560px;
  position: relative;
}

.screen-8 .div-absolute-7 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.78) 10.42%, rgba(0, 0, 0, 0) 100%);
  height: 560px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 1440px;
}

.screen-8 .heading-president-9 {
  -webkit-text-stroke: 1px #ffffff;
  color: #ffffff;
  font-family: "Metal", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 86px;
  left: 386px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 422px;
  width: 667px;
}

.screen-8 .screenshot-13 {
  height: 334px;
  left: 423px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 595px;
}

.screen-8 .group-113 {
  height: 56px;
  left: 251px;
  position: absolute;
  top: 809px;
  width: 942px;
}

.screen-8 .button-3 {
  all: unset;
  box-sizing: border-box;
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 56px;
  left: 0;
  letter-spacing: 0;
  line-height: 59px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 402px;
}

.screen-8 .button-statements-4 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 44px;
  left: 598px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6px;
  width: 340px;
}

.screen-8 .group-114 {
  height: 1px;
  left: 97px;
  position: absolute;
  top: 904px;
  width: 1246px;
}

.screen-8 .overlap-45 {
  height: 5px;
  position: relative;
  top: -4px;
}

.screen-8 .line-25 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 1246px;
}

.screen-8 .line-26 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 619px;
}

.screen-8 .group-115 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.screen-8 .group-116 {
  background-image: url(../../../static/img/image.png) !important;
}

.screen-8 .group-117 {
  background-image: url(../../../static/img/link-image-2x.png) !important;
}

.screen-8 .pantagon-2 {
  height: 699px;
  left: 244px;
  position: absolute;
  top: 959px;
  width: 945px;
}

.screen-8 .button-SVG-4 {
  height: 20px;
  left: 1323px;
  position: absolute;
  top: 789px;
  width: 20px;
}
