.contact {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.contact .div-28 {
  background-color: #ffffff;
  height: 2569px;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}

.contact .overlap-24 {
  height: 411px;
  left: 0;
  position: absolute;
  top: 195px;
  width: 1440px;
}

.contact .section-image {
  background-image: url(../../../static/img/section-image.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 410px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 1440px;
}

.contact .rectangle-7 {
  background-color: #00000040;
  height: 411px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.contact .section-2 {
  background-color: #253795;
  height: 56px;
  left: 0;
  position: absolute;
  top: 606px;
  width: 1440px;
}

.contact .group-62 {
  height: 20px;
  left: 101px;
  position: relative;
  top: 18px;
  width: 1243px;
}

.contact .div-relative-2 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 156px;
}

.contact .img-3 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 18px;
}

.contact .text-wrapper-104 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 18px;
  left: 30px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 128px;
}

.contact .div-relative-3 {
  height: 20px;
  left: 285px;
  position: absolute;
  top: 0;
  width: 168px;
}

.contact .text-wrapper-105 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 18px;
  left: 30px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 139px;
}

.contact .div-relative-4 {
  height: 20px;
  left: 582px;
  position: absolute;
  top: 0;
  width: 661px;
}

.contact .img-4 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 13px;
}

.contact .text-wrapper-106 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 18px;
  left: 25px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 638px;
}

.contact .section-iframe {
  border-bottom-style: solid;
  border-bottom-width: 8px;
  border-color: #e41d2c;
  border-top-style: solid;
  border-top-width: 8px;
  height: 400px;
  left: 0;
  position: absolute;
  top: 662px;
  width: 1440px;
}

.contact .body {
  background-color: #808080;
  height: 384px;
  position: relative;
  top: 8px;
}

.contact .div-29 {
  background-color: #e5e3df;
  height: 384px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.contact .overlap-25 {
  background-image: url(../../../static/img/vt.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 834px;
  position: absolute;
  top: 179px;
  width: 256px;
}

.contact .image-22 {
  height: 22px;
  left: 100px;
  position: absolute;
  top: 168px;
  width: 52px;
}

.contact .vt {
  background-image: url(../../../static/img/vt-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 578px;
  position: absolute;
  top: 179px;
  width: 256px;
}

.contact .vt-2 {
  background-image: url(../../../static/img/vt-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 834px;
  position: absolute;
  top: -77px;
  width: 256px;
}

.contact .vt-3 {
  background-image: url(../../../static/img/vt-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 322px;
  position: absolute;
  top: -77px;
  width: 256px;
}

.contact .vt-4 {
  background-image: url(../../../static/img/vt-4.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 322px;
  position: absolute;
  top: 179px;
  width: 256px;
}

.contact .vt-5 {
  background-image: url(../../../static/img/vt-5.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 578px;
  position: absolute;
  top: -77px;
  width: 256px;
}

.contact .vt-6 {
  background-image: url(../../../static/img/vt-6.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 1090px;
  position: absolute;
  top: -77px;
  width: 256px;
}

.contact .overlap-group-15 {
  height: 256px;
  left: 1090px;
  position: absolute;
  top: 179px;
  width: 1024px;
}

.contact .vt-7 {
  background-image: url(../../../static/img/vt-7.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 0;
  position: absolute;
  top: 0;
  width: 256px;
}

.contact .vt-8 {
  background-image: url(../../../static/img/vt-12.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 768px;
  position: absolute;
  top: 0;
  width: 256px;
}

.contact .vt-9 {
  background-image: url(../../../static/img/vt-14.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 256px;
  position: absolute;
  top: 0;
  width: 256px;
}

.contact .vt-10 {
  background-image: url(../../../static/img/vt-17.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 512px;
  position: absolute;
  top: 0;
  width: 256px;
}

.contact .div-30 {
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 1px 4px -1px #0000004c;
  height: 81px;
  left: 780px;
  overflow: hidden;
  position: absolute;
  top: 100px;
  width: 40px;
}

.contact .button-zoom-in {
  height: 40px;
  left: 18656px;
  position: absolute;
  top: 326px;
  width: 40px;
}

.contact .div-31 {
  background-color: #e6e6e6;
  height: 1px;
  left: 5px;
  position: absolute;
  top: 40px;
  width: 30px;
}

.contact .button-zoom-out {
  height: 40px;
  left: 18656px;
  position: absolute;
  top: 367px;
  width: 40px;
}

.contact .div-32 {
  background-color: #f5f5f5;
  height: 14px;
  left: 223px;
  opacity: 0.7;
  position: absolute;
  top: 191px;
  transform: rotate(180deg);
  width: 382px;
}

.contact .button-keyboard {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 14px;
  left: 610px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: center;
  top: 190px;
  white-space: nowrap;
  width: 87px;
}

.contact .div-33 {
  background-color: #f5f5f5;
  height: 14px;
  left: 310px;
  opacity: 0.7;
  position: absolute;
  top: 191px;
  transform: rotate(180deg);
  width: 394px;
}

.contact .text-wrapper-107 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 11px;
  left: 709px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: right;
  top: 191px;
  white-space: nowrap;
  width: 75px;
}

.contact .div-34 {
  background-color: #f5f5f5;
  height: 14px;
  left: 350px;
  opacity: 0.7;
  position: absolute;
  top: 191px;
  transform: rotate(180deg);
  width: 441px;
}

.contact .link-terms {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 11px;
  left: 796px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  text-align: right;
  top: 191px;
  white-space: nowrap;
  width: 28px;
}

.contact .vt-11 {
  background-image: url(../../../static/img/vt-8.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 66px;
  position: absolute;
  top: 179px;
  width: 256px;
}

.contact .button-show-wrapper {
  background-image: url(../../../static/img/vt-9.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: -190px;
  position: absolute;
  top: 179px;
  width: 256px;
}

.contact .button-show {
  background-color: #ffffff;
  border: 2px solid;
  border-radius: 3px;
  box-shadow: 0px 2px 6px #0000004c;
  height: 42px;
  left: 200px;
  overflow: hidden;
  position: relative;
  top: 152px;
  width: 42px;
}

.contact .region-map-wrapper {
  background-color: #e5e3df;
  height: 38px;
  left: 2px;
  overflow: hidden;
  position: relative;
  top: 2px;
  width: 38px;
}

.contact .region-map {
  background-image: url(../../../static/img/region-map.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: -61px;
  position: relative;
  top: -143px;
  width: 256px;
}

.contact .overlap-26 {
  background-image: url(../../../static/img/vt-10.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: -190px;
  position: absolute;
  top: -77px;
  width: 256px;
}

.contact .div-35 {
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 1px 4px -1px #0000004c;
  height: 92px;
  left: 22px;
  overflow: hidden;
  position: relative;
  top: 87px;
  transform: rotate(180deg);
  width: 178px;
}

.contact .div-place-name {
  height: 16px;
  left: 12px;
  overflow: hidden;
  position: absolute;
  top: 66px;
  width: 76px;
}

.contact .text-wrapper-108 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-weight: 500;
  height: 16px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: nowrap;
  width: 92px;
}

.contact .text-wrapper-109 {
  color: #5b5b5b;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 14px;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 45px;
  white-space: nowrap;
  width: 69px;
}

.contact .link-get-directions {
  background-image: url(../../../static/img/link-get-directions-to-this-location-on-google-maps.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 22px;
  left: 183px;
  position: absolute;
  top: 60px;
  width: 22px;
}

.contact .link-get-directions-2 {
  height: 14px;
  left: 159px;
  position: absolute;
  top: 41px;
  transform: rotate(180deg);
  width: 70px;
}

.contact .text-wrapper-110 {
  color: #1a73e8;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 14px;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -1px;
  white-space: nowrap;
  width: 54px;
}

.contact .link-view-larger-map {
  color: #1a73e8;
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 14px;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 9px;
  white-space: nowrap;
  width: 87px;
}

.contact .vt-12 {
  background-image: url(../../../static/img/vt-11.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 66px;
  position: absolute;
  top: -77px;
  width: 256px;
}

.contact .vt-13 {
  background-image: url(../../../static/img/vt-13.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 1346px;
  position: absolute;
  top: -77px;
  width: 256px;
}

.contact .vt-14 {
  background-image: url(../../../static/img/vt-15.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 1858px;
  position: absolute;
  top: -77px;
  width: 256px;
}

.contact .vt-15 {
  background-image: url(../../../static/img/vt-16.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 256px;
  left: 1602px;
  position: absolute;
  top: -77px;
  width: 256px;
}

.contact .overlap-27 {
  height: 727px;
  left: -48px;
  position: absolute;
  top: 1085px;
  width: 1536px;
}

.contact .div-px {
  height: 727px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1536px;
}

.contact .section-3 {
  border-color: #e41d2c;
  border-top-style: solid;
  border-top-width: 1px;
  height: 1px;
  left: 80px;
  position: absolute;
  top: 18px;
  width: 597px;
}

.contact .section-4 {
  color: #000000;
  font-family: "Kantumruy", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 32px;
  left: 693px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
  width: 151px;
}

.contact .section-5 {
  border-color: #e41d2c;
  border-top-style: solid;
  border-top-width: 1px;
  height: 1px;
  left: 859px;
  position: absolute;
  top: 18px;
  width: 597px;
}

.contact .section-6 {
  border: 2px solid;
  border-color: #e41d2c;
  height: 4px;
  left: 748px;
  position: absolute;
  top: 51px;
  width: 40px;
}

.contact .section-link-img {
  height: 32px;
  left: 185px;
  position: absolute;
  top: 132px;
  width: 20px;
}

.contact .group-130 {
  left: 80px !important;
  position: absolute !important;
  top: 258px !important;
}

.contact .section-link {
  color: #253795;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 154px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 234px;
  white-space: nowrap;
  width: 79px;
}

.contact .vector-8 {
  height: 26px;
  left: 407px;
  position: absolute;
  top: 136px;
  width: 32px;
}

.contact .section-link-twitter {
  color: #253795;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 395px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 172px;
  white-space: nowrap;
  width: 56px;
}

.contact .vector-9 {
  height: 31px;
  left: 637px;
  position: absolute;
  top: 134px;
  width: 31px;
}

.contact .section-link-2 {
  color: #253795;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 615px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 172px;
  white-space: nowrap;
  width: 75px;
}

.contact .vector-10 {
  height: 32px;
  left: 867px;
  position: absolute;
  top: 133px;
  width: 28px;
}

.contact .section-link-tiktok {
  color: #253795;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 857px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 172px;
  white-space: nowrap;
  width: 49px;
}

.contact .vector-11 {
  height: 24px;
  left: 1094px;
  position: absolute;
  top: 137px;
  width: 34px;
}

.contact .section-link-youtube {
  color: #253795;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 1077px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 172px;
  white-space: nowrap;
  width: 68px;
}

.contact .vector-12 {
  height: 28px;
  left: 1326px;
  position: absolute;
  top: 135px;
  width: 28px;
}

.contact .section-link-3 {
  color: #253795;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  left: 1299px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 172px;
  white-space: nowrap;
  width: 83px;
}

.contact .group-63 {
  height: 12px;
  left: 726px;
  position: absolute;
  top: 708px;
  width: 84px;
}

.contact .list-item-button-go {
  background-color: #253795;
  border: 2px solid;
  border-color: #a9212d;
  border-radius: 6px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 12px;
}

.contact .list-item-button-go-2 {
  border: 2px solid;
  border-color: #a9212d;
  border-radius: 6px;
  height: 12px;
  left: 18px;
  position: absolute;
  top: 0;
  width: 12px;
}

.contact .list-item-button-go-3 {
  border: 2px solid;
  border-color: #a9212d;
  border-radius: 6px;
  height: 12px;
  left: 36px;
  position: absolute;
  top: 0;
  width: 12px;
}

.contact .list-item-button-go-4 {
  border: 2px solid;
  border-color: #a71f2d;
  border-radius: 6px;
  height: 12px;
  left: 54px;
  position: absolute;
  top: 0;
  width: 12px;
}

.contact .list-item-button-go-5 {
  border: 2px solid;
  border-color: #a9212d;
  border-radius: 6px;
  height: 12px;
  left: 72px;
  position: absolute;
  top: 0;
  width: 12px;
}

.contact .group-64 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.contact .group-65 {
  height: 714px;
  left: 9px;
  position: absolute;
  top: 1855px;
  width: 1463px;
}

.contact .overlap-28 {
  height: 714px;
  position: relative;
}

.contact .cambodianflag-5 {
  height: 548px;
  left: 1px;
  position: absolute;
  top: 166px;
  width: 1461px;
}

.contact .group-66 {
  height: 399px;
  left: 1px;
  position: absolute;
  top: 311px;
  width: 1461px;
}

.contact .overlap-29 {
  height: 399px;
  position: relative;
}

.contact .footer-5 {
  background-color: transparent;
  height: 399px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1461px;
}

.contact .overlap-group-16 {
  height: 68px;
  left: 96px;
  position: relative;
  top: 301px;
  width: 1261px;
}

.contact .line-15 {
  height: 1px;
  left: 15px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 1246px;
}

.contact .div-36 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1246px;
}

.contact .p-5 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  left: 4px;
  padding: 6px 11.14px 6px 11px;
  position: absolute;
  top: 34px;
  width: 372px;
}

.contact .element-5 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-111 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.contact .text-wrapper-112 {
  font-family: "Montserrat", Helvetica;
}

.contact .ul-5 {
  height: 16px;
  left: 935px;
  position: absolute;
  top: 32px;
  width: 366px;
}

.contact .frame-71 {
  height: 16px;
  left: -36px;
  position: relative;
  width: 395px;
}

.contact .frame-72 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11px;
  left: 23px;
  position: relative;
}

.contact .text-wrapper-113 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 57px;
}

.contact .text-wrapper-114 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 95px;
}

.contact .text-wrapper-115 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 80px;
}

.contact .text-wrapper-116 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: 92px;
}

.contact .frame-73 {
  height: 240px;
  left: 98px;
  position: absolute;
  top: 45px;
  width: 1263px;
}

.contact .frame-74 {
  align-items: flex-start;
  display: inline-flex;
  gap: 136px;
  height: 180px;
  position: relative;
  top: 36px;
}

.contact .frame-75 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 203px;
  margin-bottom: -23px;
  position: relative;
  width: 423px;
}

.contact .frame-76 {
  height: 201px;
  overflow: hidden;
  position: relative;
  width: 377px;
}

.contact .group-67 {
  height: 41px;
  left: 395px;
  position: absolute;
  top: 12px;
  width: 238px;
}

.contact .div-37 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 130px;
}

.contact .text-wrapper-117 {
  font-weight: 700;
  line-height: 12px;
}

.contact .frame-78 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  left: 0;
  position: absolute;
  top: 37px;
}

.contact .frame-79 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 44px;
  position: relative;
}

.contact .image-23 {
  height: 59px;
  position: relative;
  width: 91px;
}

.contact .frame-80 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 45px;
  position: relative;
  width: 361px;
}

.contact .image-24 {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 87px;
}

.contact .image-25 {
  height: 59px;
  object-fit: cover;
  position: relative;
  width: 91px;
}

.contact .group-68 {
  height: 166px;
  position: relative;
  width: 319px;
}

.contact .group-69 {
  height: 112px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 318px;
}

.contact .frame-81 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 112px;
  position: relative;
  width: 318px;
}

.contact .frame-82 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  margin-right: -30.37px;
  position: relative;
}

.contact .text-wrapper-118 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  width: 106px;
}

.contact .text-wrapper-119 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  width: 348.37px;
}

.contact .frame-83 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 22px;
  left: 0;
  position: absolute;
  top: 118px;
}

.contact .div-38 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 15px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 136px;
}

.contact .text-wrapper-120 {
  font-weight: 700;
  line-height: 11px;
}

.contact .text-wrapper-121 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 11px;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: 267px;
}

.contact .div-footer-social-5 {
  height: 161px;
  position: relative;
  width: 230px;
}

.contact .text-wrapper-122 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 48px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
  width: 144px;
}

.contact .frame-84 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 17px 15px;
  left: 48px;
  position: absolute;
  top: 40px;
  width: 184px;
}

.contact .group-70 {
  height: 50px;
  position: relative;
  width: 50px;
}

.contact .list-item-link-SVG-5 {
  background-image: url(../../../static/img/group.png);
  background-size: 100% 100%;
  height: 50px;
  position: relative;
  width: 50px;
}

.contact .symbol-26 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 17px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.contact .telegram-app-6 {
  height: 28px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 28px;
}

.contact .symbol-27 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 10px;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 26px;
}

.contact .symbol-28 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 6px;
  left: 13px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 86px;
  white-space: nowrap;
  width: 24px;
}

.contact .symbol-29 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 81px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.contact .symbol-30 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 145px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.contact .subtract-5 {
  height: 311px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1463px;
}
