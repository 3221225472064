.component {
  height: 873px;
  width: 749px;
}

.component .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 29px;
  position: relative;
  top: 3px;
}

.component .frame-4 {
  background-color: #ffffff;
  border-radius: 9px;
  box-shadow: 0px 2px 15px #0000001c;
  height: 195px;
  position: relative;
  width: 749px;
}

.component .screenshot {
  height: 164px;
  left: 18px;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 287px;
}

.component .heading-link {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 31px;
  left: 324px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 31px;
  white-space: nowrap;
  width: 394px;
}

.component .p {
  color: #000000;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 700;
  height: 48px;
  left: 324px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 78px;
  width: 410px;
}

.component .overlap-group-3 {
  height: 14px;
  left: 324px;
  position: absolute;
  top: 147px;
  width: 66px;
}

.component .text-wrapper-7 {
  color: #3c83ff;
  font-family: "Kantumruy", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 14px;
  left: 0;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-decoration: underline;
  top: 0;
  white-space: nowrap;
  width: 63px;
}

.component .arrow {
  height: 4px;
  left: 61px;
  position: absolute;
  top: 7px;
  width: 5px;
}

.component .text-wrapper-8 {
  color: #000000;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 19px;
  left: 612px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  text-align: center;
  top: 142px;
  white-space: nowrap;
  width: 88px;
}

.component .line {
  height: 1px;
  left: 595px;
  object-fit: cover;
  position: absolute;
  top: 153px;
  width: 9px;
}

.component .img {
  height: 1px;
  left: 703px;
  object-fit: cover;
  position: absolute;
  top: 153px;
  width: 9px;
}

.component .screenshot-2 {
  height: 163px;
  left: 18px;
  position: absolute;
  top: 19px;
  width: 287px;
}
