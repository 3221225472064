.group-wrapper {
  height: 439px;
  width: 1381px;
}

.group-wrapper .frame-8 {
  align-items: center;
  display: inline-flex;
  gap: 17px;
  left: 66px;
  position: relative;
  top: 13px;
}

.group-wrapper .button-go-to {
  background-color: #253795;
  border-radius: 35px;
  height: 43px;
  position: relative;
  width: 43px;
}

.group-wrapper .symbol-2 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 13px;
  left: 11px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 12px;
  white-space: nowrap;
  width: 19px;
}

.group-wrapper .div-relative-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.group-wrapper .div-relative {
  background-color: #ffffff01;
  border-radius: 4px;
  box-shadow: 0px 2px 4px -2px #0000001a, 0px 4px 6px -1px #0000001a;
  height: 413.14px;
  overflow: hidden;
  position: relative;
  width: 270px;
}

.group-wrapper .img-2 {
  height: 9px;
  left: 12px;
  position: absolute;
  top: 389px;
  width: 9px;
}

.group-wrapper .text-wrapper-21 {
  color: #253b95;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 15px;
  left: 29px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 385px;
  white-space: nowrap;
  width: 86px;
}

.group-wrapper .image-4 {
  border-radius: 4px 4px 0px 0px;
  height: 381px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 363px;
}

.group-wrapper .image-5 {
  height: 375px;
  left: 0;
  position: absolute;
  top: 0;
  width: 269px;
}

.group-wrapper .vector-2 {
  height: 20px;
  left: 248px;
  position: absolute;
  top: 384px;
  width: 11px;
}

.group-wrapper .button-go-to-2 {
  background-color: #253795;
  border-radius: 35px;
  height: 43px;
  position: relative;
  transform: rotate(180deg);
  width: 43px;
}

.group-wrapper .symbol-3 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 13px;
  left: 11px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
  width: 19px;
}
