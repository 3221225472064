.biography-screen {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.biography-screen .biography-2 {
  background-color: #ffffff;
  height: 4250px;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}

.biography-screen .overlap-46 {
  height: 1569px;
  left: 96px;
  position: absolute;
  top: 1879px;
  width: 1251px;
}

.biography-screen .group-118 {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1.6px 3.6px #00000021, 0px 0px 2.9px #0000001c;
  height: 651px;
  left: 0;
  position: absolute;
  top: 918px;
  width: 1248px;
}

.biography-screen .background-10 {
  height: 651px;
  left: 0;
  position: absolute;
  top: 918px;
  width: 1251px;
}

.biography-screen .group-119 {
  height: 1525px;
  left: 43px;
  position: absolute;
  top: 0;
  width: 1188px;
}

.biography-screen .group-120 {
  height: 338px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 1180px;
}

.biography-screen .award-2 {
  height: 338px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 543px;
}

.biography-screen .group-121 {
  height: 338px;
  left: 637px;
  position: absolute;
  top: 0;
  width: 547px;
}

.biography-screen .text-wrapper-185 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 274px;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 64px;
  width: 543px;
}

.biography-screen .text-wrapper-186 {
  -webkit-text-stroke: 1px #000000;
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 31px;
  left: 78px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-decoration: underline;
  top: -1px;
  white-space: nowrap;
  width: 377px;
}

.biography-screen .group-122 {
  height: 338px;
  left: 8px;
  position: absolute;
  top: 463px;
  width: 1180px;
}

.biography-screen .award-3 {
  height: 338px;
  left: 637px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 543px;
}

.biography-screen .group-123 {
  height: 338px;
  left: 0;
  position: absolute;
  top: 0;
  width: 547px;
}

.biography-screen .text-wrapper-187 {
  -webkit-text-stroke: 1px #000000;
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 31px;
  left: 79px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-decoration: underline;
  top: -1px;
  white-space: nowrap;
  width: 377px;
}

.biography-screen .group-124 {
  height: 563px;
  left: 0;
  position: absolute;
  top: 962px;
  width: 1167px;
}

.biography-screen .div-56 {
  height: 76px;
  left: 350px;
  position: absolute;
  top: 874px;
  width: 547px;
}

.biography-screen .overlap-47 {
  height: 560px;
  left: -1px;
  position: absolute;
  top: 195px;
  width: 1442px;
}

.biography-screen .un-3 {
  height: 499px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 1441px;
}

.biography-screen .pseudo-2 {
  height: 499px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1442px;
}

.biography-screen .div-57 {
  height: 122px;
  left: 141px;
  position: absolute;
  top: 438px;
  width: 1180px;
}

.biography-screen .group-125 {
  height: 637px;
  left: 104px;
  position: absolute;
  top: 814px;
  width: 1252px;
}

.biography-screen .frame-140 {
  height: 551px;
  left: 680px;
  position: absolute;
  top: 26px;
  width: 572px;
}

.biography-screen .overlap-group-25 {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1.6px 3.6px #00000021, 0px 0px 2.9px #0000001c;
  height: 506px;
  left: -22px;
  position: relative;
  top: 76px;
  width: 593px;
}

.biography-screen .frame-141 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 449px;
  left: 21px;
  position: relative;
  top: 28px;
  width: 551px;
}

.biography-screen .text-wrapper-188 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 95px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: 495.41px;
}

.biography-screen .text-wrapper-189 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 161px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: -1px;
  position: relative;
  width: 552px;
}

.biography-screen .text-wrapper-190 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 161px;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: 551px;
}

.biography-screen .group-126 {
  height: 637px;
  left: 0;
  position: absolute;
  top: 0;
  width: 589px;
}

.biography-screen .text-wrapper-191 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 37px;
  font-weight: 700;
  height: 53px;
  left: 48px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 490px;
}

.biography-screen .text-wrapper-192 {
  color: #333333;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 123px;
  left: 72px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: center;
  top: 514px;
  width: 442px;
}

.biography-screen .hrfihu-cambodia-pm {
  height: 369px;
  left: 0;
  position: absolute;
  top: 97px;
  width: 585px;
}

.biography-screen .group-127 {
  height: 247px;
  left: 130px;
  position: absolute;
  top: 1535px;
  width: 1180px;
}

.biography-screen .div-58 {
  height: 122px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1180px;
}

.biography-screen .medal-2 {
  height: 125px;
  left: 506px;
  object-fit: cover;
  position: absolute;
  top: 122px;
  width: 168px;
}

.biography-screen .group-128 {
  height: 714px;
  left: 0;
  position: absolute;
  top: 3537px;
  width: 1463px;
}

.biography-screen .overlap-48 {
  height: 714px;
  position: relative;
}

.biography-screen .cambodianflag-10 {
  height: 548px;
  left: 1px;
  position: absolute;
  top: 166px;
  width: 1461px;
}

.biography-screen .group-129 {
  height: 399px;
  left: 1px;
  position: absolute;
  top: 311px;
  width: 1461px;
}

.biography-screen .overlap-49 {
  height: 399px;
  position: relative;
}

.biography-screen .footer-10 {
  background-color: transparent;
  height: 399px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1461px;
}

.biography-screen .overlap-group-26 {
  height: 68px;
  left: 96px;
  position: relative;
  top: 301px;
  width: 1261px;
}

.biography-screen .line-27 {
  height: 1px;
  left: 15px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 1246px;
}

.biography-screen .div-59 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1246px;
}

.biography-screen .p-10 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  left: 4px;
  padding: 6px 11.14px 6px 11px;
  position: absolute;
  top: 34px;
  width: 372px;
}

.biography-screen .element-10 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.biography-screen .text-wrapper-193 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.biography-screen .text-wrapper-194 {
  font-family: "Montserrat", Helvetica;
}

.biography-screen .ul-10 {
  height: 16px;
  left: 935px;
  position: absolute;
  top: 32px;
  width: 366px;
}

.biography-screen .frame-142 {
  height: 16px;
  left: -36px;
  position: relative;
  width: 395px;
}

.biography-screen .frame-143 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11px;
  left: 23px;
  position: relative;
}

.biography-screen .text-wrapper-195 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 57px;
}

.biography-screen .text-wrapper-196 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 95px;
}

.biography-screen .text-wrapper-197 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 80px;
}

.biography-screen .text-wrapper-198 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: 92px;
}

.biography-screen .frame-144 {
  height: 240px;
  left: 98px;
  position: absolute;
  top: 45px;
  width: 1263px;
}

.biography-screen .frame-145 {
  align-items: flex-start;
  display: inline-flex;
  gap: 136px;
  height: 180px;
  position: relative;
  top: 36px;
}

.biography-screen .frame-146 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 203px;
  margin-bottom: -23px;
  position: relative;
  width: 423px;
}

.biography-screen .frame-147 {
  height: 201px;
  overflow: hidden;
  position: relative;
  width: 377px;
}

.biography-screen .group-131 {
  height: 41px;
  left: 395px;
  position: absolute;
  top: 12px;
  width: 238px;
}

.biography-screen .div-60 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 130px;
}

.biography-screen .text-wrapper-199 {
  font-weight: 700;
  line-height: 12px;
}

.biography-screen .frame-148 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  left: 0;
  position: absolute;
  top: 37px;
}

.biography-screen .frame-149 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 44px;
  position: relative;
}

.biography-screen .image-38 {
  height: 59px;
  position: relative;
  width: 91px;
}

.biography-screen .frame-150 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 45px;
  position: relative;
  width: 361px;
}

.biography-screen .image-39 {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 87px;
}

.biography-screen .image-40 {
  height: 59px;
  object-fit: cover;
  position: relative;
  width: 91px;
}

.biography-screen .group-132 {
  height: 166px;
  position: relative;
  width: 319px;
}

.biography-screen .group-133 {
  height: 112px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 318px;
}

.biography-screen .frame-151 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 112px;
  position: relative;
  width: 318px;
}

.biography-screen .frame-152 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  margin-right: -30.37px;
  position: relative;
}

.biography-screen .text-wrapper-200 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  width: 106px;
}

.biography-screen .text-wrapper-201 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  width: 348.37px;
}

.biography-screen .frame-153 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 22px;
  left: 0;
  position: absolute;
  top: 118px;
}

.biography-screen .div-61 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 15px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 136px;
}

.biography-screen .text-wrapper-202 {
  font-weight: 700;
  line-height: 11px;
}

.biography-screen .text-wrapper-203 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 11px;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: 267px;
}

.biography-screen .div-footer-social-10 {
  height: 161px;
  position: relative;
  width: 230px;
}

.biography-screen .text-wrapper-204 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 48px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
  width: 144px;
}

.biography-screen .frame-154 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 17px 15px;
  left: 48px;
  position: absolute;
  top: 40px;
  width: 184px;
}

.biography-screen .group-134 {
  height: 50px;
  position: relative;
  width: 50px;
}

.biography-screen .list-item-link-SVG-10 {
  background-image: url(../../../static/img/group-2x.png);
  background-size: 100% 100%;
  height: 50px;
  position: relative;
  width: 50px;
}

.biography-screen .symbol-51 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 17px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.biography-screen .telegram-app-11 {
  height: 28px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 28px;
}

.biography-screen .symbol-52 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 10px;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 26px;
}

.biography-screen .symbol-53 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 6px;
  left: 13px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 86px;
  white-space: nowrap;
  width: 24px;
}

.biography-screen .symbol-54 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 81px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.biography-screen .symbol-55 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 145px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.biography-screen .subtract-10 {
  height: 311px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1463px;
}

.biography-screen .group-135 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.biography-screen .group-136 {
  background-image: url(../../../static/img/image.png) !important;
}

.biography-screen .group-137 {
  background-image: url(../../../static/img/link-image-2x.png) !important;
}
