.component-variant {
  height: 1253px;
  width: 1272px;
}

.component-variant .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 25px;
  position: relative;
}

.component-variant .frame-10 {
  background-color: #ffffff;
  border-radius: 9px;
  box-shadow: 0px 2px 15px #0000001c;
  height: 226px;
  position: relative;
  width: 1272px;
}

.component-variant .div-image {
  height: 200px;
  left: 13px;
  object-fit: cover;
  position: absolute;
  top: 13px;
  width: 348px;
}

.component-variant .heading-president {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 67px;
  left: 384px;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  top: 19px;
  width: 874px;
}

.component-variant .frame-11 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 7px;
  left: 384px;
  position: absolute;
  top: 105px;
}

.component-variant .first-to-maintain {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 40px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  width: 874px;
}

.component-variant .span {
  font-weight: 700;
}

.component-variant .text-wrapper-22 {
  font-weight: 500;
}

.component-variant .second-to-build-and {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 53px;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  width: 874px;
}
