.vision {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.vision .div-10 {
  background-color: #ffffff;
  height: 2779px;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}

.vision .group-2 {
  height: 56px;
  left: 251px;
  position: absolute;
  top: 809px;
  width: 942px;
}

.vision .button {
  all: unset;
  box-sizing: border-box;
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 56px;
  left: 0;
  letter-spacing: 0;
  line-height: 59px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 402px;
}

.vision .button-statements {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 44px;
  left: 598px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6px;
  width: 340px;
}

.vision .group-3 {
  height: 1px;
  left: 97px;
  position: absolute;
  top: 904px;
  width: 1246px;
}

.vision .overlap-group-5 {
  height: 5px;
  position: relative;
  top: -4px;
}

.vision .line-2 {
  height: 2px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 1246px;
}

.vision .line-3 {
  height: 4px;
  left: 627px;
  position: absolute;
  top: 0;
  width: 619px;
}

.vision .group-15 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.vision .group-4 {
  height: 714px;
  left: -1px;
  position: absolute;
  top: 2069px;
  width: 1442px;
}

.vision .overlap-3 {
  height: 714px;
  position: relative;
}

.vision .cambodianflag {
  height: 548px;
  left: 1px;
  position: absolute;
  top: 166px;
  width: 1440px;
}

.vision .group-5 {
  height: 399px;
  left: 1px;
  position: absolute;
  top: 311px;
  width: 1440px;
}

.vision .overlap-4 {
  height: 399px;
  position: relative;
}

.vision .footer {
  background-color: transparent;
  height: 399px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.vision .overlap-group-6 {
  height: 68px;
  left: 96px;
  position: relative;
  top: 301px;
  width: 1251px;
}

.vision .line-4 {
  height: 1px;
  left: 5px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 1246px;
}

.vision .div-11 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1246px;
}

.vision .element-wrapper {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  left: 4px;
  padding: 6px 11.14px 6px 11px;
  position: absolute;
  top: 34px;
  width: 372px;
}

.vision .element {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vision .text-wrapper-25 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.vision .text-wrapper-26 {
  font-family: "Montserrat", Helvetica;
}

.vision .ul {
  height: 16px;
  left: 935px;
  position: absolute;
  top: 32px;
  width: 366px;
}

.vision .frame-12 {
  height: 16px;
  left: -36px;
  position: relative;
  width: 395px;
}

.vision .frame-13 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11px;
  left: 23px;
  position: relative;
}

.vision .text-wrapper-27 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 57px;
}

.vision .text-wrapper-28 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 95px;
}

.vision .text-wrapper-29 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 80px;
}

.vision .text-wrapper-30 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: 92px;
}

.vision .frame-14 {
  height: 240px;
  left: 97px;
  position: absolute;
  top: 45px;
  width: 1245px;
}

.vision .frame-15 {
  align-items: flex-start;
  display: inline-flex;
  gap: 136px;
  height: 180px;
  position: relative;
  top: 36px;
}

.vision .frame-16 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 203px;
  margin-bottom: -23px;
  position: relative;
  width: 423px;
}

.vision .frame-17 {
  height: 201px;
  overflow: hidden;
  position: relative;
  width: 377px;
}

.vision .group-6 {
  height: 41px;
  left: 395px;
  position: absolute;
  top: 12px;
  width: 238px;
}

.vision .div-12 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 130px;
}

.vision .text-wrapper-31 {
  font-weight: 700;
  line-height: 12px;
}

.vision .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  left: 0;
  position: absolute;
  top: 37px;
}

.vision .frame-19 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 44px;
  position: relative;
}

.vision .image-7 {
  height: 59px;
  position: relative;
  width: 91px;
}

.vision .frame-20 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 45px;
  position: relative;
  width: 361px;
}

.vision .image-8 {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 87px;
}

.vision .image-9 {
  height: 59px;
  object-fit: cover;
  position: relative;
  width: 91px;
}

.vision .group-7 {
  height: 166px;
  position: relative;
  width: 319px;
}

.vision .group-8 {
  height: 112px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 318px;
}

.vision .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 112px;
  position: relative;
  width: 318px;
}

.vision .frame-22 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  margin-right: -30.37px;
  position: relative;
}

.vision .text-wrapper-32 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  width: 106px;
}

.vision .text-wrapper-33 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  width: 348.37px;
}

.vision .frame-23 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 22px;
  left: 0;
  position: absolute;
  top: 118px;
}

.vision .div-13 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 15px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 136px;
}

.vision .text-wrapper-34 {
  font-weight: 700;
  line-height: 11px;
}

.vision .text-wrapper-35 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 11px;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: 267px;
}

.vision .div-footer-social {
  height: 161px;
  position: relative;
  width: 230px;
}

.vision .text-wrapper-36 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 48px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
  width: 144px;
}

.vision .frame-24 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 17px 15px;
  left: 48px;
  position: absolute;
  top: 40px;
  width: 184px;
}

.vision .group-9 {
  height: 50px;
  position: relative;
  width: 50px;
}

.vision .list-item-link-SVG {
  background-image: url(../../../static/img/group-6-2x.png);
  background-size: 100% 100%;
  height: 50px;
  position: relative;
  width: 50px;
}

.vision .symbol-4 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 17px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.vision .telegram-app {
  height: 28px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 28px;
}

.vision .symbol-5 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 10px;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 26px;
}

.vision .symbol-6 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 6px;
  left: 13px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 86px;
  white-space: nowrap;
  width: 24px;
}

.vision .symbol-7 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 81px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.vision .symbol-8 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 145px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.vision .subtract {
  height: 311px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1442px;
}

.vision .button-SVG {
  height: 20px;
  left: 1323px;
  position: absolute;
  top: 781px;
  width: 20px;
}

.vision .frame-25 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  height: 1060px;
  left: 97px;
  position: absolute;
  top: 905px;
}

.vision .link-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.vision .link-2 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e6e7e8;
  height: 259px;
  position: relative;
  width: 1241px;
}

.vision .heading-president-2 {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 40px;
  left: 372px;
  letter-spacing: 0;
  line-height: 39px;
  position: absolute;
  top: 108px;
  white-space: nowrap;
  width: 869px;
}

.vision .text-wrapper-37 {
  color: #000000;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  height: 20px;
  left: 1137px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 192px;
  white-space: nowrap;
  width: 104px;
}

.vision .text-wrapper-38 {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 37px;
  left: 685px;
  letter-spacing: 0;
  line-height: 47px;
  position: absolute;
  top: 212px;
  white-space: nowrap;
  width: 263px;
}

.vision .line-5 {
  height: 1px;
  left: 392px;
  position: absolute;
  top: 230px;
  width: 280px;
}

.vision .div-image-2 {
  background-image: url(../../../static/img/div-image-1.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 9px;
  height: 204px;
  left: 0;
  position: absolute;
  top: 27px;
  transform: rotate(180deg);
  width: 323px;
}

.vision .heading-president-3 {
  color: #253b95;
  font-family: "Kantumruy", Helvetica;
  font-size: 32px;
  font-weight: 700;
  height: 40px;
  left: 389px;
  letter-spacing: 0;
  line-height: 39px;
  position: absolute;
  top: 108px;
  white-space: nowrap;
  width: 852px;
}

.vision .div-image-3 {
  background-image: url(../../../static/img/div-image-2x.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 9px;
  height: 204px;
  left: 0;
  position: absolute;
  top: 27px;
  width: 323px;
}

.vision .div-image-4 {
  background-image: url(../../../static/img/div-image-2.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 9px;
  height: 204px;
  left: 0;
  position: absolute;
  top: 27px;
  transform: rotate(180deg);
  width: 323px;
}

.vision .div-image-5 {
  background-image: url(../../../static/img/div-image-3.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 9px;
  height: 201px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 323px;
}

.vision .main-section {
  background-image: url(../../../static/img/rectangle-29.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 560px;
  left: 0;
  position: absolute;
  top: 195px;
  width: 1440px;
}

.vision .overlap-5 {
  height: 560px;
  position: relative;
}

.vision .div-absolute {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.78) 10.42%, rgba(0, 0, 0, 0) 100%);
  height: 560px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 1440px;
}

.vision .heading-president-4 {
  -webkit-text-stroke: 1px #ffffff;
  color: #ffffff;
  font-family: "Metal", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 86px;
  left: 386px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 424px;
  width: 667px;
}

.vision .screenshot-10 {
  height: 334px;
  left: 423px;
  object-fit: cover;
  position: absolute;
  top: 51px;
  width: 595px;
}
