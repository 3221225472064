.announcement {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.announcement .div-23 {
  background-color: #ffffff;
  height: 3309px;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}

.announcement .overlap-21 {
  height: 2756px;
  left: -15px;
  position: absolute;
  top: 556px;
  width: 1468px;
}

.announcement .background-7 {
  height: 797px;
  left: 10px;
  position: absolute;
  top: 1452px;
  width: 1450px;
}

.announcement .div-24 {
  height: 764px;
  left: 146px;
  position: absolute;
  top: 1071px;
  width: 1180px;
}

.announcement .rectangle-4 {
  background-color: #253b95;
  height: 1340px;
  left: 225px;
  position: absolute;
  top: 0;
  width: 1032px;
}

.announcement .image-18 {
  height: 1199px;
  left: 300px;
  object-fit: cover;
  position: absolute;
  top: 71px;
  width: 883px;
}

.announcement .rectangle-5 {
  background-color: #f5f5f5;
  height: 103px;
  left: 225px;
  position: absolute;
  top: 1381px;
  width: 1032px;
}

.announcement .text-wrapper-85 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 17px;
  left: 335px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 1417px;
  white-space: nowrap;
  width: 503px;
}

.announcement .file-svg {
  height: 52px;
  left: 271px;
  position: absolute;
  top: 1400px;
  width: 53px;
}

.announcement .rectangle-6 {
  background-color: #e41d2c;
  height: 52px;
  left: 1046px;
  position: absolute;
  top: 1400px;
  width: 147px;
}

.announcement .text-wrapper-86 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 17px;
  left: 1074px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 1417px;
  white-space: nowrap;
  width: 92px;
}

.announcement .frame-92 {
  left: 224px !important;
  position: absolute !important;
  top: 1646px !important;
}

.announcement .group-54 {
  height: 34px;
  left: 623px;
  position: absolute;
  top: 1564px;
  width: 226px;
}

.announcement .text-wrapper-87 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  width: 224px;
}

.announcement .line-13 {
  height: 4px;
  left: 62px;
  position: absolute;
  top: 32px;
  width: 100px;
}

.announcement .group-55 {
  height: 714px;
  left: 0;
  position: absolute;
  top: 2042px;
  width: 1468px;
}

.announcement .overlap-22 {
  height: 714px;
  position: relative;
}

.announcement .cambodianflag-4 {
  height: 548px;
  left: 1px;
  position: absolute;
  top: 166px;
  width: 1466px;
}

.announcement .group-56 {
  height: 399px;
  left: 1px;
  position: absolute;
  top: 311px;
  width: 1466px;
}

.announcement .overlap-23 {
  height: 399px;
  position: relative;
}

.announcement .footer-4 {
  background-color: transparent;
  height: 399px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1466px;
}

.announcement .overlap-group-14 {
  height: 68px;
  left: 96px;
  position: relative;
  top: 301px;
  width: 1264px;
}

.announcement .line-14 {
  height: 1px;
  left: 18px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 1246px;
}

.announcement .div-25 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1246px;
}

.announcement .p-4 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2px;
  display: flex;
  height: 34px;
  left: 4px;
  padding: 6px 11.14px 6px 11px;
  position: absolute;
  top: 34px;
  width: 372px;
}

.announcement .element-4 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
  margin-right: -0.14px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.announcement .text-wrapper-88 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.announcement .text-wrapper-89 {
  font-family: "Montserrat", Helvetica;
}

.announcement .ul-4 {
  height: 16px;
  left: 935px;
  position: absolute;
  top: 32px;
  width: 366px;
}

.announcement .frame-58 {
  height: 16px;
  left: -36px;
  position: relative;
  width: 395px;
}

.announcement .frame-59 {
  align-items: flex-start;
  display: inline-flex;
  gap: 11px;
  left: 23px;
  position: relative;
}

.announcement .text-wrapper-90 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 57px;
}

.announcement .text-wrapper-91 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 95px;
}

.announcement .text-wrapper-92 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 80px;
}

.announcement .text-wrapper-93 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  width: 92px;
}

.announcement .frame-60 {
  height: 240px;
  left: 99px;
  position: absolute;
  top: 45px;
  width: 1267px;
}

.announcement .frame-61 {
  align-items: flex-start;
  display: inline-flex;
  gap: 136px;
  height: 180px;
  position: relative;
  top: 36px;
}

.announcement .frame-62 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 203px;
  margin-bottom: -23px;
  position: relative;
  width: 423px;
}

.announcement .frame-63 {
  height: 201px;
  overflow: hidden;
  position: relative;
  width: 377px;
}

.announcement .group-57 {
  height: 41px;
  left: 395px;
  position: absolute;
  top: 12px;
  width: 238px;
}

.announcement .div-26 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 130px;
}

.announcement .text-wrapper-94 {
  font-weight: 700;
  line-height: 12px;
}

.announcement .frame-64 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 23px;
  left: 0;
  position: absolute;
  top: 37px;
}

.announcement .frame-65 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 44px;
  position: relative;
}

.announcement .image-19 {
  height: 59px;
  position: relative;
  width: 91px;
}

.announcement .frame-66 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 45px;
  position: relative;
  width: 361px;
}

.announcement .image-20 {
  height: 57px;
  object-fit: cover;
  position: relative;
  width: 87px;
}

.announcement .image-21 {
  height: 59px;
  object-fit: cover;
  position: relative;
  width: 91px;
}

.announcement .group-58 {
  height: 166px;
  position: relative;
  width: 319px;
}

.announcement .group-59 {
  height: 112px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 318px;
}

.announcement .frame-67 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 112px;
  position: relative;
  width: 318px;
}

.announcement .frame-68 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9px;
  margin-right: -30.37px;
  position: relative;
}

.announcement .text-wrapper-95 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  position: relative;
  width: 106px;
}

.announcement .text-wrapper-96 {
  color: #ffffff;
  font-family: "Kantumruy Pro", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  width: 348.37px;
}

.announcement .frame-69 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 22px;
  left: 0;
  position: absolute;
  top: 118px;
}

.announcement .div-27 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 15px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 136px;
}

.announcement .text-wrapper-97 {
  font-weight: 700;
  line-height: 11px;
}

.announcement .text-wrapper-98 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 11px;
  letter-spacing: 0;
  line-height: 11px;
  position: relative;
  white-space: nowrap;
  width: 267px;
}

.announcement .div-footer-social-4 {
  height: 161px;
  position: relative;
  width: 230px;
}

.announcement .text-wrapper-99 {
  color: #ffffff;
  font-family: "Kantumruy", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 48px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
  width: 144px;
}

.announcement .frame-70 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 17px 15px;
  left: 48px;
  position: absolute;
  top: 40px;
  width: 184px;
}

.announcement .group-60 {
  height: 50px;
  position: relative;
  width: 50px;
}

.announcement .list-item-link-SVG-4 {
  background-image: url(../../../static/img/group-24.png);
  background-size: 100% 100%;
  height: 50px;
  position: relative;
  width: 50px;
}

.announcement .symbol-21 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 17px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.announcement .telegram-app-5 {
  height: 28px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 28px;
}

.announcement .symbol-22 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 10px;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 26px;
}

.announcement .symbol-23 {
  color: #ffffff;
  font-family: "Font Awesome 5 Free-Solid", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 6px;
  left: 13px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 86px;
  white-space: nowrap;
  width: 24px;
}

.announcement .symbol-24 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 81px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.announcement .symbol-25 {
  color: #ffffff;
  font-family: "Font Awesome 5 Brands-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 16px;
  left: 145px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;
}

.announcement .subtract-4 {
  height: 311px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1468px;
}

.announcement .group-61 {
  height: 16px;
  left: 95px;
  position: absolute;
  top: 221px;
  width: 914px;
}

.announcement .text-wrapper-100 {
  color: #000000;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 600;
  height: 13px;
  left: 73px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 839px;
}

.announcement .vector-5 {
  height: 10px;
  left: 38px;
  position: absolute;
  top: 4px;
  width: 6px;
}

.announcement .vector-6 {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 19px;
}

.announcement .text-wrapper-101 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-weight: 700;
  height: 148px;
  left: 94px;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 307px;
  width: 1239px;
}

.announcement .span-wrapper {
  height: 27px;
  left: 108px;
  position: absolute;
  top: 483px;
  width: 88px;
}

.announcement .span-2 {
  height: 18px;
  left: -11px;
  opacity: 0.7;
  position: relative;
  top: 4px;
  width: 84px;
}

.announcement .text-wrapper-102 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 18px;
  left: 0;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.announcement .svg-2 {
  height: 15px;
  left: 90px;
  position: absolute;
  top: 1px;
  width: 15px;
}

.announcement .text-wrapper-103 {
  color: #171717;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  left: 1180px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 256px;
  width: 166px;
}

.announcement .icon-facebook-2 {
  height: 21px;
  left: 1270px;
  position: absolute;
  top: 483px;
  width: 12px;
}

.announcement .vector-7 {
  height: 21px;
  left: 1319px;
  position: absolute;
  top: 483px;
  width: 27px;
}

.announcement .design-component-instance-node {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}
